import { Actions, Mutations } from './types';
import { sectorsApi } from '../../../api/sectors';

const actions = {
  [Actions.LOAD_SECTORS_LIST]: async ({ commit }) => {
    const { data } = await sectorsApi.fetchSectors();
    const sectorNames = data.map((item) => item.name);
    commit(Mutations.SET_SECTORS_LIST, sectorNames);
  },
};

export default actions;

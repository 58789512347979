<template>
  <thead class="table-head">
    <tr class="table-head__row">
      <th
        v-for="header in headers"
        class="table-head__column cell body-2 primary--text header-border"
        :key="header.value"
        :class="`${header.value}-container`"
      >
        <div
          v-if="header.value !== 'ante'"
          class="header-padding table-head__column-content"
          :class="`${calculateMargin(header.value)} ${calculateAbs(header.value)}`"
        >
          <VHover v-slot="{ hover }">
            <div
              class="d-flex align-start table-head__column-label"
              @click="toggleSortParam(header.value)"
            >
              <span class="pr-2">{{ header.text }}</span>

              <div
                v-if="hover || (header.value === sortParams.sortBy && sortParams.value)"
                class="name-sort"
              >
                <VIcon
                  class="name-sort-icon"
                  :class="{ active: checkCurrentSortIcon(header.value, SORT_PARAMS_VALUES.ASC) }"
                >
                  mdi-menu-up
                </VIcon>
                <VIcon
                  class="name-sort-icon"
                  :class="{ active: checkCurrentSortIcon(header.value, SORT_PARAMS_VALUES.DESC) }"
                >
                  mdi-menu-down
                </VIcon>
              </div>
            </div>
          </VHover>

          <template v-if="searchFields.includes(header.value)">
            <VTextField
              class="table-head__column-textfield"
              placeholder="Search"
              dense
              clearable
              hide-details
              @input="onFilterChange(header.value, $event)"
            />
          </template>
        </div>

        <div v-else>
          <div class="text-center ante-header-title" :id="header.value">
            <span>{{ header.text }}</span>
          </div>

          <div class="d-flex">
            <div
              v-for="anteValues in Object.values(header.categories)"
              class="table-head__column-ante-header-wrapper"
              :key="anteValues.text"
            >
              <div class="d-flex flex-column" align="center">
                <span class="py-1"> {{ anteValues.text }} </span>
                <div class="d-flex">
                  <span
                    v-for="anteScore in anteValues.value"
                    class="ante-score-cell"
                    :key="anteScore.label"
                  >
                    <VMenu rounded="0" open-on-hover bottom right offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on"> {{ anteScore.label }}. </span>
                      </template>
                      <div class="table-head__column-ante-score-menu-label">
                        <span>{{ anteScore.text }}</span>
                      </div>
                    </VMenu>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </th>
      <th class="table-head__column table-head__column-empty header-border"></th>
    </tr>
  </thead>
</template>
<script>
import { reactive } from '@vue/composition-api';
import { calculateMargin, calculateAlign, dateCells } from '../utils';

export default {
  props: {
    headers: { type: Array, required: true },
  },

  emits: ['onFilterChange', 'onSortChange'],
  setup(_, { emit }) {
    const searchFields = ['name', 'email', 'company'];

    const SORT_PARAMS_VALUES = { DESC: -1, NULL: null, ASC: 1 };

    const sortParams = reactive({
      sortBy: null,
      value: SORT_PARAMS_VALUES.NULL,
    });

    const checkCurrentSortIcon = (field, value) => {
      return sortParams.sortBy === field && sortParams.value === value;
    };

    const toggleSortParam = (field) => {
      let newValue;

      if (field !== sortParams.sortBy && sortParams.sortBy !== null) {
        sortParams.sortBy = null;
        sortParams.value = SORT_PARAMS_VALUES.NULL;
      }

      if (sortParams.value === SORT_PARAMS_VALUES.DESC) newValue = SORT_PARAMS_VALUES.NULL;

      if (sortParams.value === SORT_PARAMS_VALUES.NULL) newValue = SORT_PARAMS_VALUES.ASC;

      if (sortParams.value === SORT_PARAMS_VALUES.ASC) newValue = SORT_PARAMS_VALUES.DESC;

      if (newValue === SORT_PARAMS_VALUES.NULL) {
        sortParams.sortBy = null;
        sortParams.value = newValue;
        return emit('onSortChange', sortParams);
      }
      sortParams.sortBy = field;
      sortParams.value = newValue;

      emit('onSortChange', sortParams);
    };

    const calculateAbs = (header) => {
      return dateCells.includes(header) ? 'center' : '';
    };

    const onFilterChange = (field, newName) => {
      emit('onFilterChange', field, newName);
    };

    return {
      dateCells,
      checkCurrentSortIcon,
      SORT_PARAMS_VALUES,
      sortParams,
      toggleSortParam,
      calculateAlign,
      calculateAbs,
      calculateMargin,
      onFilterChange,
      searchFields,
    };
  },
};
</script>
<style scoped lang="scss">
@import '../styles';

.table-head {
  display: block;
  padding-left: 0 !important;
  padding-right: 0 !important;

  &__column {
    position: relative;
    font-weight: bold;
    border-bottom: 2px solid darkgrey !important;

    &-label {
      cursor: pointer;
      position: relative;
      width: fit-content;
    }

    &-textfield {
      width: 80%;
    }

    &-content {
      white-space: pre;
      position: absolute;
      top: 44px;
      left: 0;

      &.center {
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &-empty {
      padding: 0 !important;
      min-width: 10px;
      max-width: 10px;
    }

    &-ante-score-menu-label {
      padding: 10px 15px;
      background: #fff;
    }

    &-ante-header-wrapper {
      position: relative;
      background: var(--v-additional-lighten5);
    }

    &-ante-header-wrapper:not(:last-child)::after {
      content: '';
      position: absolute;
      bottom: -2px;
      right: 0;
      height: calc(100% + 2px);
      width: 1px;
      border-right: 1px lightgreen solid;
    }
  }
}

.name-sort {
  position: absolute;
  top: 0;
  right: -10px;

  .name-sort-icon {
    display: flex;
    flex-direction: column;
    height: 9px;
    width: 10px;
    color: lightgrey;
  }

  .name-sort-icon.active {
    color: var(--primary);
  }
}
</style>

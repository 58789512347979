import { computed } from '@vue/composition-api';
import { store } from '../store';

export function useStore(nameSpace) {
  return {
    useActions: (map) => {
      const obj = {};
      map.forEach((m) => {
        obj[m] = async (payload, options) => {
          return store.dispatch(`${nameSpace}/${m}`, payload, options);
        };
      });
      return obj;
    },
    useGetters: (map) => {
      const obj = {};
      map.forEach((m) => {
        obj[m] = store.getters[`${nameSpace}/${m}`];
      });
      return obj;
    },
    useMutations: (map) => {
      const obj = {};
      map.forEach((m) => {
        obj[m] = async (payload, options) => {
          return store.commit(`${nameSpace}/${m}`, payload, options);
        };
      });
      return obj;
    },
    useState: (map) => {
      const obj = {};
      map.forEach((m) => {
        obj[m] = computed(() => store.state[nameSpace][m]);
      });
      return obj;
    },
  };
}

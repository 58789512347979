import Vue from 'vue';
import Vuex from 'vuex';
import user from './modules/user';
import notifications from './modules/notification';
import sectors from './modules/sectors';
import institute from './modules/institute';
import deliveryType from './modules/deliveryType';

Vue.use(Vuex);

export const StoreModules = {
  USER: 'USER',
  NOTIFICATION: 'NOTIFICATION',
  SECTORS: 'SECTORS',
  INSTITUTE: 'INSTITUTE',
  DELIVERY_TYPE: 'DELIVERY_TYPE',
};

const storeOptions = {
  modules: {
    [StoreModules.USER]: user,
    [StoreModules.NOTIFICATION]: notifications,
    [StoreModules.SECTORS]: sectors,
    [StoreModules.INSTITUTE]: institute,
    [StoreModules.DELIVERY_TYPE]: deliveryType,
  },
};

export const store = new Vuex.Store(storeOptions);

export default store;

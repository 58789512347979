import Vue from 'vue';
import VueRouter from 'vue-router';

import Layout from '../components/Layout/Layout';
import Login from '../pages/Login.vue';
import ForgotPassword from '../pages/ForgotPassword.vue';
import Score from '../pages/Score/Score';
import Matchup from '../pages/Matchup/Matchup';
import Company from '../pages/Company/Company';
import Contact from '../pages/Contact/Contact';
import CompanySearch from '../pages/CompanySearch/CompanySearch';
import ContactSearch from '../pages/ContactSearch/ContactSearch';
import Users from '../pages/Users/Users.vue';
import DbUpdate from '../pages/DbUpdate/DbUpdate.vue';
import { Actions, States } from '../store/modules/user/types';

import { useStore } from '../uses/useStore';
import { StoreModules as Modules } from '../store';
import { useUserInfo } from '@/uses/useUserInfo';

Vue.use(VueRouter);

export const HOMEPAGES = {
  ADMIN: '/users',
  USER: '/matchup',
};

const routes = [
  {
    path: '/',
    component: Layout,
    redirect: () => {
      const { isAdmin } = useUserInfo();
      return isAdmin.value ? HOMEPAGES.ADMIN : HOMEPAGES.USER;
    },
    meta: {
      authorized: true,
    },
    children: [
      {
        path: '/score',
        name: 'score',
        component: Score,
        meta: {
          authorized: true,
          role: 'user',
        },
      },
      {
        path: '/matchup',
        name: 'matchup',
        component: Matchup,
        meta: {
          authorized: true,
          role: 'user',
        },
      },
      {
        path: '/company/:id',
        name: 'company',
        component: Company,
        meta: {
          authorized: true,
          role: 'user',
        },
      },
      {
        path: '/contact/:id',
        name: 'contact',
        component: Contact,
        meta: {
          authorized: true,
          role: 'user',
        },
      },
      {
        path: '/contact-search',
        name: 'contactSearch',
        component: ContactSearch,
        meta: {
          authorized: true,
          role: 'user',
        },
      },
      {
        path: '/company-search',
        name: 'companySearch',
        component: CompanySearch,
        meta: {
          authorized: true,
          role: 'user',
        },
      },
      {
        path: '/users',
        name: 'users',
        component: Users,
        meta: {
          authorized: true,
          role: 'admin',
        },
      },
      {
        path: '/db-update',
        name: 'dbUpdate',
        component: DbUpdate,
        meta: {
          authorized: true,
          role: 'admin',
        },
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/forgot-password',
    name: 'forgotPassword',
    component: ForgotPassword,
  },
];

const router = new VueRouter({ mode: 'history', routes });

const handleRouteRole = (role, routeRole, routeTo) => {
  const { isAdmin, isUser } = useUserInfo();

  if (role !== routeRole) {
    if (isUser.value) return HOMEPAGES.USER;
    if (isAdmin.value) return HOMEPAGES.ADMIN;
  }

  return routeTo;
};

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.authorized)) {
    if (localStorage.getItem('user')) {
      const { useState, useActions } = useStore(Modules.USER);
      const { user } = useState([States.user]);
      const role = user.value?.role;

      if (!user.value) {
        const { INIT_CURRENT_USER } = useActions([Actions.INIT_CURRENT_USER]);
        await INIT_CURRENT_USER();

        const { user } = useState([States.user]);
        const role = user.value?.role;

        const route = handleRouteRole(role, to.meta.role, to);
        return next(route);
      }
      const route = handleRouteRole(role, to.meta.role, to);
      if (route !== to) next(route);
      next();
    } else {
      next('/login');
    }
  } else {
    next();
  }
});

export default router;

export const States = {
  sectorsList: 'sectorsList',
  selectedSectors: 'selectedSectors',
};

export const Mutations = {
  SET_SECTORS_LIST: 'SET_SECTORS_LIST',
  SET_SELECTED_SECTORS: 'SET_SELECTED_SECTORS',
};

export const Actions = {
  LOAD_SECTORS_LIST: 'LOAD_SECTORS_LIST',
  LOAD_SELECTED_SECTORS: 'LOAD_SELECTED_SECTORS',
};

<template>
  <div>
    <VCard class="notes rounded-0" height="310" flat>
      <VCard ref="messagesContainer" class="notes__message" height="260" flat>
        <Message v-for="(message, index) in messages" :key="index" :message="message" />
      </VCard>
      <div class="d-flex">
        <VTextField
          v-model="message"
          class="pa-0 ma-0 rounded-0"
          placeholder="Write your message here"
          dense
          outlined
          hide-details
          @keypress.enter="onSubmitMessage"
        />
        <VBtn
          class="notes__submit-btn rounded-0 text-capitalize white--text px-6"
          color="secondary darken-1"
          @click="onSubmitMessage"
        >
          Submit
        </VBtn>
      </div>
    </VCard>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref, watch } from '@vue/composition-api';
import { useUserInfo } from '../../../../uses/useUserInfo';
import Message from './molecules/Message';
import { notesApi } from '../../../../api/notes';
import { institutesApi } from '../../../../api/institutes';

export default defineComponent({
  components: {
    Message,
  },
  props: {
    contact: { type: Object },
  },
  setup(props, { root }) {
    const message = ref('');
    const messages = ref([]);
    const messagesContainer = ref(null);

    const { user } = useUserInfo();

    const moveScrollBottom = () => {
      setTimeout(() => {
        messagesContainer.value.$el.scrollTop = messagesContainer.value.$el.scrollHeight;
      }, 50);
    };

    onMounted(() => {
      moveScrollBottom();
    });

    const getMessages = async (instituteId) => {
      const { data } = await institutesApi.fetchInstituteNotes(instituteId, root.$route.params.id);
      messages.value = data;
    };

    watch(
      user,
      async (value) => {
        if (value) {
          await getMessages(value.institute);
        }
      },
      { immediate: true, deep: true }
    );

    const onSubmitMessage = async () => {
      if (!message.value) return;
      const body = {
        publisher: user.value.name,
        publicationDate: new Date().toISOString(),
        content: message.value,
        contactId: props.contact.contact_id,
      };
      await notesApi.createNote(body, user.value.institute);
      await getMessages(user.value.institute);
      moveScrollBottom();
      message.value = '';
    };

    return { messages, onSubmitMessage, message, messagesContainer };
  },
});
</script>

<style scoped lang="scss">
.notes {
  &__message {
    border-top: 2px solid lightgrey;
    border-left: 2px solid lightgrey;
    border-right: 2px solid lightgrey;
    overflow-y: auto;
  }

  &__submit-btn {
    height: 40px !important;
  }
}
::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-thumb {
  background: darkgrey;
  border-radius: 10px;
}
</style>

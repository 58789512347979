import axios from 'axios';
import { logError } from '../utils/logger';

const baseUrl = `/api/v1/companies`;

const fetchCompanies = async (params) => {
  try {
    return await axios.get(baseUrl, { params });
  } catch (error) {
    logError(error);
    return error;
  }
};

const fetchCompany = async (id) => {
  try {
    return await axios.get(`${baseUrl}/${id}`);
  } catch (error) {
    logError(error);
    return error;
  }
};

const fetchCompanyMatchup = async (params) => {
  try {
    return await axios.get(`${baseUrl}/matchup`, { params });
  } catch (error) {
    logError(error);
    return error;
  }
};

const generateCompaniesMatchupCSV = async (searchParams, instituteId) => {
  try {
    return await axios.post(`${baseUrl}/matchup-csv`, { instituteId }, { params: searchParams });
  } catch (error) {
    logError(error);
    return error;
  }
};

const generateCompaniesScoutingReportCSV = async (searchParams) => {
  try {
    return await axios.post(`${baseUrl}/scouting-report-csv`, null, { params: searchParams });
  } catch (error) {
    logError(error);
    return error;
  }
};

export const companiesApi = {
  fetchCompanies,
  fetchCompany,
  generateCompaniesMatchupCSV,
  generateCompaniesScoutingReportCSV,
  fetchCompanyMatchup,
};

import axios from 'axios';
import { logError } from '../utils/logger';

const baseUrl = `/api/v1/notes`;

const createNote = async (body, instituteId) => {
  try {
    return await axios.post(baseUrl, body, { params: { instituteId } });
  } catch (error) {
    logError(error);
    return error;
  }
};

export const notesApi = { createNote };

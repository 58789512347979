import { Actions, Mutations } from './types';
import { authApi } from '../../../api/auth';
import router from '../../../router';

const actions = {
  [Actions.INIT_CURRENT_USER]: ({ commit }) => {
    const user = JSON.parse(localStorage.getItem('user'));
    commit(Mutations.SET_USER_DETAILS, { ...user });
  },
  [Actions.LOGIN]: async ({ commit }, user) => {
    const { data: loginData } = await authApi.login(user);
    if (!loginData?.token) return;
    localStorage.setItem('token', JSON.stringify(loginData.token));
    const { data } = await authApi.fetchCurrentUser();
    if (data) {
      const preparedUser = {
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        name: `${data.firstName} ${data.lastName}`,
        role: data.role,
        institute: data.institute,
        ante: data.ante,
        id: data._id,
        requestedAnte: data.requestedAnte,
        token: loginData.token,
      };
      localStorage.setItem('user', JSON.stringify(preparedUser));
      commit(Mutations.SET_USER_DETAILS, preparedUser);
      await router.push('/');
    }
  },
  [Actions.LOGOUT]: async ({ commit }) => {
    if (router.currentRoute.path !== '/login') {
      await authApi.logout();
      commit(Mutations.SET_USER_DETAILS, null);
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      await router.push('/login');
    }
  },
  [Actions.GET_CURRENT_USER]: async ({ commit }) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const { data } = await authApi.fetchCurrentUser();
    if (data) {
      const preparedUser = {
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        name: `${data.firstName} ${data.lastName}`,
        role: data.role,
        institute: data.institute,
        ante: data.ante,
        id: data._id,
        requestedAnte: data.requestedAnte,
        token: user.token,
      };
      localStorage.setItem('user', JSON.stringify(preparedUser));
      commit(Mutations.SET_USER_DETAILS, preparedUser);
    }
  },
};

export default actions;

<template>
  <VApp>
    <Snackbar />
    <RouterView />
  </VApp>
</template>

<script>
import { useStore } from './uses/useStore';
import { StoreModules as Modules } from './store';
import { Actions } from './store/modules/user/types';
import { onMounted } from '@vue/composition-api';
import Snackbar from './components/Snackbar';

export default {
  components: {
    Snackbar,
  },
  setup() {
    const { useActions } = useStore(Modules.USER);
    const { INIT_CURRENT_USER } = useActions([Actions.INIT_CURRENT_USER]);

    onMounted(() => {
      INIT_CURRENT_USER();
    });
  },
};
</script>

<style lang="scss">
@import '~vuetify/src/styles/settings/_variables.scss';

.header-text {
  font-family: 'Oswald', sans-serif;
  width: 100%;
  font-size: 2.4rem;
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  grid-column-gap: 1.2rem;
  align-items: center;
  text-transform: uppercase;
  font-weight: 800;
  color: var(--v-primary-base);
}

.page-header {
  font-family: 'Oswald', sans-serif;

  font-size: 2.4rem;
  text-transform: uppercase;
  font-weight: 800;
}

.header-text::before,
.header-text::after {
  content: '';
  display: block;
  height: 2px;
  background-color: var(--v-grey-lighten2);
}
.subheader-text {
  font-family: 'Oswald', sans-serif;
  color: var(--v-primary-base);
  font-size: 1.3rem;
}

.page-subheader {
  text-align: center;
  padding-left: 0;

  @media #{map-get($display-breakpoints, 'xs-only')} {
    padding-left: 12px;
    text-align: left;
  }
}

.cell {
  padding: 0 !important;
  border-bottom: 1px dotted darkgrey !important;
  height: 40px !important;
}
</style>

<template>
  <div v-if="notifications.length" class="snackbar">
    <VSnackbar
      v-for="notification in notifications"
      :key="notification.id"
      :color="notification.variant"
      :timeout="notification.duration"
      :value="notification.visible"
      class="snackbar__item"
      @input="snackbarClose(notification)"
    >
      {{ notification.message }}
      <VBtn dark text @click="snackbarClose(notification)"> Close </VBtn>
    </VSnackbar>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import { useStore } from '../uses/useStore';
import { StoreModules as Modules } from '../store';
import { Actions, States } from '../store/modules/notification/types';

export default defineComponent({
  setup() {
    const { useState, useActions } = useStore(Modules.NOTIFICATION);
    const { notifications } = useState([States.notifications]);
    const { REMOVE_NOTIFICATION } = useActions([Actions.REMOVE_NOTIFICATION]);

    const snackbarClose = (notification) => {
      REMOVE_NOTIFICATION(notification);
    };

    return {
      notifications,
      snackbarClose,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.snackbar {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: $z-index-snackbar;
  &__item {
    position: relative;
    height: auto;
  }
}
</style>

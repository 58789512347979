export const headers = [
  {
    text: 'Company Name',
    value: 'name',
  },
  {
    text: 'Investment Strategy',
    value: 'strategy',
  },
  {
    text: 'Score',
    value: 'averageScore',
  },
  {
    text: '13F',
    value: 'f13Overlap',
  },
  {
    text: 'Budget',
    value: 'budget',
  },
  {
    text: 'ANTE',
    value: 'anteOverlapScore',
  },
  {
    text: 'Accuracy',
    value: 'accuracyOverlapScore',
  },
  {
    text: 'Novelty',
    value: 'noveltyOverlapScore',
  },
  {
    text: 'Timeliness',
    value: 'timelinessOverlapScore',
  },
  {
    text: 'Ease of Use',
    value: 'easeOfUseOverlapScore',
  },
];

<template>
  <VTabs
    v-model="tab"
    :vertical="vertical"
    :class="{ horizontal: !vertical, 'pt-5': vertical }"
    color="white"
    background-color="primary"
    dark
    @change="onTabChange"
  >
    <VTabsSlider color="additional darken-1" />

    <template v-if="isUser">
      <VTab to="/matchup" class="list-item" :class="getClassByAxis"> Matchup </VTab>
      <VMenu nudge-bottom="3" offset-y bottom>
        <template #activator="{ on, attrs }">
          <VTab class="list-item" :class="getClassByAxis" v-bind="attrs" v-on="on">
            Scouting Report
          </VTab>
        </template>
        <VList>
          <VListItem to="/company-search" link @click="onTabChange(1, true)">
            <VListItemTitle>Companies</VListItemTitle>
          </VListItem>
          <VListItem to="/contact-search" link @click="onTabChange(1, true)">
            <VListItemTitle>Contacts</VListItemTitle>
          </VListItem>
        </VList>
      </VMenu>
      <VTab to="/score" class="list-item" :class="getClassByAxis"> My Ante Score </VTab>
    </template>

    <template v-else-if="isAdmin">
      <VTab to="/users" class="list-item" :class="getClassByAxis"> Users </VTab>
    </template>
  </VTabs>
</template>
<script>
import { ref, nextTick, computed, onMounted } from '@vue/composition-api';
import { useUserInfo } from '../../../uses/useUserInfo';

export default {
  props: {
    vertical: { type: Boolean, default: false },
  },
  setup({ vertical }, { root }) {
    const tab = ref(null);
    const currentTab = ref(null);

    const { isUser, isAdmin } = useUserInfo();

    onMounted(() => {
      const currentPath = root.$route.path;
      const reportPaths = ['/company-search', '/contact-search'];

      if (reportPaths.includes(currentPath)) {
        onTabChange(1, true);
      }
    });

    const onTabChange = (newTab, forceChange) => {
      if (newTab === 1 && !forceChange) {
        nextTick(() => {
          tab.value = currentTab.value;
        });
        return;
      }
      currentTab.value = newTab;
      tab.value = newTab;
    };

    const getClassByAxis = computed(() => {
      return vertical ? 'd-flex pl-0 mx-5 justify-start' : '';
    });

    return {
      tab,
      onTabChange,
      isUser,
      isAdmin,
      getClassByAxis,
    };
  },
};
</script>
<style lang="scss">
.list-item {
  margin-right: 20px;
}

.horizontal {
  .v-slide-group__wrapper {
    height: 30px !important;
    margin-top: 7px;
  }
  .v-tab {
    padding: 0 !important;
    margin-right: 20px;
    font-size: 15px !important;
  }
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tbody',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.loadMoreUsers),expression:"loadMoreUsers"}],ref:"tableBodyRef",staticClass:"table-body d-block",attrs:{"infinite-scroll-disabled":"infiniteScrollDisabled","infinite-scroll-distance":"10"}},_vm._l((_vm.items),function(item){return _c('tr',{key:item._id,staticClass:"table-row"},[_vm._l((_vm.getTableRow(item)),function(ref){
var key = ref[0];
var value = ref[1];
return _c('td',{key:key,staticClass:"cell",class:(key + "-container"),attrs:{"align":_vm.calculateAlign(key)}},[(_vm.isDateField(key))?_c('div',{staticClass:"cell-content"},[_c('span',{staticClass:"primary--text",class:_vm.calculateMargin(key)},[_vm._v(" "+_vm._s(_vm.formatDate(value) || '##/##/####')+" ")])]):(key === 'anteScore')?_c('div',{staticClass:"d-flex h-100"},[(!_vm.isEmpty(value))?_vm._l((Object.values(value)),function(anteName){return _c('div',{key:anteName.label,staticClass:"d-flex h-100 ante-score-cell-container"},_vm._l((_vm.getAnteScores(anteName)),function(ante,i){return _c('span',{key:i,staticClass:"h-100 ante-score-cell h-100"},[_vm._v(" "+_vm._s(_vm.getScoreByAnte(ante))+" ")])}),0)}):_vm._l((4),function(i){return _c('div',{key:i,staticClass:"d-flex h-100 ante-score-cell-container"},_vm._l((4),function(i){return _c('span',{key:i,staticClass:"h-100 ante-score-cell h-100"},[_vm._v("##")])}),0)})],2):_c('div',{staticClass:"cell-content"},[_c('VMenu',{attrs:{"open-on-hover":"","bottom":"","right":"","offset-y":"","rounded":"0"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"primary--text",class:_vm.calculateMargin(key)},'span',attrs,false),on),[_vm._v(" "+_vm._s(value)+" ")])]}}],null,true)},[(_vm.checkIfTruncated(key, value))?_c('div',{staticClass:"cell-content-label"},[_vm._v(_vm._s(value))]):_vm._e()])],1)])}),_c('VMenu',{attrs:{"bottom":"","left":"","offset-y":"","rounded":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
var value = ref.value;
return [_c('VIcon',_vm._g(_vm._b({staticClass:"icon",class:{ 'icon-active': value },attrs:{"size":"15","color":"primary"}},'VIcon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('div',{staticClass:"d-flex flex-column py-2 menu-edit"},[_c('div',{staticClass:"menu-list-item",on:{"click":function($event){return _vm.handleEdit(item)}}},[_vm._v("Edit")]),_c('div',{staticClass:"menu-list-item",on:{"click":function($event){return _vm.handleRemove(item)}}},[_vm._v("Remove")])])])],2)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }
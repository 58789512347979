<template>
  <VHover v-slot="{ hover }">
    <div class="d-flex pointer" :class="classes" @click="$emit('handleSort', headerValue)">
      <slot></slot>
      <div v-if="hover || headerValue === sortBy" class="sort-wrapper">
        <VIcon
          class="sort-icon sort-icon-up"
          :class="{
            active: sortDesc === 1 && headerValue === sortBy,
          }"
        >
          mdi-menu-up
        </VIcon>
        <VIcon
          class="sort-icon sort-icon-down"
          :class="{
            active: sortDesc === -1 && headerValue === sortBy,
          }"
        >
          mdi-menu-down
        </VIcon>
      </div>
    </div>
  </VHover>
</template>

<script>
import { computed } from '@vue/composition-api';
export default {
  props: {
    headerValue: { type: String },
    sortDesc: { type: Number },
    sortBy: { type: String },
    additionalClasses: { type: Array },
  },
  emits: ['handleSort'],
  setup({ additionalClasses }) {
    const classes = computed(() => {
      if (!additionalClasses) return '';
      return additionalClasses.join(' ');
    });

    return {
      classes,
    };
  },
};
</script>

<style lang="scss">
.sort-wrapper {
  position: relative;
  .sort-icon {
    position: absolute;
    color: lightgrey;
    &.sort-icon-up {
      top: -7px;
    }
    &.sort-icon-down {
      top: 2px;
    }
    &.active {
      color: var(--primary);
    }
  }
}
</style>

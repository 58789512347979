<template>
  <VCard
    class="buyer-stats"
    color="grey lighten-5"
    min-height="390"
    height="390"
    v-infinite-scroll="loadMoreCompanies"
    infinite-scroll-disabled="infiniteScrollDisabled"
    flat
  >
    <div class="buyer-stats__table-wrapper">
      <VDataTable
        class="buyer-stats__table"
        :headers="headers"
        :items="items"
        :items-per-page="totalItems"
        hide-default-header
        hide-default-footer
      >
        <template #top>
          <div class="buyer-stats__top">
            <div class="buyer-stats__top-title subheader-text ml-3 mt-3">Buyer Stats</div>
          </div>
        </template>
        <template #header="{ props }">
          <thead class="buyer-stats__head">
            <tr class="buyer-stats__head-row">
              <th
                v-for="header in props.headers"
                class="buyer-stats__head-column cell body-2 primary--text"
                :class="calculateTHClass(header.value)"
                :key="header.value"
              >
                <div
                  :class="
                    header.value in anteFields
                      ? 'buyer-stats__head-column-ante mt-6'
                      : 'buyer-stats__head-column-string ml-4 mb-2'
                  "
                >
                  <template v-if="header.value in anteFields">
                    <VImg max-width="40" :src="anteFields[header.value].img" />
                    <div class="mt-1">{{ anteFields[header.value].label }}</div>
                  </template>

                  <template v-else>
                    {{ header.text }}
                  </template>
                </div>
              </th>
            </tr>
          </thead>
        </template>
        <template #body="{ items }">
          <VOverlay color="primary" :value="loading" absolute />
          <tbody class="buyer-stats__body">
            <tr
              v-for="item in items"
              :key="item._id"
              class="buyer-stats__body-row body-2"
              :class="{ 'buyer-stats__body-row--active': rowIsActive(item.function) }"
              @click="$router.push(`/contact/${item.contact_id}`)"
            >
              <td
                v-for="[itemKey, itemText] in bodyColumns(item)"
                :key="itemKey"
                class="buyer-stats__body-column cell"
                :class="calculateTHClass(itemKey)"
              >
                <span v-if="itemKey in anteFields" class="primary--text">
                  {{ formatPercent(itemText, 0) }}
                </span>
                <span v-else class="primary--text ml-4 text-truncate">{{ itemText }}</span>
              </td>
            </tr>
          </tbody>
        </template>
      </VDataTable>
      <div class="buyer-stats__fillers">
        <div class="string-cell-filler"></div>
        <div class="number-cell-filler" />
      </div>
    </div>
  </VCard>
</template>

<script>
import { computed, defineComponent, reactive, ref, watch } from '@vue/composition-api';
import { useFormatter } from '../../../uses/useFormatter';
import { logError } from '../../../utils/logger';

import { accuracyImg, easeOfUseImg, noveltyImg, timelinessImg } from '@/assets';
import { contactsApi } from '../../../api/contacts';

export const headers = [
  {
    text: 'Name',
    value: 'name',
  },
  {
    text: 'Position',
    value: 'title',
  },
  {
    text: 'Function',
    value: 'function',
  },
  {
    text: 'Accuracy',
    value: 'accuracyOverlapScore',
  },
  {
    text: 'Novelty',
    value: 'noveltyOverlapScore',
  },
  {
    text: 'Timeliness',
    value: 'timelinessOverlapScore',
  },
  {
    text: 'Ease of Use',
    value: 'easeOfUseOverlapScore',
  },
];

export default defineComponent({
  props: {
    companyName: {
      type: String,
    },
    activePositions: { type: Array },
  },
  setup(props) {
    const anteFields = {
      accuracyOverlapScore: {
        label: 'Accuracy',
        img: accuracyImg,
      },
      noveltyOverlapScore: {
        label: 'Novelty',
        img: noveltyImg,
      },
      timelinessOverlapScore: {
        label: 'Timeliness',
        img: timelinessImg,
      },
      easeOfUseOverlapScore: {
        label: 'Ease of Use',
        img: easeOfUseImg,
      },
    };

    const items = ref([]);
    const totalItems = ref(null);
    const loading = ref(false);
    const searchParams = reactive({
      limit: 0,
    });

    const infiniteScrollDisabled = computed(() => items.value.length === totalItems.value);

    const { formatPercent } = useFormatter();

    const calculateTHClass = (columnName) => {
      const classes = [];
      if (columnName == 'name') classes.push('name-cell');
      if (columnName == 'title') classes.push('title-cell');
      if (columnName == 'function') classes.push('function-cell');

      if (columnName === 'name' || columnName === 'title' || columnName === 'function') {
        classes.push('string-cell');
      } else {
        classes.push('number-cell');
      }
      return classes.join(' ');
    };

    const bodyColumns = (columns) => {
      const headerFields = Object.values(headers).map((header) => header.value);
      return headerFields.map((field) => [field, columns[field]]);
    };

    const rowIsActive = (title) => props?.activePositions?.includes(title);

    const loadCompanies = async () => {
      try {
        loading.value = true;
        const { data } = await contactsApi.fetchContacts(props.companyName, searchParams);
        if (data) {
          items.value = data.resultList;
          totalItems.value = data.totalCount || 0;
        }
      } catch (error) {
        logError(error);
      } finally {
        loading.value = false;
      }
    };

    const loadMoreCompanies = () => {
      searchParams.limit += 20;
      loadCompanies();
    };

    watch(
      () => props.companyName,
      (value) => {
        if (value) {
          loadMoreCompanies();
        }
      },
      { immediate: true, deep: true }
    );

    return {
      headers,
      calculateTHClass,
      formatPercent,
      loadMoreCompanies,
      items,
      totalItems,
      loading,
      rowIsActive,
      anteFields,
      bodyColumns,
      infiniteScrollDisabled,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/styles/variables.scss';

$tableWidth: 720px;

.buyer-stats {
  background-color: var(--v-grey-lighten5);
  position: relative;
  overflow: scroll;
  z-index: $z-index-base;

  &__table-wrapper {
    min-width: 720px;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__table {
    display: flex;
    flex-direction: column;
  }

  &__top {
    position: sticky;
    top: 24px;
    left: 0;
    display: flex;
    align-items: center;
    height: 0;
    width: 34%;
    background-color: var(--v-grey-lighten5);
    z-index: $z-index-fixedBlockTop;

    &-title {
      font-weight: bold;
    }
  }

  &__head {
    display: block;
    width: 100%;
    position: sticky;
    top: 0;
    background-color: var(--v-grey-lighten5);
    z-index: $z-index-fixedBlockHead;

    &-row {
      display: table;
      width: 100%;
    }

    &-column {
      position: relative;
      font-weight: bold;
      border-bottom: 2px solid darkgrey !important;

      &-ante {
        position: absolute;
        bottom: 8px;
        left: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        transform: translateX(-50%);
      }

      &-string {
        margin-top: 70px;
      }
    }
  }

  &__body {
    display: block;
    height: fit-content;
    position: relative;
    z-index: $z-index-fixedBlockBody;
    background-color: var(--v-grey-lighten5);

    &-row {
      display: flex;
      width: 100%;
      cursor: pointer;

      &--active {
        background-color: var(--v-grey-lighten1);

        .number-cell {
          background-color: var(--v-grey-lighten1);
        }

        .name-cell {
          background-color: var(--v-grey-lighten1);
        }

        .title-cell {
          background-color: var(--v-grey-lighten1);
        }
        .string-cell {
          background-color: var(--v-grey-lighten1);
        }
      }

      &:hover {
        .number-cell {
          background-color: var(--v-secondary-lighten5);
        }
        .string-cell {
          background-color: var(--v-grey-lighten3);
        }
      }
    }

    &-column {
      display: flex;
      justify-content: center;
      align-items: center;

      &.string-cell {
        justify-content: flex-start;
      }
    }
  }

  &__fillers {
    display: flex;
    justify-content: space-between;
    height: inherit;
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background: darkgrey;
    border-radius: 10px;
  }
}

.string-cell {
  background-color: var(--v-grey-lighten5);
}
.name-cell {
  width: 16%;
}
.title-cell {
  width: 27%;
}
.function-cell {
  width: 13%;
}
.number-cell {
  width: 11%;
  text-align: center !important;
  background-color: var(--v-additional-lighten5);
}
.number-cell-filler {
  background-color: var(--v-additional-lighten5);
  width: 44%;
  height: 100%;
  z-index: $z-index-backgroundLower;
}

.string-cell-filler {
  background-color: var(--v-grey-lighten5);
  width: 56%;
  height: 100%;
  z-index: $z-index-background;
}

.name-cell {
  position: sticky;
  left: 0;
  background-color: var(--v-grey-lighten5);
  z-index: $z-index-base;
}

.title-cell {
  position: sticky;
  left: 102px;
  background-color: var(--v-grey-lighten5);
  border-right: 1px darkgrey solid;
  z-index: $z-index-base;
}

::v-deep {
  .v-input--selection-controls__input .v-icon {
    color: var(--v-grey-lighten2);
  }
  .v-data-table__wrapper {
    overflow-x: unset;
    overflow-y: unset;
    .v-text-field--placeholder {
      font-size: 13px;
    }
    .v-icon {
      cursor: pointer;
    }
  }
  .v-label {
    font-size: 12px;
  }
  .v-input--selection-controls__input .v-icon {
    color: var(--v-grey-lighten2);
  }
}
</style>

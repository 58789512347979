import { Actions, Mutations } from './types';
import { institutesApi } from '../../../api/institutes';

const actions = {
  [Actions.LOAD_INSTITUTE]: async ({ commit }, instituteId) => {
    const { data } = await institutesApi.fetchInstitute(instituteId);
    commit(Mutations.SET_INSTITUTE, data);
    return data;
  },
};

export default actions;

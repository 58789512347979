<template>
  <VContainer>
    <div class="font-weight-bold primary--text d-flex justify-center flex-column align-center">
      <div class="header-text">DB Update</div>
    </div>
    <div class="mt-10 d-flex flex-column px-12">
      <VBtn class="mb-2" color="error" outlined @click="onResetDbClick"> Reset Db Data </VBtn>
      <VBtn class="mb-2" color="primary" outlined @click="onSeedCompaniesClick">
        Seed Companies
      </VBtn>
      <VBtn class="mb-2" color="primary" outlined @click="onSeedSectorsClick"> Seed Sectors </VBtn>
      <VBtn class="mb-2" color="primary" outlined @click="onF13SectorCoverageClick">
        Seed F13 Sector Coverage
      </VBtn>
      <VBtn class="mb-2" color="primary" outlined @click="onF13CompanyCoverageClick">
        Seed F13 Company Coverage
      </VBtn>
      <VBtn class="mb-2" color="primary" outlined @click="onF13CompanyCoveragePreviousClick">
        Seed F13 Company Coverage Previous
      </VBtn>
      <VBtn class="mb-2" color="primary" outlined @click="onContactsClick"> Seed Contacts </VBtn>
    </div>
  </VContainer>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import { seedApi } from '../../api/seed';
import { useStore } from '@/uses/useStore';
import { StoreModules as Modules } from '@/store';
import { Actions } from '@/store/modules/user/types';

export default defineComponent({
  setup() {
    const { useActions } = useStore(Modules.USER);
    const { LOGOUT } = useActions([Actions.LOGOUT]);

    const onResetDbClick = async () => {
      await seedApi.resetDbData();
      LOGOUT();
    };

    const onSeedCompaniesClick = async () => {
      await seedApi.seedCompanies();
    };

    const onSeedSectorsClick = async () => {
      await seedApi.seedSectors();
    };

    const onF13SectorCoverageClick = async () => {
      await seedApi.seedF13SectorCoverage();
    };

    const onF13CompanyCoverageClick = async () => {
      await seedApi.seedF13CompanyCoverage();
    };

    const onF13CompanyCoveragePreviousClick = async () => {
      await seedApi.seedF13CompanyCoveragePrevious();
    };

    const onContactsClick = async () => {
      await seedApi.seedContacts();
    };

    return {
      onResetDbClick,
      onSeedCompaniesClick,
      onSeedSectorsClick,
      onF13SectorCoverageClick,
      onF13CompanyCoverageClick,
      onContactsClick,
      onF13CompanyCoveragePreviousClick,
    };
  },
});
</script>

<style scoped lang="scss"></style>

export const headers = [
  { text: 'Name', value: 'name' },
  { text: 'Company', value: 'company' },
  { text: 'Email', value: 'email' },
  {
    text: 'ANTE Scores',
    value: 'ante',
    categories: {
      accuracy: {
        text: 'Accuracy',
        value: [
          {
            label: 'App',
            text: 'Application',
            value: 'ac01',
          },
          {
            label: 'KPI',
            text: 'KPI Predictive',
            value: 'ac02',
          },
          {
            label: 'Mar',
            text: 'Market Predictive',
            value: 'ac03',
          },
          {
            label: 'Dep',
            text: 'Depth',
            value: 'ac04',
          },
        ],
      },
      novelty: {
        text: 'Novelty',
        value: [
          {
            label: 'Com',
            text: 'Competition',
            value: 'nv01',
          },
          {
            label: 'Moa',
            text: 'Moat',
            value: 'nv02',
          },
          {
            label: 'Inn',
            text: 'Innovation',
            value: 'nv03',
          },
          {
            label: 'Bre',
            text: 'Breadth',
            value: 'nv04',
          },
        ],
      },
      timeliness: {
        text: 'Timeliness',
        value: [
          {
            label: 'His',
            text: 'History',
            value: 'tm01',
          },
          {
            label: 'Lat',
            text: 'Latency',
            value: 'tm02',
          },
          {
            label: 'Cad',
            text: 'Cadence',
            value: 'tm03',
          },
          {
            label: 'Dur',
            text: 'Duration',
            value: 'tm04',
          },
        ],
      },
      easeOfUse: {
        text: 'Ease of Use',
        value: [
          {
            label: 'Str',
            text: 'Structure',
            value: 'eu01',
          },
          {
            label: 'Qua',
            text: 'Quality',
            value: 'eu02',
          },
          {
            label: 'Tes',
            text: 'Testability',
            value: 'eu03',
          },
          {
            label: 'Wor',
            text: 'Workflow',
            value: 'eu04',
          },
        ],
      },
    },
  },
  { text: 'Date of ANTE scores', value: 'anteDate' },
  { text: 'Start Date', value: 'accessStartDate' },
  { text: 'End Date', value: 'accessEndDate' },
];

<template>
  <VContainer>
    <div class="font-weight-bold primary--text">
      <div class="page-header pl-3" :class="{ 'header-text pl-0': $vuetify.breakpoint.smAndUp }">
        Matchup
      </div>
      <div class="text-h6 page-subheader">Top Data Buyers in Finance</div>
    </div>
    <TopPanel />
    <CompaniesTable />
  </VContainer>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import CompaniesTable from './molecules/CompaniesTable';
import TopPanel from '../../components/TopPanel';

export default defineComponent({
  components: {
    CompaniesTable,
    TopPanel,
  },
  setup() {
    //
  },
});
</script>

<style scoped lang="scss">
::v-deep {
  .v-list-item {
    padding-left: 8px;
    &__action {
      margin: 0 5px 0 0 !important;
    }
    .mdi-checkbox-marked::before {
      color: var(--v-additional-lighten1);
    }
  }
}
</style>

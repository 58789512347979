<template>
  <VCard class="pa-3 buyer-map-container" color="grey lighten-1" flat>
    <div class="subheader-text font-weight-bold buyer-map-subheader">Buyer Map</div>
    <div class="buyer-map">
      <div class="buyer-map--text">
        <p
          @mouseenter="setStage(stageNames.STAGE1)"
          @mouseleave="resetStage"
          class="buyer-map--label primary--text"
        >
          1. {{ stages[stageNames.STAGE1].label }}
        </p>
        <p
          @mouseenter="setStage(stageNames.STAGE2)"
          @mouseleave="resetStage"
          class="buyer-map--label primary--text"
        >
          2. {{ stages[stageNames.STAGE2].label }}
        </p>
        <p
          @mouseenter="setStage(stageNames.STAGE3)"
          @mouseleave="resetStage"
          class="buyer-map--label primary--text"
        >
          3. {{ stages[stageNames.STAGE3].label }}
        </p>
      </div>
      <div class="buyer-map--visualization">
        <div class="buyer-map--visualization-row">
          <span
            class="position"
            :class="
              getClass([stageNames.STAGE1, stageNames.STAGE2, stageNames.STAGE3], 'Procurement')
            "
            @mouseenter="setPosition('Procurement')"
            @mouseleave="setPosition('')"
          >
            Procurement
          </span>
          <DualArrow class="horizontal-arrow" :class="getClass([stageNames.STAGE2])" />
          <span
            class="position"
            :class="getClass([stageNames.STAGE2], 'Data Science')"
            @mouseenter="setPosition('Data Science')"
            @mouseleave="setPosition('')"
          >
            Data Science
          </span>
        </div>
        <div
          class="buyer-map--visualization-row buyer-map--visualization-row-middle"
          :class="getClass(stageNames.STAGE2)"
        >
          <DualArrow class="diagonal-arrow-small-left" :class="getClass([stageNames.STAGE3])" />
          <span
            class="position"
            :class="getClass([stageNames.STAGE3], 'Portfolio Manager')"
            @mouseenter="setPosition('Portfolio Manager')"
            @mouseleave="setPosition('')"
          >
            Portfolio Manager
          </span>
          <DualArrow class="vertical-arrow" :class="getClass([stageNames.STAGE3])" />
        </div>
        <div class="buyer-map--visualization-row">
          <DualArrow class="diagonal-arrow-long-left" :class="getClass([stageNames.STAGE2])" />
          <span
            class="position"
            :class="getClass([stageNames.STAGE1, stageNames.STAGE2, stageNames.STAGE3], 'Analyst')"
            @mouseenter="setPosition('Analyst')"
            @mouseleave="setPosition('')"
          >
            Analyst
          </span>
          <DualArrow class="diagonal-arrow-long-right" :class="getClass([stageNames.STAGE2])" />
        </div>
      </div>
    </div>
  </VCard>
</template>

<script>
import { ref, watch } from '@vue/composition-api';
import DualArrow from '../atoms/DualArrow.vue';
export default {
  components: { DualArrow },
  props: {
    stages: { type: Object },
    stageNames: { type: Object },
    contact: { type: Object },
  },
  emits: ['setPositions'],
  setup(props, { emit }) {
    const currentStage = ref(null);
    const selectedFunction = ref(null);

    const setStage = (stage) => {
      currentStage.value = stage;
      emit('setPositions', props.stages?.[currentStage.value]?.positions);
    };

    const setPosition = (position) => {
      emit('setPositions', [position]);
    };

    watch(
      () => props,
      (value) => {
        if (value) {
          selectedFunction.value = value.contact.function;
        }
      },
      { immediate: true, deep: true }
    );

    const resetStage = () => {
      currentStage.value = '';
      emit('setPositions', []);
    };

    const getClass = (stages, func) => {
      return stages.includes(currentStage.value) || selectedFunction.value === func
        ? 'buyer-map--visualization-item--active'
        : 'buyer-map--visualization-item';
    };

    return {
      currentStage,
      setStage,
      resetStage,
      getClass,
      setPosition,
      selectedFunction,
    };
  },
};
</script>
<style scoped lang="scss">
@import '~vuetify/src/styles/settings/_variables.scss';
.buyer-map-container {
  display: flex;
  flex-direction: column;
  min-width: 550px;
  width: 100%;
}

.buyer-map-subheader {
  margin-bottom: 48px;
}

.buyer-map {
  display: flex;
  justify-content: space-between;
  flex: 1;
  padding-bottom: 16px;

  &--label {
    padding-bottom: 64px;
    margin-bottom: 0;

    &:last-child {
      padding-bottom: 0;
    }

    &:hover {
      font-weight: bold;
    }
  }

  &--visualization {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 365px;
    height: 265px;
    font-weight: bold;

    &-item {
      color: rgba(1, 43, 62, 0.3);

      &--active {
        color: rgba(1, 43, 62, 1);
      }
    }

    &-row {
      position: relative;
      width: 100%;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: bold;

      .position {
        &:hover {
          color: rgba(1, 43, 62, 1);
        }
      }

      &:last-child {
        justify-content: center;
      }
    }

    &-row-middle {
      width: 35%;
      text-align: center;
    }
  }
}

.horizontal-arrow {
  height: 145px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(90deg);
}

.diagonal-arrow-small-left {
  position: absolute;
  top: 0;
  left: -10%;
  transform: translate(75%, -75%) rotate(-30deg);
  height: 130px;
}

.diagonal-arrow-long-left {
  position: absolute;
  bottom: 5px;
  left: 30%;
  height: 255px;
  transform: translateX(-50%) rotateZ(-30deg);
}

.diagonal-arrow-long-right {
  position: absolute;
  bottom: 5px;
  right: 30%;
  height: 255px;
  transform: translateX(-50%) rotateZ(30deg);
}

.vertical-arrow {
  position: absolute;
  bottom: 0;
  left: 50%;
  height: 85px;
  transform: translate(-100%, 100%);
}
@media #{map-get($display-breakpoints, 'xs-only')} {
  .buyer-map-container {
    height: 425px;
    min-width: 300px;
  }

  .buyer-map-subheader {
    margin-bottom: 24px;
  }

  .buyer-map {
    flex-direction: column;

    &--label {
      padding-bottom: 16px;
    }
    &--visualization {
      width: 275px;
      height: 200px;
      align-self: center;
    }
  }
  .vertical-arrow {
    height: 55px;
  }

  .diagonal-arrow-small-left {
    top: 17%;
    left: -15%;
    height: 92px;
  }

  .diagonal-arrow-long-left {
    bottom: 9px;
    height: 180px;
  }

  .diagonal-arrow-long-right {
    bottom: 9px;
    height: 180px;
  }

  .horizontal-arrow {
    height: 80px;
  }
}
</style>

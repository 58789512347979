<template>
  <tbody
    class="table-body d-block"
    v-infinite-scroll="loadMoreUsers"
    infinite-scroll-disabled="infiniteScrollDisabled"
    infinite-scroll-distance="10"
    ref="tableBodyRef"
  >
    <tr v-for="item in items" class="table-row" :key="item._id">
      <td
        v-for="[key, value] in getTableRow(item)"
        class="cell"
        :key="key"
        :class="`${key}-container`"
        :align="calculateAlign(key)"
      >
        <div v-if="isDateField(key)" class="cell-content">
          <span class="primary--text" :class="calculateMargin(key)">
            {{ formatDate(value) || '##/##/####' }}
          </span>
        </div>

        <div v-else-if="key === 'anteScore'" class="d-flex h-100">
          <template v-if="!isEmpty(value)">
            <div
              v-for="anteName in Object.values(value)"
              class="d-flex h-100 ante-score-cell-container"
              :key="anteName.label"
            >
              <span
                v-for="(ante, i) in getAnteScores(anteName)"
                class="h-100 ante-score-cell h-100"
                :key="i"
              >
                {{ getScoreByAnte(ante) }}
              </span>
            </div>
          </template>

          <template v-else>
            <div v-for="i in 4" class="d-flex h-100 ante-score-cell-container" :key="i">
              <span v-for="i in 4" class="h-100 ante-score-cell h-100" :key="i">##</span>
            </div>
          </template>
        </div>

        <div v-else class="cell-content">
          <VMenu open-on-hover bottom right offset-y rounded="0">
            <template v-slot:activator="{ on, attrs }">
              <span class="primary--text" :class="calculateMargin(key)" v-bind="attrs" v-on="on">
                {{ value }}
              </span>
            </template>
            <div v-if="checkIfTruncated(key, value)" class="cell-content-label">{{ value }}</div>
          </VMenu>
        </div>
      </td>
      <VMenu bottom left offset-y rounded>
        <template v-slot:activator="{ on, attrs, value }">
          <VIcon
            class="icon"
            :class="{ 'icon-active': value }"
            v-bind="attrs"
            v-on="on"
            size="15"
            color="primary"
          >
            mdi-pencil
          </VIcon>
        </template>
        <div class="d-flex flex-column py-2 menu-edit">
          <div class="menu-list-item" @click="handleEdit(item)">Edit</div>
          <div class="menu-list-item" @click="handleRemove(item)">Remove</div>
        </div>
      </VMenu>
    </tr>
  </tbody>
</template>
<script>
import { useFormatter } from '@/uses/useFormatter';
import { onMounted, onUnmounted, ref } from '@vue/composition-api';
import { isEmpty } from 'lodash';
import { dateCells, calculateMargin, calculateAlign } from '../utils';
import { measureTextWidth } from '@/utils/measureTextWidth';
import { debounce } from 'lodash';

const fieldPercents = {
  name: 19,
  company: 15,
  email: 23,
};

export default {
  props: {
    items: { type: Array, required: true },
    infiniteScrollDisabled: { type: Boolean, required: true },
  },
  emits: ['loadMoreUsers', 'handleEditUser', 'handleRemoveUser'],
  setup(props, { emit }) {
    const { formatDate } = useFormatter();
    const tableBodyRef = ref(null);
    const tableRowWidth = ref(null);

    onMounted(() => {
      window.addEventListener('resize', getTableBodyWidth);
      getTableBodyWidth();
    });

    onUnmounted(() => {
      window.removeEventListener('resize', getTableBodyWidth);
    });

    const getTableBodyWidth = debounce(() => {
      const anteStaticFieldWidth = 640;
      const editButtonFieldWidth = 10;
      tableRowWidth.value =
        tableBodyRef.value?.clientWidth - anteStaticFieldWidth - editButtonFieldWidth || 0;
    }, 200);

    const getTableRow = (item) => {
      return Object.entries(item).filter((el) => el[0] !== '_id');
    };

    const checkIfTruncated = (field, value) => {
      const width = measureTextWidth(value, 14);
      const fieldMarginLeft = 16;
      const fieldMarginRight = 4;
      const offset = 4;

      const fieldMaxWidth =
        (tableRowWidth.value * fieldPercents[field]) / 100 -
        fieldMarginLeft -
        fieldMarginRight -
        offset;

      return width > fieldMaxWidth;
    };

    const loadMoreUsers = () => {
      emit('loadMoreUsers');
    };

    const getAnteScores = (anteName) => {
      return Object.values(anteName).filter((ante) => ante?.score >= 0);
    };

    const getScoreByAnte = (ante) => {
      if (ante?.score === null || ante?.score === undefined) return '##';
      return ante?.score >= 0 ? ante?.score : '##';
    };

    const handleEdit = (item) => {
      emit('handleEditUser', item);
    };

    const handleRemove = (item) => {
      emit('handleRemoveUser', item);
    };

    const isDateField = (fieldName) => {
      return dateCells.includes(fieldName);
    };

    return {
      formatDate,
      dateCells,
      calculateAlign,
      getAnteScores,
      calculateMargin,
      loadMoreUsers,
      isEmpty,
      checkIfTruncated,
      getScoreByAnte,
      handleEdit,
      handleRemove,
      getTableRow,
      isDateField,
      tableBodyRef,
    };
  },
};
</script>
<style scoped lang="scss">
@import '../styles';

.cell-content {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  white-space: nowrap;
}

.cell-content-label {
  padding: 5px 7px;
  background-color: #fff;
}

.table-body {
  height: 600px;
  overflow-y: auto;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.menu-edit {
  width: 150px;
  background: #fff;

  .menu-list-item {
    cursor: pointer;
    padding: 5px 10px;

    .active {
      opacity: 1;
    }
    &:hover {
      background-color: var(--v-grey-lighten3);
    }
  }
}
.table-row {
  position: relative;
  padding-right: 10px;
  .icon {
    position: absolute;
    top: 45%;
    right: 5px;
    transform: translateY(-50%);
    opacity: 0;
  }

  .icon-active {
    opacity: 1;
  }

  &:hover .icon {
    opacity: 0.4;
  }

  .icon:hover {
    opacity: 1;
  }
}

@media screen and (max-width: 960px) {
  .table-row {
    .icon {
      opacity: 0.4;
    }
  }
}
</style>

<template>
  <div>
    <div class="dual-arrow">
      <span class="arrow arrow-up"> </span>
      <div class="stick"></div>
      <span class="arrow arrow-down"> </span>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped lang="scss">
.dual-arrow {
  position: relative;
  height: 100%;
}

.arrow {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 7px 15px 7px;
  border-color: transparent transparent currentColor;
}

.arrow-up {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-50%);
}

.arrow-down {
  position: absolute;
  bottom: 0;
  left: 0;
  transform: rotateX(180deg) translateX(-50%);
}

.stick {
  position: absolute;
  top: 15px;
  left: 0;
  transform: translateX(-50%);
  height: calc(100% - 30px);
  width: 3px;
  background-color: currentColor;
}
</style>

<template>
  <VDataTable
    class="similarities-score"
    :headers="headers"
    :items="items"
    :items-per-page="totalItems"
    hide-default-header
    hide-default-footer
  >
    <template #top>
      <div class="mb-3 pl-5 pt-3">
        <span class="similarities-score__title subheader-text"> Similarities score </span>
      </div>
    </template>
    <template #header="{ props }">
      <thead class="similarities-score__head">
        <tr class="similarities-score__head-row">
          <th
            v-for="header in props.headers"
            :class="calculateTHClass(header.value)"
            class="similarities-score__head-column cell body-2 primary--text"
            :key="header.value"
          >
            <div v-if="header.value === 'score'" class="similarities-score__head-score ml-4">
              {{ header.text }}
            </div>
            <div v-else class="ml-4">{{ header.text }}</div>
          </th>
        </tr>
      </thead>
    </template>
    <template #body="{ items }">
      <VOverlay color="primary" :value="loading" absolute />
      <tbody
        v-infinite-scroll="loadMoreCompanies"
        infinite-scroll-disabled="infiniteScrollDisabled"
        class="similarities-score__body"
      >
        <tr
          v-for="item in items"
          :key="item._id"
          class="similarities-score__body-row"
          :class="{ 'similarities-score__body-row--active': rowIsActive(item.position) }"
        >
          <td
            v-for="(itemName, itemNameKey) in item"
            :key="itemNameKey"
            class="similarities-score__body-column cell"
            :class="calculateTHClass(itemNameKey)"
          >
            <span class="primary--text ml-4"> {{ getColumnText(itemNameKey, itemName) }} </span>
          </td>
        </tr>
      </tbody>
    </template>
  </VDataTable>
</template>

<script>
import { defineComponent, reactive, ref } from '@vue/composition-api';
import { useFormatter } from '../../../uses/useFormatter';
import { logError } from '../../../utils/logger';

export const headers = [
  {
    text: 'Name',
    value: 'name',
  },
  {
    text: 'Position',
    value: 'position',
  },
  {
    text: 'Phase',
    value: 'phase1',
  },
  {
    text: 'Phase',
    value: 'phase2',
  },
  {
    text: 'Similarities Score',
    value: 'score',
  },
];

const initialItem = {
  name: 'Joe Great',
  position: 'Portfolio Manager',
  phase1: 'First Touch',
  phase2: 'Gatekeeper',
  score: 80,
};
const initialItem2 = {
  name: 'John Doe',
  position: 'Analyst',
  phase1: 'First Touch',
  phase2: 'Gatekeeper',
  score: 70,
};

const initialItems = new Array(22)
  .fill('')
  .map(() => (Math.random() > 0.5 ? initialItem : initialItem2));

const stringFields = ['name', 'position', 'phase1', 'phase2'];
export default defineComponent({
  props: {
    activePositions: { type: Array },
  },
  setup(props) {
    const items = ref([]);
    const totalItems = ref(null);
    const loading = ref(false);
    const searchParams = reactive({
      limit: 0,
    });

    const { formatPercent, formatCurrency } = useFormatter();

    const loadCompanies = async () => {
      try {
        loading.value = true;
        setTimeout(() => {
          items.value = initialItems;
          totalItems.value = 20;
        }, 100);
      } catch (error) {
        logError(error);
      } finally {
        loading.value = false;
      }
    };

    const loadMoreCompanies = () => {
      searchParams.limit += 20;
      loadCompanies();
    };

    const calculateTHClass = (columnName) => {
      if (stringFields.includes(columnName)) {
        return 'string-cell';
      }
      return 'number-cell';
    };

    const getColumnText = (columnName, text) => {
      if (stringFields.includes(columnName)) return text;
      return formatPercent(text, 0);
    };

    const rowIsActive = (position) => {
      return props?.activePositions?.includes(position);
    };

    return {
      headers,
      calculateTHClass,
      formatPercent,
      formatCurrency,
      loadMoreCompanies,
      items,
      totalItems,
      loading,
      getColumnText,
      rowIsActive,
    };
  },
});
</script>

<style scoped lang="scss">
.similarities-score {
  background-color: var(--v-grey-lighten5);
  position: relative;
  min-width: 600px;

  &__title {
    font-weight: bold;
  }

  &__head {
    display: block;
    width: 100%;
    overflow-y: scroll !important;

    &-score {
      text-align: center;
    }

    &-row {
      display: table;
      width: 100%;
    }

    &-column {
      font-weight: bold;
      border-bottom: 2px solid darkgrey !important;
    }
  }

  &__body {
    display: block;
    overflow-y: scroll !important;
    height: 500px;

    &-row {
      display: flex;
      width: 100%;

      &--active {
        background-color: var(--v-grey-lighten1);
      }
    }

    &-column {
      display: flex;
      justify-content: center;
      align-items: center;

      &.string-cell {
        justify-content: flex-start;
      }
    }
  }
}

.string-cell {
  width: 21%;
}

.number-cell {
  width: 16%;
}

::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-thumb {
  background: darkgrey;
  border-radius: 10px;
}
::v-deep .v-input--selection-controls__input .v-icon {
  color: var(--v-grey-lighten2);
}
.v-data-table__wrapper {
  overflow: unset;
  .v-text-field--placeholder {
    font-size: 13px;
  }
  .v-icon {
    cursor: pointer;
  }
}
</style>

import { format } from 'date-fns';
import { enUS } from 'date-fns/locale';

export function useFormatter() {
  const formatPercent = (value, fractionDigits = 2, signDisplay = 'auto') => {
    if (value === null || value === undefined) return '';

    return new Intl.NumberFormat('en-US', {
      style: 'percent',
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits,
      signDisplay,
    }).format(value / 100);
  };

  const formatCurrency = (value, fractionDigits = 2, currency = 'USD') => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency,
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits,
    }).format(value);
  };

  const formatDate = (date) => {
    if (!date) return '';
    return format(new Date(date), 'MM/dd/yyyy', { locale: enUS });
  };

  const formatDateHour = (date) => {
    if (!date) return '';
    return format(new Date(date), "hh:mmaaaaa'm'", { locale: enUS });
  };

  return { formatPercent, formatCurrency, formatDate, formatDateHour };
}

<template>
  <VContainer>
    <div class="font-weight-bold primary--text">
      <div class="page-header pl-3" :class="{ 'header-text pl-0': $vuetify.breakpoint.smAndUp }">
        Scouting Report: Company
      </div>
      <div class="text-h6 page-subheader">
        {{ $route.params.id }}
      </div>
    </div>
    <TopPanel />
    <VRow dense>
      <VCol :cols="companyInfoCols">
        <VRow dense>
          <VCol cols="12" md="6">
            <CompanyStats :company="company" />
          </VCol>
          <VCol cols="12" md="6">
            <AnteMatch
              :companyMatchup="companyMatchup"
              :companyDetails="company"
              :loading="isMatchupLoading"
            />
          </VCol>
        </VRow>
      </VCol>
      <VCol :cols="gameplanCols"><Gameplan :company="company" /></VCol>
    </VRow>
    <VRow dense class="page-row">
      <VCol class="page-column-8">
        <div class="overflow-x-auto">
          <BuyerStats :activePositions="activePositions" :companyName="searchParams.companyName" />
        </div>
      </VCol>
      <VCol class="page-column-4">
        <BuyerMap :stageNames="STAGES" :stages="stages" @setPositions="setPositions" />
      </VCol>
    </VRow>
    <VRow dense>
      <VCol class="flex-grow-1">
        <div class="overflow-x-auto"><F13SectorCoverage /></div>
      </VCol>
      <VCol class="flex-grow-1">
        <div class="overflow-x-auto">
          <F13CompanyCoverage />
        </div>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import { defineComponent, watch, ref, computed } from '@vue/composition-api';
import F13CompanyCoverage from './molecules/F13CompanyCoverage';
import F13SectorCoverage from './molecules/F13SectorCoverage';
import CompanyStats from './molecules/CompanyStats';
import AnteMatch from './molecules/AnteMatch';
import Gameplan from './molecules/Gameplan';
import BuyerStats from './molecules/BuyerStats';
import BuyerMap from './molecules/BuyerMap';
import TopPanel from '../../components/TopPanel';
import { companiesApi } from '../../api/companies';
import { reactive } from '@vue/composition-api/dist/vue-composition-api.esm';
import { useStore } from '../../uses/useStore';
import { StoreModules as Modules } from '../../store';
import { States as DeliveryTypesStates } from '../../store/modules/deliveryType/types';
import { States as SectorsStates } from '../../store/modules/sectors/types';

const COMPANIES_LIMIT = 200;

export default defineComponent({
  components: {
    F13CompanyCoverage,
    F13SectorCoverage,
    TopPanel,
    CompanyStats,
    Gameplan,
    BuyerStats,
    BuyerMap,
    AnteMatch,
  },
  setup(_, { root }) {
    const company = ref(null);
    const companyMatchup = ref(null);
    const isMatchupLoading = ref(false);

    const searchParams = reactive({
      offset: 0,
      limit: COMPANIES_LIMIT,
      companyName: null,
      isDB: false,
      isReport: false,
      sectors: [],
    });

    const STAGES = {
      STAGE1: 'STAGE1',
      STAGE2: 'STAGE2',
      STAGE3: 'STAGE3',
    };

    const stages = {
      [STAGES.STAGE1]: {
        label: 'Introduction',
        positions: ['Procurement', 'Analyst'],
      },
      [STAGES.STAGE2]: {
        label: 'Qualify & evaluate',
        positions: ['Procurement', 'Data Science', 'Analyst'],
      },
      [STAGES.STAGE3]: {
        label: 'Close',
        positions: ['Procurement', 'Portfolio Manager', 'Analyst'],
      },
    };

    const activePositions = ref([]);

    const { useState: useDeliveryTypeState } = useStore(Modules.DELIVERY_TYPE);
    const { useState: useSectorsState } = useStore(Modules.SECTORS);
    const { deliveryType } = useDeliveryTypeState([DeliveryTypesStates.deliveryType]);
    const { selectedSectors } = useSectorsState([SectorsStates.selectedSectors]);

    watch(
      () => [deliveryType.value, selectedSectors.value],
      async ([type, sectors]) => {
        searchParams.companyName = root.$router.currentRoute.params.id;
        searchParams.isDB = type.isDB;
        searchParams.isReport = type.isReport;
        searchParams.sectors = sectors;
        const { data } = await companiesApi.fetchCompany(root.$router.currentRoute.params.id);
        isMatchupLoading.value = true;
        const { data: matchupData } = await companiesApi.fetchCompanyMatchup(searchParams);
        isMatchupLoading.value = false;
        companyMatchup.value = matchupData.resultList[0];
        company.value = data;
      },
      { deep: true }
    );

    const gameplanCols = computed(() => {
      const breakpoint = 1330;
      const fullRow = root.$vuetify.breakpoint.width <= breakpoint;
      return fullRow ? 12 : 4;
    });

    const companyInfoCols = computed(() => {
      const breakpoint = 1330;
      const fullRow = root.$vuetify.breakpoint.width <= breakpoint;
      return fullRow ? 12 : 8;
    });

    const setPositions = (positions) => {
      activePositions.value = positions;
    };

    return {
      company,
      gameplanCols,
      stages,
      setPositions,
      STAGES,
      activePositions,
      companyInfoCols,
      searchParams,
      companyMatchup,
      isMatchupLoading,
    };
  },
});
</script>

<style scoped lang="scss">
.page-column-8 {
  flex-grow: 8;
  flex-shrink: 0;
}

.page-column-4 {
  flex-grow: 4;
  flex-shrink: 0;
}
</style>

<template>
  <VCard v-if="loading" class="pa-3 ante-match" color="grey lighten-5" height="250" flat>
    <div class="subheader-text font-weight-bold mb-2">ANTE Match</div>
    <VSkeletonLoader type="heading@4" tile />
  </VCard>
  <VCard
    v-else-if="companyMatchup"
    class="pa-3 ante-match"
    color="grey lighten-5"
    height="250"
    flat
  >
    <div class="subheader-text font-weight-bold mb-2">ANTE Match</div>
    <div class="d-flex align-center">
      <div class="ante-match__title mr-2">
        <div class="ante-match__percent ante-match__label-score subheader-text">
          {{ companyMatchup.averageScore }}%
        </div>
        <div class="ante-match__label ante-match__label-score">Score</div>
      </div>
      <VProgressLinear
        :value="companyMatchup.averageScore"
        color="secondary"
        background-color="secondary lighten-4"
        height="23"
      />
    </div>
    <div class="d-flex align-center">
      <div class="ante-match__title mr-2">
        <div class="ante-match__percent ante-match__label-13f subheader-text">
          {{ companyMatchup.f13Overlap }}%
        </div>
        <div class="ante-match__label ante-match__label-13f">13F</div>
      </div>
      <VProgressLinear
        :value="companyMatchup.f13Overlap"
        color="grey darken-2"
        background-color="grey lighten-1"
        height="23"
      />
    </div>
    <div class="d-flex align-center">
      <div class="ante-match__title mr-2">
        <div class="ante-match__percent ante-match__label-budget subheader-text">
          {{ companyMatchup.budget }}%
        </div>
        <div class="ante-match__label ante-match__label-budget">Budget</div>
      </div>
      <VProgressLinear
        :value="companyMatchup.budget"
        color="grey darken-2"
        background-color="grey"
        height="23"
      />
    </div>
    <VMenu open-on-hover bottom offset-y min-width="auto">
      <template v-slot:activator="{ on, attrs }">
        <div class="d-flex align-center" v-bind="attrs" v-on="on">
          <div class="ante-match__title align-center mr-2">
            <div class="ante-match__percent ante-match__label-ante subheader-text">
              {{ companyMatchup.anteOverlapScore }}%
            </div>
            <div class="ante-match__label ante-match__label-ante">ANTE</div>
          </div>
          <VProgressLinear
            :value="companyMatchup.anteOverlapScore"
            color="additional"
            background-color="additional lighten-4"
            height="23"
          />
        </div>
      </template>
      <AnteDetailsPanel :ante="{ ...companyDetails, ...companyMatchup }" />
    </VMenu>
  </VCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import AnteDetailsPanel from '../../../components/AnteDetailsPanel';

export default defineComponent({
  components: {
    AnteDetailsPanel,
  },
  props: {
    companyMatchup: {
      type: Object,
    },
    loading: {
      type: Boolean,
    },
    companyDetails: {
      type: Object,
    },
  },
});
</script>

<style scoped lang="scss">
.ante-match {
  &__label-score {
    color: var(--v-secondary-base);
  }

  &__label-13f {
    color: var(-v--additional-darken1);
  }

  &__label-budget {
    color: var(-v--additional-darken1);
  }

  &__label-ante {
    color: var(--v-additional-base);
  }

  &__percent {
    font-weight: bold;
  }

  &__title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 50px;
  }

  &__label {
    font-size: 10px;
  }
}
.theme--light.v-divider {
  border-color: var(--v-grey-darken3) !important;
}
::v-deep {
  .v-skeleton-loader {
    &__heading {
      margin-top: 15px;
      margin-bottom: 15px;
      margin-left: 25px;
      width: 100%;
    }
  }
}
</style>

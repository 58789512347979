<template>
  <VCard class="gameplan pa-3" color="grey lighten-1" height="250" flat>
    <div class="gameplan__title subheader-text mb-2">Gameplan</div>
    <VRow v-if="company">
      <VCol cols="5" class="pb-0 pt-3">
        <div class="body-2 mb-2">
          <span class="font-weight-bold">Procurement: </span>
          <span>{{ company.data_procurement === 'Y' ? 'Yes' : 'No' }}</span>
        </div>
        <div class="body-2 mb-2">
          <span class="font-weight-bold">Data Science: </span>
          <span>{{ company.data_science || '-' }}</span>
        </div>
        <div class="body-2">
          <span class="font-weight-bold">Early Adopter: </span>
          <span>{{ company.early_adopter || '-' }}</span>
        </div>
      </VCol>
      <VCol cols="7" class="pb-0 pt-3">
        <div class="body-2 mb-2">
          <span class="font-weight-bold">Buyer of: </span>
          <span>{{ company.buyer_of === 'All' ? BUYER_OF_LIST : company.buyer_of }}</span>
        </div>
        <div class="body-2 mb-1">
          <span class="font-weight-bold">Data Budget: </span>
          <span>{{ dataBudget }}</span>
        </div>
        <div class="gameplan__progress d-flex mb-2">
          <VProgressLinear
            :value="calculateAllocationDatabaseWidth"
            color="additional"
            background-color="secondary"
            height="23"
          >
            <div class="gameplan__progress-label-wrapper" ref="progressbar">
              <span class="gameplan__progress-label">
                {{ getAllocationDatabaseText }}
              </span>

              <span class="gameplan__progress-label" :style="getReportOffset">
                {{ getAllocationReportsText }}
              </span>
            </div>
          </VProgressLinear>
        </div>
      </VCol>
    </VRow>
    <div v-if="company && company.selling_strategy" class="body-2 pt-2">
      <span class="font-weight-bold">Selling Strategy:</span>
      <span>
        {{ company && company.selling_strategy }}
      </span>
    </div>
  </VCard>
</template>

<script>
import { computed, defineComponent, onMounted, onUnmounted, ref } from '@vue/composition-api';
import { useFormatter } from '../../../uses/useFormatter';
import { debounce } from 'lodash';

const BUYER_OF_LIST =
  'App, B2B, Building/Industrial, Clickstream, Media, Healthcare, Insurance/Financial, Public, Receipts, Social/Sentinment, Transaction, Webscrape';

const dataBudgetRangeList = [
  {
    min: 5000000,
    max: 10000000,
    value: '$5-10M',
  },
  {
    min: 10000000,
    max: 20000000,
    value: '$10-20M',
  },
  {
    min: 10000000,
    max: 20000000,
    value: '$10-20M',
  },
  {
    min: 20000000,
    max: 50000000,
    value: '$20-50M',
  },
  {
    min: 50000000,
    max: 10000000000000,
    value: '$50M+',
  },
];

export default defineComponent({
  props: {
    company: {
      type: Object,
    },
  },
  setup(props) {
    const { formatCurrency } = useFormatter();

    const progressbar = ref(null);
    const progressbarWidth = ref(0);

    const totalAllocation = computed(() => {
      return (
        Number.parseFloat(props.company?.budget_allocation_database) +
        Number.parseFloat(props.company?.budget_allocation_products_reports)
      );
    });

    onMounted(() => {
      window.addEventListener('resize', getProgressbarWidth);
      getProgressbarWidth();
    });

    onUnmounted(() => {
      window.removeEventListener('resize', getProgressbarWidth);
    });

    const getProgressbarWidth = debounce(() => {
      progressbarWidth.value = progressbar.value?.clientWidth || 0;
    }, 200);

    const calculateAllocationDatabaseWidth = computed(() => {
      const width = Number.parseFloat(props.company?.budget_allocation_database);
      return Math.round((width / totalAllocation.value) * 100) || 0;
    });

    const calculateAllocationReportsWidth = computed(() => {
      const width = Number.parseFloat(props.company?.budget_allocation_products_reports);
      return Math.round((width / totalAllocation.value) * 100) || 0;
    });

    const getReportOffset = computed(() => {
      return `left: calc(${calculateAllocationDatabaseWidth.value}% + 8px)`;
    });

    const getAllocationDatabaseText = computed(() => {
      const { value } = calculateAllocationDatabaseWidth;
      if (value < 5) return '';

      return value >= 25 ? `Database: ${value}%` : `${value}%`;
    });

    const getAllocationReportsText = computed(() => {
      const { value } = calculateAllocationReportsWidth;
      if (value < 5) return '';

      return value >= 25 ? `Report: ${value}%` : `${value}%`;
    });

    const dataBudget = computed(() => {
      const dataBudget = props.company?.aum * props.company?.multiplier;
      return (
        dataBudgetRangeList.find((item) => item.min <= dataBudget && item.max > dataBudget)
          ?.value || ''
      );
    });

    return {
      formatCurrency,
      calculateAllocationDatabaseWidth,
      calculateAllocationReportsWidth,
      getReportOffset,
      getAllocationDatabaseText,
      getAllocationReportsText,
      progressbar,
      BUYER_OF_LIST,
      dataBudget,
    };
  },
});
</script>

<style scoped lang="scss">
.gameplan {
  &__title {
    font-weight: bold;
  }

  &__column {
    flex: 1 0 250px;
  }

  &__progress {
    min-width: 200px;
  }

  &__progress-label-wrapper {
    height: 100%;
    width: 100%;
    position: relative;
    margin: 8px;
  }

  &__progress-label {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    font-size: 10px;
    color: white;
  }
}

::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-thumb {
  background: darkgrey;
  border-radius: 10px;
}
</style>

<template>
  <VContainer fill-height fluid>
    <VAppBar height="150" class="primary" app>
      <VRow class="px-12">
        <VCol>
          <VImg max-width="200" height="50" :src="require('../assets/logo.png')" />
        </VCol>
        <VCol align="right">
          <VBtn class="rounded-0 mt-2 text-capitalize white--text pa-6" color="additional darken-1"
            >Contact Us</VBtn
          >
        </VCol>
      </VRow>
    </VAppBar>
    <div class="login-card-container">
      <VCard class="login-card pa-8 mr-0" width="550" flat>
        <VCardTitle class="text-h4 font-weight-bold primary--text">Playbook Log In</VCardTitle>
        <VCardSubtitle class="login-card__subtitle body-1">
          Please enter your credentials to log in
        </VCardSubtitle>
        <VCardText>
          <VForm @submit="onFormSubmit">
            <p class="text-h5 font-weight-bold primary--text">Email</p>
            <VTextField v-model="email" class="rounded-0" placeholder="Email" outlined />
            <p class="text-h5 font-weight-bold primary--text">Password</p>
            <VTextField
              v-model="password"
              class="rounded-0"
              placeholder="Password"
              type="password"
              append-icon="mdi-eye-off"
              outlined
            />
            <VRow class="mb-2">
              <VCol cols="6">
                <VBtn
                  class="login-card__button rounded-0 text-capitalize white--text"
                  :disabled="loading"
                  :loading="loading"
                  color="additional darken-1"
                  type="submit"
                >
                  Log In
                </VBtn>
              </VCol>
            </VRow>
            <a
              class="login-card__password-forgot text-h6 font-weight-bold mt-4"
              @click="$router.push('/forgot-password')"
            >
              Forgot Password?
            </a>
          </VForm>
        </VCardText>
      </VCard>
    </div>
  </VContainer>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api';
import { useStore } from '../uses/useStore';
import { StoreModules as Modules } from '../store';
import { Actions } from '../store/modules/user/types';

export default defineComponent({
  setup() {
    const email = ref('');
    const password = ref('');
    const loading = ref(false);

    const { useActions } = useStore(Modules.USER);
    const { LOGIN } = useActions([Actions.LOGIN]);

    const onFormSubmit = async (event) => {
      event.preventDefault();
      loading.value = true;
      await LOGIN({
        email: email.value,
        password: password.value,
      });
      loading.value = false;
    };

    return {
      email,
      password,
      loading,
      onFormSubmit,
    };
  },
});
</script>

<style scoped lang="scss">
.login-card-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.login-card {
  &__subtitle {
    color: var(--v-grey-base);
  }

  &__button {
    width: 100%;
  }

  &__password-forgot {
    color: var(--v-secondary-base);
  }
}
</style>

import axios from 'axios';
import { logError } from '../utils/logger';

const baseUrl = `/api/v1/seed`;

const resetDbData = async () => {
  const url = `${baseUrl}/reset`;
  try {
    return await axios.post(url);
  } catch (error) {
    logError(error);
    return error;
  }
};

const seedCompanies = async () => {
  const url = `${baseUrl}/companies`;
  try {
    return await axios.post(url);
  } catch (error) {
    logError(error);
    return error;
  }
};

const seedSectors = async () => {
  const url = `${baseUrl}/sectors`;
  try {
    return await axios.post(url);
  } catch (error) {
    logError(error);
    return error;
  }
};

const seedF13SectorCoverage = async () => {
  const url = `${baseUrl}/f13-sector-coverage`;
  try {
    return await axios.post(url);
  } catch (error) {
    logError(error);
    return error;
  }
};

const seedF13CompanyCoverage = async () => {
  const url = `${baseUrl}/f13-company-coverage`;
  try {
    return await axios.post(url);
  } catch (error) {
    logError(error);
    return error;
  }
};

const seedF13CompanyCoveragePrevious = async () => {
  const url = `${baseUrl}/f13-company-coverage-previous`;
  try {
    return await axios.post(url);
  } catch (error) {
    logError(error);
    return error;
  }
};

const seedContacts = async () => {
  const url = `${baseUrl}/contacts`;
  try {
    return await axios.post(url);
  } catch (error) {
    logError(error);
    return error;
  }
};

const seedAllDb = async () => {
  const url = `${baseUrl}/all`;
  try {
    return await axios.post(url);
  } catch (error) {
    logError(error);
    return error;
  }
};

export const seedApi = {
  seedCompanies,
  resetDbData,
  seedSectors,
  seedF13SectorCoverage,
  seedF13CompanyCoverage,
  seedContacts,
  seedAllDb,
  seedF13CompanyCoveragePrevious,
};

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VCard',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.loadMoreCompanies),expression:"loadMoreCompanies"}],staticClass:"buyer-stats",attrs:{"color":"grey lighten-5","min-height":"390","height":"390","infinite-scroll-disabled":"infiniteScrollDisabled","flat":""}},[_c('div',{staticClass:"buyer-stats__table-wrapper"},[_c('VDataTable',{staticClass:"buyer-stats__table",attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":_vm.totalItems,"hide-default-header":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"buyer-stats__top"},[_c('div',{staticClass:"buyer-stats__top-title subheader-text ml-3 mt-3"},[_vm._v("Buyer Stats")])])]},proxy:true},{key:"header",fn:function(ref){
var props = ref.props;
return [_c('thead',{staticClass:"buyer-stats__head"},[_c('tr',{staticClass:"buyer-stats__head-row"},_vm._l((props.headers),function(header){return _c('th',{key:header.value,staticClass:"buyer-stats__head-column cell body-2 primary--text",class:_vm.calculateTHClass(header.value)},[_c('div',{class:header.value in _vm.anteFields
                    ? 'buyer-stats__head-column-ante mt-6'
                    : 'buyer-stats__head-column-string ml-4 mb-2'},[(header.value in _vm.anteFields)?[_c('VImg',{attrs:{"max-width":"40","src":_vm.anteFields[header.value].img}}),_c('div',{staticClass:"mt-1"},[_vm._v(_vm._s(_vm.anteFields[header.value].label))])]:[_vm._v(" "+_vm._s(header.text)+" ")]],2)])}),0)])]}},{key:"body",fn:function(ref){
                    var items = ref.items;
return [_c('VOverlay',{attrs:{"color":"primary","value":_vm.loading,"absolute":""}}),_c('tbody',{staticClass:"buyer-stats__body"},_vm._l((items),function(item){return _c('tr',{key:item._id,staticClass:"buyer-stats__body-row body-2",class:{ 'buyer-stats__body-row--active': _vm.rowIsActive(item.function) },on:{"click":function($event){return _vm.$router.push(("/contact/" + (item.contact_id)))}}},_vm._l((_vm.bodyColumns(item)),function(ref){
                    var itemKey = ref[0];
                    var itemText = ref[1];
return _c('td',{key:itemKey,staticClass:"buyer-stats__body-column cell",class:_vm.calculateTHClass(itemKey)},[(itemKey in _vm.anteFields)?_c('span',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(_vm.formatPercent(itemText, 0))+" ")]):_c('span',{staticClass:"primary--text ml-4 text-truncate"},[_vm._v(_vm._s(itemText))])])}),0)}),0)]}}])}),_c('div',{staticClass:"buyer-stats__fillers"},[_c('div',{staticClass:"string-cell-filler"}),_c('div',{staticClass:"number-cell-filler"})])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
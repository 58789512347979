<template>
  <VContainer>
    <div class="font-weight-bold primary--text mb-2">
      <div class="page-header pl-3" :class="{ 'header-text pl-0': $vuetify.breakpoint.smAndUp }">
        Scouting Report: Contact
      </div>
      <div class="text-h6 page-subheader">{{ contact.name }}</div>
      <div class="text-h6 page-subheader font-weight-light">{{ contact.company }}</div>
    </div>
    <VRow dense>
      <VCol class="key-stats d-flex flex-grow-1 flex-shrink-0" :class="getRowWidthClass">
        <KeyStats :contact="contact" />
      </VCol>
      <VCol class="ante-match d-flex flex-grow-1 flex-shrink-0" :class="getRowClass">
        <AnteMatch :contact="contact" />
      </VCol>
      <VCol class="buyer-map d-flex flex-grow-1 flex-shrink-0" :class="getRowWidthClass">
        <BuyerMap
          :contact="contact"
          :stages="stages"
          :stageNames="STAGES"
          @setPositions="setPositions"
        />
      </VCol>
    </VRow>
    <Notes class="mt-2" :contact="contact" />
    <div class="overflow-x-auto d-none">
      <SimilaritiesScoreTable :activePositions="activePositions" />
    </div>
  </VContainer>
</template>

<script>
import { computed, defineComponent, onMounted, ref } from '@vue/composition-api';
import KeyStats from './molecules/KeyStats';
import AnteMatch from './molecules/AnteMatch';
import BuyerMap from './molecules/BuyerMap';
import Notes from './molecules/Notes/Notes';
import SimilaritiesScoreTable from './molecules/SimilaritiesScoreTable';
import { contactsApi } from '../../api/contacts';

const STAGES = {
  STAGE1: 'STAGE1',
  STAGE2: 'STAGE2',
  STAGE3: 'STAGE3',
};

const stages = {
  [STAGES.STAGE1]: {
    label: 'Introduction',
    positions: ['Procurement', 'Analyst'],
  },
  [STAGES.STAGE2]: {
    label: 'Qualify & evaluate',
    positions: ['Procurement', 'Data Science', 'Analyst'],
  },
  [STAGES.STAGE3]: {
    label: 'Close',
    positions: ['Procurement', 'Portfolio Manager', 'Analyst'],
  },
};
export default defineComponent({
  components: {
    KeyStats,
    AnteMatch,
    BuyerMap,
    Notes,
    SimilaritiesScoreTable,
  },
  setup(_, { root }) {
    const contact = ref({});
    const activePositions = ref([]);

    onMounted(async () => {
      const { data } = await contactsApi.fetchContact(root.$route.params.id);
      contact.value = data;
    });

    const getRowClass = computed(() => {
      const windowWidth = root.$vuetify.breakpoint.width;
      if (windowWidth < 1600) return 'row-100 order-3';
      return '';
    });

    const getRowWidthClass = computed(() => {
      const windowWidth = root.$vuetify.breakpoint.width;
      if (windowWidth < 1100) return 'row-100';
      return '';
    });

    const setPositions = (positions) => {
      activePositions.value = positions;
    };

    return {
      getRowClass,
      activePositions,
      stages,
      STAGES,
      setPositions,
      getRowWidthClass,
      contact,
    };
  },
});
</script>

<style scoped lang="scss">
.row-100 {
  flex: 0 0 auto;
}
</style>

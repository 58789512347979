import axios from 'axios';
import { logError } from '../utils/logger';

const baseUrl = `/api/v1/f13`;

const fetchF13CompanyCoverage = async (companyName, searchParams) => {
  const url = `${baseUrl}/company`;
  try {
    return await axios.get(url, { params: { ...searchParams, companyName } });
  } catch (error) {
    logError(error);
    return error;
  }
};

const fetchF13SectorCoverage = async (companyName, searchParams) => {
  const url = `${baseUrl}/sector`;
  try {
    return await axios.get(url, { params: { ...searchParams, companyName } });
  } catch (error) {
    logError(error);
    return error;
  }
};

const updateF13Data = async () => {
  const url = `${baseUrl}/update`;
  try {
    return await axios.post(url);
  } catch (error) {
    logError(error);
    return error;
  }
};

export const f13Api = { fetchF13CompanyCoverage, fetchF13SectorCoverage, updateF13Data };

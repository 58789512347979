<template>
  <VDataTable
    class="company-coverage"
    :headers="headers"
    :items="items"
    :items-per-page="totalItems"
    hide-default-header
    hide-default-footer
  >
    <template #top>
      <div class="company-coverage__top mb-6 pl-5 pt-3">
        <span class="company-coverage__top-title subheader-text"> Ownership (Companies) </span>
        <VCheckbox
          v-model="isOverlap"
          class="ma-0 ml-3"
          color="additional lighten-1"
          label="Overlap"
          hide-details
        />
      </div>
    </template>
    <template #header="{ props }">
      <thead class="company-coverage__head">
        <tr class="company-coverage__head-row">
          <th
            v-for="header in props.headers"
            :class="calculateTHClass(header.value)"
            class="company-coverage__head-column cell body-2 primary--text header-border"
            :key="header.value"
          >
            <span v-if="header.value === 'value'">{{ header.text }}</span>
            <span v-else-if="header.value === 'deltaValue'">{{ header.text }}</span>
            <span v-else-if="header.value === 'percentageOfPortfolio'">{{ header.text }}</span>
            <span v-else class="ml-2">{{ header.text }}</span>
          </th>
        </tr>
      </thead>
    </template>
    <template #body="{ items }">
      <VOverlay color="primary" :value="loading" absolute />
      <tbody
        v-infinite-scroll="loadMoreCompanies"
        infinite-scroll-disabled="infiniteScrollDisabled"
        infinite-scroll-immediate-check="false"
        class="company-coverage__body"
      >
        <tr v-for="item in items" :key="item._id" class="company-coverage__body-row body-2">
          <td class="company-coverage__body-column cell ticker-cell" align="center">
            <span class="primary--text font-weight-bold ml-2"> {{ item.ticker_cusip }} </span>
          </td>
          <td class="company-coverage__body-column cell name-cell text-truncate" align="left">
            <span class="ml-2"> {{ item.name_of_issuer }} </span>
          </td>
          <td class="company-coverage__body-column cell percentage-portfolio-cell" align="center">
            <span> {{ formatPercent(item.perc_of_portfolio) }} </span>
          </td>
          <td class="company-coverage__body-column cell value-cell" align="right">
            <span> {{ formatCurrency(item.value, 0) }} </span>
          </td>
          <td class="company-coverage__body-column cell delta-value-cell d-flex justify-end pr-12">
            <span :style="`color: ${calculateDeltaAppearance(item.abs_delta_value).color}`">
              {{
                item.abs_delta_value > 0
                  ? `+${formatCurrency(item.abs_delta_value, 0)}`
                  : formatCurrency(item.abs_delta_value, 0)
              }}
            </span>
            <VIcon
              :color="calculateDeltaAppearance(item.abs_delta_value).color"
              :class="item.abs_delta_value <= 0 && 'mb-1'"
              class="ml-1"
              x-small
            >
              {{
                `${calculateDeltaAppearance(item.abs_delta_value).icon} ${
                  item.abs_delta_value > 0 ? '' : 'mdi-rotate-180'
                }`
              }}
            </VIcon>
          </td>
        </tr>
      </tbody>
    </template>
  </VDataTable>
</template>

<script>
import { computed, defineComponent, reactive, ref, watch } from '@vue/composition-api';
import { useFormatter } from '../../../uses/useFormatter';
import { logError } from '../../../utils/logger';
import { f13Api } from '../../../api/f13';
import { useStore } from '../../../uses/useStore';
import { StoreModules as Modules } from '../../../store';
import { States } from '../../../store/modules/sectors/types';

export const headers = [
  {
    text: 'Stock Ticker',
    value: 'ticker',
  },
  {
    text: 'Name',
    value: 'name',
  },
  {
    text: 'Percentage of Portfolio',
    value: 'percentageOfPortfolio',
  },
  {
    text: 'Value (USD,$)',
    value: 'value',
  },
  {
    text: 'Value of Change (USD,$)',
    value: 'deltaValue',
  },
];

export default defineComponent({
  setup(_, { root }) {
    const isOverlap = ref(true);
    const items = ref([]);
    const totalItems = ref(null);
    const loading = ref(false);
    const searchParams = reactive({
      offset: 0,
      limit: 20,
      sectors: null,
    });

    const { useState } = useStore(Modules.SECTORS);
    const { selectedSectors } = useState([States.selectedSectors]);
    const { formatPercent, formatCurrency } = useFormatter();

    const infiniteScrollDisabled = computed(() => items.value.length === totalItems.value);

    watch([isOverlap, selectedSectors], ([newOverlap, newSectors]) => {
      items.value = [];
      searchParams.offset = 0;
      searchParams.sectors = newOverlap ? newSectors : null;
      loadCompanies();
    });

    const loadCompanies = async () => {
      try {
        loading.value = true;
        const { data } = await f13Api.fetchF13CompanyCoverage(root.$route.params.id, searchParams);
        items.value = items.value.concat(data.resultList);
        totalItems.value = data.totalCount;
      } catch (error) {
        logError(error);
      } finally {
        loading.value = false;
      }
    };

    const loadMoreCompanies = () => {
      searchParams.offset += 20;
      loadCompanies();
    };

    const calculateTHClass = (columnName) => {
      if (columnName === 'ticker') {
        return 'ticker-cell';
      } else if (columnName === 'name') {
        return 'name-cell';
      } else if (columnName === 'percentageOfPortfolio') {
        return 'percentage-portfolio-cell';
      } else if (columnName === 'value') {
        return 'value-cell';
      } else if (columnName === 'deltaValue') {
        return 'delta-value-cell';
      }
    };

    const calculateDeltaAppearance = (value) => {
      if (!value) {
        return { color: 'var(--v-grey-darken2)', icon: 'mdi-window-minimize' };
      } else if (value > 0) {
        return { color: 'var(--v-success-base)', icon: 'mdi-triangle' };
      }
      return { color: 'var(--v-error-darken1)', icon: 'mdi-triangle' };
    };

    return {
      headers,
      calculateTHClass,
      formatPercent,
      formatCurrency,
      calculateDeltaAppearance,
      loadMoreCompanies,
      items,
      totalItems,
      loading,
      isOverlap,
      infiniteScrollDisabled,
    };
  },
});
</script>

<style scoped lang="scss">
.company-coverage {
  background-color: var(--v-grey-lighten5);
  position: relative;
  min-width: 705px;

  &__top {
    display: flex;
    align-items: center;

    &-title {
      font-weight: bold;
    }
  }

  &__head {
    display: block;
    width: 100%;
    overflow-y: scroll !important;

    &-row {
      display: table;
      width: 100%;
    }

    &-column {
      font-weight: bold;
      border-bottom: 2px solid darkgrey !important;
    }
  }

  &__body {
    display: block;
    height: 400px;
    overflow-y: auto;
    overflow-x: hidden;

    &-row {
      display: flex;
      width: 100%;
    }

    &-column {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.ticker-cell {
  width: 13%;
  justify-content: flex-start;
}
.name-cell {
  width: 25%;
  justify-content: flex-start;
}
.percentage-portfolio-cell {
  width: 22%;
  text-align: center !important;
}
.value-cell {
  width: 17%;
  justify-content: center;
  text-align: center !important;
}
.delta-value-cell {
  width: 23%;
  text-align: center !important;
}

::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-thumb {
  background: darkgrey;
  border-radius: 10px;
}
::v-deep {
  .v-label {
    font-size: 12px;
  }
  .v-input--selection-controls__input .v-icon {
    color: var(--v-grey-lighten2);
  }
}
</style>

import { useStore } from './useStore';
import { StoreModules as Modules } from '../store';
import { Actions } from '../store/modules/notification/types';

const { useActions } = useStore(Modules.NOTIFICATION);

export function useSnackbar() {
  const showSnackbar = ({ message, variant = 'info', duration = 5000 }) => {
    const { ADD_NOTIFICATION } = useActions([Actions.ADD_NOTIFICATION]);
    ADD_NOTIFICATION({
      variant,
      message,
      duration,
    });
  };
  return {
    showSnackbar,
  };
}

export const States = {
  institute: 'institute',
};

export const Mutations = {
  SET_INSTITUTE: 'SET_INSTITUTE',
};

export const Actions = {
  LOAD_INSTITUTE: 'LOAD_INSTITUTE',
};

import axios from 'axios';
import { logError } from '../utils/logger';

const baseUrl = `/api/v1/users`;

const addUser = async (body) => {
  const url = `${baseUrl}`;
  try {
    return await axios.post(url, body);
  } catch (error) {
    logError(error);
    return error;
  }
};

const fetchUsers = async (params) => {
  const url = `${baseUrl}`;
  try {
    return await axios.get(url, { params });
  } catch (error) {
    logError(error);
    return error;
  }
};

const fetchUserById = async (id) => {
  const url = `${baseUrl}/${id}`;
  return await axios.get(url);
};

const updateUser = async (id, body) => {
  const url = `${baseUrl}/${id}`;
  return await axios.put(url, body);
};

const deleteUser = async (id) => {
  const url = `${baseUrl}/${id}`;
  return await axios.delete(url);
};

const resetPassword = async (body) => {
  const url = `${baseUrl}/reset-password`;
  try {
    return await axios.post(url, body);
  } catch (error) {
    logError(error);
    return error;
  }
};

export const usersApi = {
  addUser,
  fetchUsers,
  fetchUserById,
  updateUser,
  deleteUser,
  resetPassword,
};

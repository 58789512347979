import axios from 'axios';
import { logError } from '../utils/logger';

const baseUrl = `/api/v1/ante`;

const fetchAnte = async (id) => {
  const url = `${baseUrl}/${id}`;
  try {
    return await axios.get(url);
  } catch (error) {
    logError(error);
    return error;
  }
};

const requestUserScore = async (email) => {
  const url = `${baseUrl}/request-user-score`;
  try {
    return await axios.post(url, { email });
  } catch (error) {
    logError(error);
    return error;
  }
};

export const anteApi = { fetchAnte, requestUserScore };

<template>
  <div class="text-center">
    <VDialog
      :value="true"
      width="795"
      height="800"
      @click:outside="$emit('cancel')"
      @keydown.esc="$emit('cancel')"
    >
      <VForm class="form-container" ref="userForm" v-model="formValid">
        <span class="close-icon" @click="$emit('cancel')">
          <VIcon color="currentColor">mdi-close</VIcon>
        </span>

        <div class="pb-3">
          <label class="input-label">Company</label>
          <VCombobox
            :value="institutes.currentItem"
            :items="institutes.items"
            :rules="rules.companyRules"
            :search-input.sync="companySearch"
            item-text="name"
            hide-details="hidden"
            dense
            filled
            required
            clearable
            @change="handleCompanyChange"
          >
            <template v-slot:no-data>
              <VListItem>
                <span class="addCompany" @click="createInstitute">Create {{ companySearch }}</span>
              </VListItem>
            </template>
          </VCombobox>
        </div>
        <VRow class="py-3">
          <VCol :cols="formHalfCols">
            <div>
              <label class="input-label">First Name</label>
              <VTextField
                :rules="rules.requiredRules"
                v-model.trim="user.firstName"
                hide-details="hidden"
                filled
                dense
                required
              />
            </div>
          </VCol>
          <VCol :cols="formHalfCols">
            <div>
              <label class="input-label">Last Name</label>
              <VTextField
                :rules="rules.requiredRules"
                v-model.trim="user.lastName"
                hide-details="hidden"
                filled
                dense
              />
            </div>
          </VCol>
        </VRow>
        <div class="py-3">
          <label class="input-label">Email</label>
          <VTextField
            :rules="rules.emailRules"
            v-model.trim="user.email"
            hide-details="hidden"
            filled
            dense
            clearable
            required
          />
        </div>
        <div>
          <label class="input-label">Date of Ante</label>
          <VMenu
            :close-on-content-click="true"
            v-model="anteDateMenu"
            transition="scale-transition"
            max-width="290px"
            min-width="290px"
            offset-y
          >
            <template #activator="{ on }">
              <VTextField
                :value="formatDate(user.ante.collectionDate)"
                hide-details="auto"
                v-on="on"
                readonly
                clearable
                filled
                dense
                @click:clear="user.ante.collectionDate = null"
              />
            </template>
            <VDatePicker
              v-model="user.ante.collectionDate"
              color="primary"
              no-title
              @input="anteDateMenu = false"
            />
          </VMenu>
        </div>

        <div class="py-3">
          <label class="input-label ante-label"> ANTE Scores</label>
          <VRow>
            <VCol
              v-for="anteName in getAnteNames(user.ante.categories)"
              class="ante-score"
              :key="anteName"
              :cols="anteCols"
            >
              <div class="d-flex justify-space-between pt-1">
                <label class="score-title-label">
                  {{ anteNames[anteName].value }}
                </label>
                <div class="score-title-field-container">
                  <div>
                    <span>{{ user.ante.categories[anteName].totalScore || '##' }}</span>
                  </div>
                </div>
              </div>
              <div>
                <div
                  class="d-flex justify-space-between pt-1"
                  v-for="anteScoreName in getScoreFields(user.ante.categories[anteName])"
                  :key="anteScoreName"
                >
                  <label class="score-label">{{ anteNames[anteName][anteScoreName].value }}</label>
                  <div class="score-field-container">
                    <div>
                      <VTextField
                        class="score-field"
                        :rules="rules.anteScoreRules"
                        :error="anteFieldIsValid(user.ante.categories[anteName][anteScoreName])"
                        v-model.number="user.ante.categories[anteName][anteScoreName].score"
                        @change="
                          changeTotalScore(user.ante.categories[anteName][anteScoreName], anteName)
                        "
                        placeholder="##"
                        hide-details="hidden"
                        type="number"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </VCol>
          </VRow>
        </div>

        <VRow class="py-3">
          <VCol :cols="formHalfCols">
            <div>
              <label class="input-label">Start Date</label>
              <VMenu
                :close-on-content-click="true"
                v-model="accessStartDateMenu"
                transition="scale-transition"
                max-width="290px"
                min-width="290px"
                offset-y
              >
                <template #activator="{ on }">
                  <VTextField
                    :value="formatDate(user.accessStartDate)"
                    :rules="rules.requiredRules"
                    v-on="on"
                    hide-details="hidden"
                    readonly
                    clearable
                    filled
                    dense
                    @click:clear="user.accessStartDate = null"
                  />
                </template>
                <VDatePicker
                  v-model="user.accessStartDate"
                  :max="maxStartDate"
                  color="primary"
                  no-title
                  @input="accessStartDateMenu = false"
                />
              </VMenu>
            </div>
          </VCol>
          <VCol :cols="formHalfCols">
            <div>
              <label class="input-label">End Date</label>
              <VMenu
                v-model="accessEndDateMenu"
                :close-on-content-click="true"
                transition="scale-transition"
                max-width="290px"
                min-width="290px"
                offset-y
              >
                <template #activator="{ on }">
                  <VTextField
                    :value="formatDate(user.accessEndDate)"
                    :rules="rules.requiredRules"
                    v-on="on"
                    hide-details="hidden"
                    readonly
                    clearable
                    filled
                    dense
                    @click:clear="user.accessEndDate = null"
                  />
                </template>
                <VDatePicker
                  v-model="user.accessEndDate"
                  :min="minEndDate"
                  color="primary"
                  no-title
                  @input="accessEndDateMenu = false"
                />
              </VMenu>
            </div>
          </VCol>
        </VRow>
        <div class="mt-3">
          <VBtn
            class="add-btn mr-4"
            :loading="loading"
            color="additional darken-1"
            @click.prevent="submitForm"
          >
            Submit
          </VBtn>
          <VBtn color="grey" text @click.prevent="$emit('cancel')">Cancel</VBtn>
        </div>
      </VForm>
    </VDialog>
  </div>
</template>
<script>
import { computed, onMounted, reactive, ref } from '@vue/composition-api';
import { institutesApi } from '@/api/institutes';
import { usersApi } from '@/api/users';
import { useFormatter } from '@/uses/useFormatter';
import { anteNames } from '../anteName';
import { format } from 'date-fns';
import { enUS } from 'date-fns/locale';

export default {
  emits: ['cancel', 'submit'],
  props: {
    initialUser: { type: Object },
  },
  setup({ initialUser }, { emit, root }) {
    const INITIAL_ITEMS = {
      firstName: '',
      lastName: '',
      institute: '',
      email: '',
      ante: {
        totalScore: null,
        collectionDate: '',
        categories: {
          accuracy: {
            ac01: {
              score: null,
            },
            ac02: {
              score: null,
            },
            ac03: {
              score: null,
            },
            ac04: {
              score: null,
            },
            totalScore: null,
          },
          novelty: {
            nv01: {
              score: null,
            },
            nv02: {
              score: null,
            },
            nv03: {
              score: null,
            },
            nv04: {
              score: null,
            },
            totalScore: null,
          },
          timeliness: {
            tm01: {
              score: null,
            },
            tm02: {
              score: null,
            },
            tm03: {
              score: null,
            },
            tm04: {
              score: null,
            },
            totalScore: null,
          },
          easeOfUse: {
            eu01: {
              score: null,
            },
            eu02: {
              score: null,
            },
            eu03: {
              score: null,
            },
            eu04: {
              score: null,
            },
            totalScore: null,
          },
        },
      },
      accessStartDate: null,
      accessEndDate: null,
    };

    const rules = {
      requiredRules: [(v) => !!v || 'Field is required'],
      companyRules: [(v) => !!v, (v) => !!v?.id],
      anteScoreRules: [(v) => (typeof v !== 'number' && !v) || (v >= 1 && v <= 10)],
      emailRules: [(v) => !!v, (v) => /.+@.+\..+/.test(v)],
    };

    const formValid = ref(false);
    const userForm = ref(null);

    const { formatDate } = useFormatter();

    const anteDateMenu = ref(false);
    const accessStartDateMenu = ref(false);
    const accessEndDateMenu = ref(false);

    const companySearch = ref(null);
    const user = ref(INITIAL_ITEMS);

    const institutes = reactive({
      items: [],
      currentItem: null,
    });

    const loading = ref(false);

    onMounted(async () => {
      if (initialUser?._id) {
        const { data: fetchedUserRes } = await usersApi.fetchUserById(initialUser._id);
        const fetchedUser = fetchedUserRes.data;
        user.value = { ...fetchedUser, institute: fetchedUser.institute?.id };
        institutes.currentItem = fetchedUser?.institute;
      }
      const institutesData = await fetchInstitutes();
      institutes.items = institutesData;
      loading.value = false;
    });

    const formHalfCols = computed(() => {
      const windowWidth = root.$vuetify.breakpoint.width;
      if (windowWidth < 720) return 12;
      return 6;
    });
    0;
    const anteCols = computed(() => {
      const windowWidth = root.$vuetify.breakpoint.width;
      if (windowWidth < 720) return 6;
      return 3;
    });

    const checkAnteScoresValid = computed(() => {
      const anteNames = Object.keys(user.value.ante.categories);
      const scoreFields = anteNames.map((anteName) =>
        getScoreFields(user.value.ante.categories[anteName])
      );

      const scores = scoreFields
        .map((field, i) =>
          field.map((score) => user.value.ante.categories[anteNames[i]][score].score)
        )
        .flat();

      const valuesAreNull = scores.every((score) => score === null);
      const valuesAreNumber = scores.every((score) => typeof score === 'number');

      return !valuesAreNull && !valuesAreNumber;
    });

    const minEndDate = computed(() => {
      if (!user.value.accessStartDate) return null;
      return addDays(new Date(user.value.accessStartDate), 1);
    });

    const maxStartDate = computed(() => {
      if (!user.value.accessEndDate) return null;
      return addDays(new Date(user.value.accessEndDate), -1);
    });

    const addDays = (date, days) => {
      const nonFormatDate = new Date(date.setDate(date.getDate() + days));
      const formatDate = format(nonFormatDate, 'yyyy-MM-dd', { locale: enUS });
      return formatDate;
    };

    const getScoreFields = (anteNames) => {
      return Object.keys(anteNames).filter((el) => el !== 'totalScore');
    };

    const getAnteNames = (antes) => {
      return Object.keys(antes).filter((el) => el !== 'collectionDate' && el !== 'totalScore');
    };

    const changeAnteTotal = () => {
      const scoreFields = Object.keys(user.value.ante.categories);
      const scores = scoreFields.map((field) => user.value.ante.categories[field].totalScore);

      if (scores.some((score) => typeof score !== 'number')) {
        user.value.ante.totalScore = null;
        return;
      }

      const total = scores.reduce((a, b) => a + b);
      user.value.ante.totalScore = total;
    };

    const changeTotalScore = (anteValue, anteName) => {
      const anteScore = anteValue.score;
      if (typeof anteScore !== 'number') {
        anteValue.score = null;
      }

      const scoreFields = getScoreFields(user.value.ante.categories[anteName]);
      const scores = scoreFields.map((field) => user.value.ante.categories[anteName][field].score);

      if (scores.some((score) => typeof score !== 'number')) {
        user.value.ante.categories[anteName].totalScore = null;
        user.value.ante.totalScore = null;

        return;
      }

      const total = scores.reduce((a, b) => a + b);
      const average = total / scoreFields.length;
      const rounded = Math.round(average);

      user.value.ante.categories[anteName].totalScore = rounded;
      changeAnteTotal();
    };

    const anteFieldIsValid = (ante) => {
      return !ante.score && checkAnteScoresValid.value;
    };

    const submitForm = () => {
      userForm.value.validate();
      if (!formValid.value) return;
      emit('submit', { user, loading });
    };

    const handleCompanyChange = (institute) => {
      user.value.institute = institute?.id;
      institutes.currentItem = institute;
    };

    const fetchInstitutes = async () => {
      const { data: institutesRes } = await institutesApi.fetchAllInstitutes();
      const institutesData = institutesRes.data;
      return institutesData;
    };

    const createInstitute = async () => {
      const name = companySearch.value.trim();
      const { data: instituteRes } = await institutesApi.addInstitute({
        name,
      });

      const instituteData = instituteRes.data;

      institutes.items = [...institutes.items, instituteData];
      institutes.currentItem = instituteData;
      user.value.institute = instituteData?.id;
    };

    return {
      loading,
      user,
      institutes,
      formatDate,
      anteDateMenu,
      accessStartDateMenu,
      accessEndDateMenu,
      getScoreFields,
      anteNames,
      userForm,
      submitForm,
      rules,
      formValid,
      companySearch,
      handleCompanyChange,
      createInstitute,
      getAnteNames,
      minEndDate,
      maxStartDate,
      changeTotalScore,
      anteFieldIsValid,
      formHalfCols,
      anteCols,
    };
  },
};
</script>
<style scoped lang="scss">
.ante-score {
  padding: 0 6px;
}

.addCompany {
  cursor: pointer;
}

.add-btn {
  color: #fff !important;
}

.input-label.ante-label {
  color: var(--v-additional-darken1);
}

.close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;

  &:hover i {
    color: var(--v-error-base);
  }
}

.score-title-label {
  font-weight: bold;
}

.score-label {
  font-size: 12px;
  color: var(--v-grey-darken1);
}

.score-title-field-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 25px;
  background-color: var(--v-grey-lighten4);
  color: var(--v-grey-darken1);
  font-weight: bold;
  font-size: 14px;

  span {
    color: black;
    margin-left: 1px;
  }
}

.score-field-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 20px;
  background-color: var(--v-grey-lighten4);
  font-size: 10px;
  color: var(--v-grey-darken1);

  div {
    width: 16px;
  }

  span {
    margin-left: 1px;
  }
}

.score-title-field {
  text-align: right;
  width: 24px;
  height: 20px;
  font-size: 14px;

  &::placeholder {
    color: black;
  }
}

.score-field {
  height: 20px;
  outline: none;
  border: none;
  color: var(--v-grey-darken1);
  font-size: inherit;

  &::placeholder {
    color: var(--v-grey-darken1);
  }
}

.score-field-error {
  border: 1px solid var(--v-error-base);
}

.form-container {
  position: relative;
  background-color: #fff;
  padding: 23px 18px;
  width: 100%;
}

.input-label {
  display: block;
  color: var(--v-primary-base);
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 10px;
}

::v-deep .score-field-container .v-input__slot {
  position: initial !important;
}

::v-deep .score-title-field-container .v-input__slot {
  position: initial !important;
}

::v-deep
  .score-field-container
  .v-text-field
  > .v-input__control
  > .v-input__slot
  > .v-text-field__slot {
  position: initial !important;
}

:v-deep
  .score-title-field-container
  .v-text-field
  > .v-input__control
  > .v-input__slot
  > .v-text-field__slot {
  position: initial !important;
}

::v-deep .score-field-container .v-text-field {
  padding: 0;
  margin: 0;

  input {
    padding: 0 !important;
    color: var(--v-grey-darken1);
    text-align: center;
  }
}

::v-deep .score-title-field-container .v-text-field {
  padding: 0;
  margin: 0;

  input {
    padding: 0 !important;
    color: black;
    text-align: right;

    &::placeholder {
      color: black;
    }
  }
}

/* Remove input['number'] arrows in browsers */

/* Chrome, Safari, Edge, Opera */
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
:v-deep input[type='number'] {
  -moz-appearance: textfield;
}
</style>

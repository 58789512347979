import axios from 'axios';
import { logError } from '../utils/logger';

const baseUrl = `/api/v1/auth`;

const login = async ({ email, password }) => {
  const url = `${baseUrl}/login`;
  try {
    return await axios.post(url, {
      email,
      password,
    });
  } catch (error) {
    logError(error);
    return error;
  }
};

const fetchCurrentUser = async () => {
  const url = `${baseUrl}/me`;
  try {
    return await axios.get(url);
  } catch (error) {
    logError(error);
    return error;
  }
};

const logout = async () => {
  const url = `${baseUrl}/logout`;
  try {
    return await axios.get(url);
  } catch (error) {
    logError(error);
    return error;
  }
};

export const authApi = { login, fetchCurrentUser, logout };

<template>
  <VContainer class="layout" fluid :class="{ 'px-4': $vuetify.breakpoint.smAndDown }">
    <VAppBar class="primary" height="80" app>
      <div
        class="layout__app-bar d-flex justify-space-between"
        :class="{ 'px-12': $vuetify.breakpoint.mdAndUp }"
      >
        <template v-if="$vuetify.breakpoint.smAndDown">
          <VAppBarNavIcon color="#fff" @click="drawer = true" />
          <div :class="{ 'mr-8': $vuetify.breakpoint.mdAndUp }">
            <VAvatar
              v-if="user"
              class="white--text mr-3 text-h5 font-weight-bold layout__avatar"
              color="additional darken-2"
              size="48"
              @click="logout"
            >
              {{ getInitials(user.name) }}
            </VAvatar>
          </div>
        </template>

        <VNavigationDrawer
          v-if="$vuetify.breakpoint.smAndDown"
          v-model="drawer"
          absolute
          app
          color="primary"
        >
          <template>
            <div class="layout__app-bar-image-container">
              <div class="d-flex pt-6 pl-4">
                <VImg
                  class="layout__app-bar-image"
                  :src="require('@/assets/logo.png')"
                  max-width="180"
                  height="45"
                  @click="navigateHome"
                />
              </div>
              <LayoutTabs vertical class="flex-1" />
            </div>
          </template>
        </VNavigationDrawer>

        <template v-if="$vuetify.breakpoint.mdAndUp">
          <div class="d-flex">
            <div class="pl-12">
              <VImg
                class="layout__app-bar-image"
                :src="require('@/assets/logo.png')"
                max-width="180"
                height="45"
                @click="navigateHome"
              />
            </div>
            <div class="ml-12">
              <LayoutTabs />
            </div>
          </div>
          <div class="mr-8">
            <VAvatar
              v-if="user"
              class="white--text mr-3 text-h5 font-weight-bold layout__avatar"
              color="additional darken-2"
              size="48"
              @click="logout"
            >
              {{ getInitials(user.name) }}
            </VAvatar>
          </div>
        </template>
      </div>
    </VAppBar>
    <VMain class="main-container">
      <RouterView />
    </VMain>
  </VContainer>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api';
import { Actions, States } from '@/store/modules/user/types';
import { useStore } from '@/uses/useStore';
import { StoreModules as Modules } from '@/store';
import LayoutTabs from './molecules/LayoutTabs.vue';
import { HOMEPAGES } from '../../router';
import { useUserInfo } from '../../uses/useUserInfo';

export default defineComponent({
  components: { LayoutTabs },
  setup(_, { root }) {
    const { useState, useActions } = useStore(Modules.USER);
    const { user } = useState([States.user]);
    const { LOGOUT } = useActions([Actions.LOGOUT]);

    const drawer = ref(false);
    const { isUser, isAdmin } = useUserInfo();

    const homepageRoutes = Object.values(HOMEPAGES);

    const logout = () => {
      LOGOUT();
    };

    const getInitials = (name) => {
      const splittedName = name.split(' ');
      return `${splittedName[0][0].toUpperCase()}${splittedName[1][0].toUpperCase()}`;
    };

    const navigateHome = async () => {
      if (homepageRoutes.includes(root.$router.currentRoute.path)) return;
      await root.$router.push('/');
    };

    return {
      logout,
      user,
      getInitials,
      navigateHome,
      drawer,
      isAdmin,
      isUser,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.main-container {
  z-index: $z-index-neutral;
}

.layout {
  &__app-bar {
    width: 100%;

    &-image {
      cursor: pointer;
    }

    &-image-container {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }

  &__avatar {
    cursor: pointer;
  }
}
.container {
  max-width: 4096px;
  padding: 20px 50px;
}

@media screen and (max-width: 720px) {
  .container {
    padding: 20px 0;
  }
}
</style>

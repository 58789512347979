import '../src/plugins/installCompositionApi';
import axios from 'axios';
import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import HighchartsVue from 'highcharts-vue';
import store, { StoreModules as Modules } from './store';
import router from './router';
import { useSnackbar } from './uses/useSnackbar';
import { useStore } from './uses/useStore';
import { Actions } from './store/modules/user/types';
import infiniteScroll from 'vue-infinite-scroll';

const { showSnackbar } = useSnackbar();
const { useActions } = useStore(Modules.USER);
const { LOGOUT } = useActions([Actions.LOGOUT]);

axios.interceptors.response.use(
  (response) => {
    if (response?.data?.passwordReset) {
      showSnackbar({
        message: 'New password was sent to your email',
        variant: 'success',
      });
    }
    return response;
  },
  (error) => {
    const message = error.response?.data?.message;
    showSnackbar({
      message,
      variant: 'error',
    });
    switch (error.response?.status) {
      case 401:
        LOGOUT();
        break;
      case 403: {
        LOGOUT();
        break;
      }
      default:
        break;
    }
    return Promise.reject(error);
  }
);

axios.interceptors.request.use(function (config) {
  config.headers.Authorization = JSON.parse(localStorage.getItem('token'));

  return config;
});

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
axios.defaults.timeout = 100000;

Vue.use(infiniteScroll);
Vue.use(HighchartsVue, { tagName: 'Highchart' });

new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
}).$mount('#app');

<template>
  <VContainer v-if="score">
    <div class="font-weight-bold primary--text">
      <div class="page-header pl-3" :class="{ 'header-text pl-0': $vuetify.breakpoint.smAndUp }">
        Your Ante Score
      </div>
      <div class="text-h6 page-subheader">Tagline Placed Here</div>
    </div>
    <section>
      <VRow>
        <VCol v-for="(ante, anteKey) in score.categories" :key="anteKey" class="px-0" :cols="cols">
          <VCard class="score mt-5 pt-5 mr-2" height="640" color="grey lighten-5" flat>
            <VCardText>
              <div class="d-flex justify-center align-center mb-10 mt-4">
                <VImg max-width="60" :src="anteFields[anteKey].img" class="mr-1" />
                <div class="score__title text-h5 ml-2">
                  {{ anteFields[anteKey].label }}
                </div>
              </div>
              <VList color="grey lighten-5">
                <VListItem v-for="[anteScoreKey] in getAnteScores(ante)" :key="anteScoreKey">
                  <VListItemContent>
                    <VListItemTitle class="score__content-title body-2">
                      <span>{{ anteFields[anteKey][anteScoreKey].label }}</span>
                      <span>{{ score.categories[anteKey][anteScoreKey].score }}/10</span>
                    </VListItemTitle>
                    <VListItemSubtitle class="caption grey--text text--darken-3">
                      {{ anteFields[anteKey][anteScoreKey].text }}
                    </VListItemSubtitle>
                    <VProgressLinear
                      :value="score.categories[anteKey][anteScoreKey].score * 10"
                      class="my-3"
                      color="secondary"
                      background-color="secondary lighten-4"
                      height="23"
                    />
                  </VListItemContent>
                </VListItem>
              </VList>
            </VCardText>
          </VCard>
        </VCol>
      </VRow>
    </section>
    <VDialog :value="showScoreMyDataDialog" width="800" no-click-animation hide-overlay persistent>
      <VOverlay
        :value="showScoreMyDataDialog"
        class="score__overlay"
        color="white"
        opacity="0.7"
        absolute
      >
      </VOverlay>
      <VCard class="score__dialog" elevation="0">
        <VCardText class="score__dialog-text black--text" :class="getTextClass">
          {{ dialogText }}
        </VCardText>
        <VCardActions class="d-flex justify-center">
          <VBtn
            class="text-capitalize white--text"
            :loading="loading"
            :disabled="loading || requestedAnte"
            color="secondary darken-1"
            @click="requestScore"
          >
            Score My Data
          </VBtn>
        </VCardActions>
      </VCard>
    </VDialog>
  </VContainer>
</template>

<script>
import { defineComponent, watch, ref, computed } from '@vue/composition-api';
import { useUserInfo } from '../../uses/useUserInfo';
import { anteApi } from '../../api/ante';
import { anteFields } from './anteFields';
import { Actions } from '../../store/modules/user/types';
import { useStore } from '../../uses/useStore';
import { StoreModules as Modules } from '../../store';

export default defineComponent({
  setup(_, { root }) {
    const showScoreMyDataDialog = ref(false);
    const score = ref(null);
    const loading = ref(false);
    const { user } = useUserInfo();
    const requestedAnte = ref(null);
    const { useActions } = useStore(Modules.USER);
    const { GET_CURRENT_USER } = useActions([Actions.GET_CURRENT_USER]);

    watch(
      user,
      async (value) => {
        if (value) {
          score.value = value.ante;
          showScoreMyDataDialog.value = !value.ante.totalScore;
          requestedAnte.value = user.value.requestedAnte;
        }
      },
      { immediate: true }
    );

    const cols = computed(() => {
      const windowWidth = root.$vuetify.breakpoint.width;
      if (windowWidth < 800) return 12;
      if (windowWidth < 1600) return 6;
      return 3;
    });

    const requestScore = async () => {
      try {
        loading.value = true;
        await anteApi.requestUserScore(user.value.email);
        showScoreMyDataDialog.value = false;
        await GET_CURRENT_USER();
      } finally {
        loading.value = false;
      }
    };

    const getAnteScores = (ante) => {
      const fields = Object.entries(ante).filter(([fieldKey]) => fieldKey !== 'totalScore');
      return fields;
    };

    const dialogText = computed(() => {
      if (requestedAnte.value) return 'We will be getting back to you on this soon';
      return `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
          ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur
          sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
          est laborum.`;
    });

    const getTextClass = computed(() => {
      if (requestedAnte.value) return 'text-center';
      return '';
    });

    return {
      score,
      showScoreMyDataDialog,
      requestScore,
      loading,
      cols,
      anteFields,
      getAnteScores,
      dialogText,
      requestedAnte,
      getTextClass,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/styles/variables.scss';

.text-center {
  text-align: center;
}

.score {
  &__title {
    color: var(--v-primary-base);

    &::first-letter {
      font-weight: bold;
    }
  }

  &__content-title {
    display: flex;
    justify-content: space-between;
    color: var(--v-secondary-base);
    font-weight: bold;
  }

  &__overlay {
    z-index: $z-index-modal;
    top: 80px !important;
  }

  &__dialog {
    background-color: transparent;
    z-index: $z-index-modal;
  }

  &__dialog-text {
    font-size: 18px;
  }
}

::v-deep {
  .v-dialog {
    box-shadow: none !important;
  }
  &.v-dialog__content {
    z-index: $z-index-neutral !important;
  }
}
</style>

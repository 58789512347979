<template>
  <VContainer>
    <div class="font-weight-bold primary--text">
      <div class="page-header pl-3" :class="{ 'header-text pl-0': $vuetify.breakpoint.smAndUp }">
        Scouting Report
      </div>
      <div class="text-h6 page-subheader">Search Companies</div>
    </div>
    <TopPanel />
    <CompanyTable />
  </VContainer>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import CompanyTable from './molecules/CompanyTable.vue';
import TopPanel from '../../components/TopPanel';

export default defineComponent({
  components: {
    CompanyTable,
    TopPanel,
  },
  setup() {
    return {
      //
    };
  },
});
</script>

<style scoped lang="scss"></style>

<template>
  <VContainer fill-height fluid>
    <VAppBar height="150" class="primary" app>
      <VRow class="px-12">
        <VCol>
          <VImg max-width="200" height="50" :src="require('../assets/logo.png')" />
        </VCol>
        <VCol align="right">
          <VBtn class="rounded-0 mt-2 text-capitalize white--text pa-6" color="additional darken-1"
            >Contact Us</VBtn
          >
        </VCol>
      </VRow>
    </VAppBar>
    <div class="forgot-password-container">
      <VCard class="forgot-password pa-8 mr-0" width="550" flat>
        <VCardTitle class="text-h4 font-weight-bold primary--text">Forgot Password</VCardTitle>
        <VCardSubtitle class="forgot-password__subtitle body-1">
          Please enter your email and we'll send you a new password
        </VCardSubtitle>
        <VCardText>
          <VForm @submit.prevent="onFormSubmit">
            <p class="text-h5 font-weight-bold primary--text">Email</p>
            <VTextField v-model="email" class="rounded-0" placeholder="Email" outlined />
            <VRow class="mb-2">
              <VCol cols="6">
                <VBtn
                  class="forgot-password__button rounded-0 text-capitalize white--text"
                  :disabled="loading"
                  :loading="loading"
                  color="additional darken-1"
                  type="submit"
                >
                  Reset password
                </VBtn>
              </VCol>
            </VRow>
          </VForm>
        </VCardText>
      </VCard>
    </div>
  </VContainer>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api';
import { generatePassword } from '@/utils/generatePassword';
import { usersApi } from '../api/users';
import router from '../router';

export default defineComponent({
  setup() {
    const loading = ref(false);
    const email = ref('');

    const onFormSubmit = async () => {
      loading.value = true;
      const newPassword = generatePassword();
      const { data } = await usersApi.resetPassword({ email: email.value, newPassword });
      loading.value = false;
      if (data?.success) {
        await router.push('/login');
      }
    };

    return {
      email,
      onFormSubmit,
      loading,
    };
  },
});
</script>

<style scoped lang="scss">
.forgot-password-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.forgot-password {
  &__subtitle {
    color: var(--v-grey-base);
  }
  &__button {
    width: 100%;
  }
}
</style>

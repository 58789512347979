<template>
  <div class="d-flex my-3 ml-3">
    <section class="body-2">
      <div>{{ formatDate(message.publicationDate) }}</div>
      <div class="d-flex justify-end">{{ formatDateHour(message.publicationDate) }}</div>
    </section>
    <section class="message-text body-2 ml-4">
      <div class="font-weight-bold mb-2">{{ message.publisher }}</div>
      <div>{{ message.content }}</div>
    </section>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import { useFormatter } from '../../../../../uses/useFormatter';

export default defineComponent({
  props: {
    message: {
      type: Object,
    },
  },
  setup() {
    const { formatDate, formatDateHour } = useFormatter();

    return {
      formatDate,
      formatDateHour,
    };
  },
});
</script>

<style scoped lang="scss">
.message-text {
  word-break: break-word;
}
</style>

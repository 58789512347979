import axios from 'axios';
import { logError } from '../utils/logger';

const baseUrl = `/api/v1/sectors`;

const fetchSectors = async () => {
  try {
    return await axios.get(baseUrl);
  } catch (error) {
    logError(error);
    return error;
  }
};

export const sectorsApi = { fetchSectors };

<template>
  <VCard class="pa-3 flex-grow-1" color="grey lighten-5" flat>
    <div class="subheader-text font-weight-bold mb-3 d-flex">
      <span class="mr-3">Key Stats</span>
      <!--      <div class="d-flex align-center">-->
      <!--        <VChip class="rounded-sm" color="secondary lighten-2" text-color="white" small pill>-->
      <!--          Gatekeeper-->
      <!--        </VChip>-->
      <!--        <VChip class="ml-2 rounded-sm" color="primary lighten-2" text-color="white" small>-->
      <!--          First Touch-->
      <!--        </VChip>-->
      <!--      </div>-->
    </div>
    <div v-if="contact.title" class="body-2 mb-1">
      <span class="font-weight-bold">Title:</span>
      {{ contact.title }}
    </div>
    <div v-if="contact.function" class="body-2 mb-1">
      <span class="font-weight-bold">Function:</span>
      {{ contact.function }}
    </div>
    <div v-if="contact.email" class="body-2 mb-1">
      <span class="font-weight-bold">Email:</span> {{ contact.email }}
    </div>
    <div v-if="contact.telephone" class="body-2 mb-5">
      <span class="font-weight-bold">Phone: </span>{{ contact.telephone }}
    </div>
    <div v-if="contact.out_take" class="body-2">
      <span class="font-weight-bold">Our Take:</span> {{ contact.out_take }}
    </div>
  </VCard>
</template>

<script>
export default {
  props: {
    contact: { type: Object },
  },
  setup() {
    //
  },
};
</script>

<template>
  <VCard class="ante-panel">
    <VRow class="ante-panel__ante">
      <VCol class="ante-panel__ante-cell caption">
        <div class="ante-panel__ante-cell-title ml-3">
          <div
            class="
              ante-panel__ante-cell-title ante-panel__ante-cell-title-percent
              subheader-text
              font-weight-bold
              mb-1
            "
          >
            {{ ante.accuracyOverlapScore }}%
          </div>
          <div class="ante-panel__ante-cell-title-label primary--text">Accuracy</div>
        </div>
        <div class="ante-panel__ante-cell-values">
          <div class="d-flex justify-space-between">
            <div class="grey--text">Applicability</div>
            <div class="ante-panel__ante-cell-values-percent ml-9 font-weight-medium">
              {{ ante.ante.categories.accuracy.ac01.score }}
            </div>
          </div>
          <div class="d-flex justify-space-between">
            <div class="grey--text">KPI Predictive</div>
            <div class="ante-panel__ante-cell-values-percent font-weight-medium">
              {{ ante.ante.categories.accuracy.ac02.score }}
            </div>
          </div>
          <div class="d-flex justify-space-between">
            <div class="grey--text">Market Predictive</div>
            <div class="ante-panel__ante-cell-values-percent font-weight-medium">
              {{ ante.ante.categories.accuracy.ac03.score }}
            </div>
          </div>
          <div class="d-flex justify-space-between">
            <div class="grey--text">Depth</div>
            <div class="ante-panel__ante-cell-values-percent font-weight-medium">
              {{ ante.ante.categories.accuracy.ac04.score }}
            </div>
          </div>
        </div>
      </VCol>
      <VCol class="ante-panel__ante-cell caption">
        <div class="ante-panel__ante-cell-title ml-3">
          <div class="ante-panel__ante-cell-title-percent subheader-text font-weight-bold mb-1">
            {{ ante.noveltyOverlapScore }}%
          </div>
          <div class="ante-panel__ante-cell-title-label primary--text">Novelty</div>
        </div>
        <div class="ante-panel__ante-cell-values">
          <div class="d-flex justify-space-between">
            <div class="grey--text">Competition</div>
            <div class="ante-panel__ante-cell-values-percent ml-8 font-weight-medium">
              {{ ante.ante.categories.novelty.nv01.score }}
            </div>
          </div>
          <div class="d-flex justify-space-between">
            <div class="grey--text">Moat</div>
            <div class="ante-panel__ante-cell-values-percent font-weight-medium">
              {{ ante.ante.categories.novelty.nv02.score }}
            </div>
          </div>
          <div class="d-flex justify-space-between">
            <div class="grey--text">Innovation</div>
            <div class="ante-panel__ante-cell-values-percent font-weight-medium">
              {{ ante.ante.categories.novelty.nv03.score }}
            </div>
          </div>
          <div class="d-flex justify-space-between">
            <div class="grey--text">Breadth</div>
            <div class="ante-panel__ante-cell-values-percent font-weight-medium">
              {{ ante.ante.categories.novelty.nv04.score }}
            </div>
          </div>
        </div>
      </VCol>
    </VRow>
    <VRow class="ante-panel__ante">
      <VCol class="ante-panel__ante-cell caption pt-3">
        <div class="ante-panel__ante-cell-title ml-3">
          <div class="ante-panel__ante-cell-title-percent subheader-text font-weight-bold mb-1">
            {{ ante.timelinessOverlapScore }}%
          </div>
          <div class="ante-panel__ante-cell-title-label primary--text">Timeliness</div>
        </div>
        <div class="ante-panel__ante-cell-values">
          <div class="d-flex justify-space-between">
            <div class="grey--text mr-4">History</div>
            <div class="ante-panel__ante-cell-values-percent ml-12 font-weight-medium">
              {{ ante.ante.categories.timeliness.tm01.score }}
            </div>
          </div>
          <div class="d-flex justify-space-between">
            <div class="grey--text">Latency</div>
            <div class="ante-panel__ante-cell-values-percent font-weight-medium">
              {{ ante.ante.categories.timeliness.tm02.score }}
            </div>
          </div>
          <div class="d-flex justify-space-between">
            <div class="grey--text">Cadence</div>
            <div class="ante-panel__ante-cell-values-percent font-weight-medium">
              {{ ante.ante.categories.timeliness.tm03.score }}
            </div>
          </div>
          <div class="d-flex justify-space-between">
            <div class="grey--text">Duration</div>
            <div class="ante-panel__ante-cell-values-percent font-weight-medium">
              {{ ante.ante.categories.timeliness.tm04.score }}
            </div>
          </div>
        </div>
      </VCol>
      <VCol class="ante-panel__ante-cell caption pt-3">
        <div class="ante-panel__ante-cell-title ml-3 mb-4">
          <div
            class="ante-panel__ante-cell-title-percent subheader-text font-weight-bold mb-1 mt-4"
          >
            {{ ante.easeOfUseOverlapScore }}%
          </div>
          <div class="ante-panel__ante-cell-title-label primary--text">Ease of Use</div>
        </div>
        <div class="ante-panel__ante-cell-values">
          <div class="d-flex justify-space-between">
            <div class="grey--text">Structure</div>
            <div class="ante-panel__ante-cell-values-percent ml-12 font-weight-medium">
              {{ ante.ante.categories.easeOfUse.eu01.score }}
            </div>
          </div>
          <div class="d-flex justify-space-between">
            <div class="grey--text">Quality</div>
            <div class="ante-panel__ante-cell-values-percent font-weight-medium">
              {{ ante.ante.categories.easeOfUse.eu02.score }}
            </div>
          </div>
          <div class="d-flex justify-space-between">
            <div class="grey--text">Testability</div>
            <div class="ante-panel__ante-cell-values-percent font-weight-medium">
              {{ ante.ante.categories.easeOfUse.eu03.score }}
            </div>
          </div>
          <div class="d-flex justify-space-between">
            <div class="grey--text">Workflow</div>
            <div class="ante-panel__ante-cell-values-percent font-weight-medium">
              {{ ante.ante.categories.easeOfUse.eu04.score }}
            </div>
          </div>
        </div>
      </VCol>
    </VRow>
  </VCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    ante: {
      type: Object,
    },
  },
});
</script>

<style scoped lang="scss">
@import '~vuetify/src/styles/settings/_variables.scss';
.ante-panel {
  height: 230px;
  width: 480px;

  &__ante {
    border-bottom: 1px dotted grey;

    &:last-child {
      border-bottom: none;
    }
    &-cell {
      display: flex;
      justify-content: space-between;
      padding-top: 32px;

      &:nth-child(2n - 1) {
        .ante-panel__ante-cell-values {
          border-right: 1px dotted rgb(197, 184, 184);
          padding-right: 12px;
        }
      }
      &:nth-child(2n) {
        padding-left: 0;
        margin-right: 12px;
      }

      &-values {
        &-percent {
          color: var(--v-secondary-base);
        }
      }

      &-title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        &-percent {
          color: var(--v-additional-base);
          font-size: 28px;
          text-align: center;
        }

        &-label {
          font-size: 13px;
        }
      }
    }
  }
}
@media #{map-get($display-breakpoints, 'xs-only')} {
  .ante-panel {
    width: 245px;
    height: 437px;

    &__ante {
      &-cell {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px dotted grey;
        padding-top: 12px;

        &:first-child {
          padding-top: 30px;
        }

        &:last-child {
          border-bottom: none;
        }
        &:nth-child(2n) {
          padding-left: 12px;
          margin-right: 0;
        }

        &-title {
          align-items: start;
        }

        &-values {
          border-right: none;
          padding-right: 12px;
        }
      }
    }
  }
}
</style>

<template>
  <VCard class="overflow-y-auto pa-3" color="grey lighten-5" height="250" flat>
    <template v-if="company && company.address_1">
      <div class="subheader-text font-weight-bold mb-2">Company Stats</div>
      <div class="body-2 mb-2">
        <span class="font-weight-bold">Headquarters:</span> {{ company ? company.address_1 : '' }}
      </div>
    </template>
    <template v-if="company && company.city">
      <div class="body-2 mb-2"><span class="font-weight-bold">City:</span> {{ company.city }}</div>
    </template>
    <template v-if="company && company.state">
      <div class="body-2 mb-2">
        <span class="font-weight-bold">State:</span> {{ company.state }}
      </div>
    </template>
    <template v-if="company && company.country">
      <div class="body-2 mb-2">
        <span class="font-weight-bold">Country:</span> {{ company.country }}
      </div>
    </template>
    <div v-if="company && company.strategy" class="body-2 mb-2">
      <span class="font-weight-bold">Investment Strategy:</span>
      {{ company ? mapStrategy(company.strategy) : '' }}
    </div>
    <div v-if="company && company.our_take" class="body-2">
      <span class="font-weight-bold">Our Take:</span>
      <span> {{ company ? company.our_take : '' }} </span>
    </div>
  </VCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import { COMPANY_STRATEGIES } from '../../../constants/companyStrategies';
import AnteDetailsPanel from '../../../components/AnteDetailsPanel';

export default defineComponent({
  components: {
    AnteDetailsPanel,
  },
  props: {
    company: {
      type: Object,
    },
  },
  setup() {
    const mapStrategy = (abbr) => COMPANY_STRATEGIES.find((item) => item.value === abbr)?.text;

    return {
      mapStrategy,
    };
  },
});
</script>

<style scoped lang="scss">
::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-thumb {
  background: darkgrey;
  border-radius: 10px;
}
</style>

import { useStore } from './useStore';
import { StoreModules as Modules } from '../store';
import { States } from '../store/modules/user/types';
import { computed } from '@vue/composition-api';

export function useUserInfo() {
  const { useState } = useStore(Modules.USER);
  const { user } = useState([States.user]);

  const isUser = computed(() => user.value?.role === 'user');

  const isAdmin = computed(() => user.value?.role === 'admin');

  return {
    user,
    isUser,
    isAdmin,
  };
}

import { accuracyImg, noveltyImg, timelinessImg, easeOfUseImg } from '@/assets';

export const anteFields = {
  accuracy: {
    label: 'Accuracy',
    img: accuracyImg,
    ac01: {
      label: 'Applicability',
      text: 'Description of the item above, if needed',
    },
    ac02: {
      label: 'KPI Predictive',
      text: 'Description of the item above, if needed',
    },
    ac03: {
      label: 'Market Predictive',
      text: 'Description of the item above, if needed',
    },
    ac04: {
      label: 'Depth',
      text: 'Description of the item above, if needed',
    },
  },
  novelty: {
    label: 'Novelty',
    img: noveltyImg,
    nv01: {
      label: 'Competition',
      text: 'Description of the item above, if needed',
    },
    nv02: {
      label: 'Moat',
      text: 'Description of the item above, if needed',
    },
    nv03: {
      label: 'Innovation',
      text: 'Description of the item above, if needed',
    },
    nv04: {
      label: 'Breadth',
      text: 'Description of the item above, if needed',
    },
  },

  timeliness: {
    label: 'Timeliness',
    img: timelinessImg,
    tm01: {
      label: 'History',
      text: 'Description of the item above, if needed',
    },
    tm02: {
      label: 'Latency',
      text: 'Description of the item above, if needed',
    },
    tm03: {
      label: 'Cadence',
      text: 'Description of the item above, if needed',
    },
    tm04: {
      label: 'Duration',
      text: 'Description of the item above, if needed',
    },
  },
  easeOfUse: {
    label: 'Ease of Use',
    img: easeOfUseImg,
    eu01: {
      label: 'Structure',
      text: 'Description of the item above, if needed',
    },
    eu02: {
      label: 'Quality',
      text: 'Description of the item above, if needed',
    },
    eu03: {
      label: 'Testability',
      text: 'Description of the item above, if needed',
    },
    eu04: {
      label: 'Workflow',
      text: 'Description of the item above, if needed',
    },
  },
};

import mutations from './mutations';

export const state = {
  deliveryType: {
    isDB: false,
    isReport: false,
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};

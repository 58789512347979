<template>
  <VContainer>
    <div class="font-weight-bold primary--text d-flex justify-center flex-column align-center">
      <div class="header-text">Users</div>
    </div>
    <div class="d-flex justify-end py-2">
      <VBtn class="add-user-btn mr-5" color="additional darken-2" @click="onUpdateDbClick">
        Update DB
      </VBtn>
      <VBtn
        class="add-user-btn"
        color="additional darken-2"
        @click="openDialog(DIALOGS.CREATE_USER)"
      >
        Add new client
      </VBtn>
      <UserForm
        v-if="dialogs[DIALOGS.CREATE_USER]"
        @cancel="closeDialog(DIALOGS.CREATE_USER)"
        @submit="addNewUser"
      />
    </div>
    <div class="overflow-x-auto">
      <VDataTable
        class="user-table"
        :items="items"
        :headers="headers"
        :items-per-page="totalItems"
        hide-default-header
        hide-default-footer
      >
        <template #header="{ props }">
          <TableHeader
            class="px-4"
            :headers="props.headers"
            @onFilterChange="onFilterChange"
            @onSortChange="onSortChange"
          />
        </template>

        <template #body="{ items }">
          <VOverlay :value="loading" color="primary" absolute />
          <TableBody
            class="px-4"
            :items="items"
            :infiniteScrollDisabled="infiniteScrollDisabled"
            @handleEditUser="handleEditUser"
            @handleRemoveUser="handleRemoveUser"
            @loadMoreUsers="loadMoreUsers"
          />
        </template>
      </VDataTable>
    </div>

    <UserForm
      v-if="dialogs[DIALOGS.EDIT_USER]"
      :initialUser="currentUser"
      @cancel="closeDialog(DIALOGS.EDIT_USER)"
      @submit="editUser"
    />

    <SubmitForm
      v-if="dialogs[DIALOGS.DELETE_USER]"
      :user="currentUser"
      @cancel="closeDialog(DIALOGS.DELETE_USER)"
      @submit="deleteUser"
    >
    </SubmitForm>
  </VContainer>
</template>
<script>
import { ref, reactive, computed, onMounted } from '@vue/composition-api';
import { usersApi } from '@/api/users';
import { logError } from '@/utils/logger';
import { debounce } from 'lodash';
import TableHeader from './molecules/TableHeader.vue';
import TableBody from './molecules/TableBody.vue';
import { headers } from './headers';
import UserForm from './molecules/UserForm.vue';
import { generatePassword } from '@/utils/generatePassword';
import SubmitForm from './molecules/SubmitForm.vue';
import { seedApi } from '../../api/seed';

export default {
  components: { TableHeader, TableBody, UserForm, SubmitForm },
  setup() {
    const DIALOGS = {
      CREATE_USER: 'CREATE_USER',
      EDIT_USER: 'EDIT_USER',
      DELETE_USER: 'DELETE_USER',
    };

    const dialogs = reactive({
      [DIALOGS.CREATE_USER]: false,
      [DIALOGS.EDIT_USER]: false,
      [DIALOGS.DELETE_USER]: false,
    });

    const currentUser = ref(null);

    const items = ref([]);
    const totalItems = ref();
    const loading = ref(false);
    const searchParams = reactive({
      page: 0,
      limit: 20,
      name: null,
      company: null,
      email: null,
      sortBy: null,
      sortDesc: null,
    });

    let totalPages = 0;

    const infiniteScrollDisabled = computed(() => items.value.length === totalItems.value);

    onMounted(() => {
      loadUsers();
    });

    const fetchUsers = async () => {
      const usersRes = await usersApi.fetchUsers(searchParams);
      const users = usersRes.data;
      return users;
    };

    const onSortChange = ({ sortBy, value }) => {
      searchParams.sortBy = sortBy;
      searchParams.sortDesc = value;
      loadUsers();
    };

    const onFilterChange = debounce((field, newName) => {
      searchParams[field] = newName;
      loadUsers();
    }, 500);

    const formatUser = ({
      _id,
      name,
      email,
      company,
      anteScore,
      anteDate,
      accessStartDate,
      accessEndDate,
    }) => {
      return {
        _id,
        name,
        company,
        email,
        anteScore,
        anteDate,
        accessStartDate,
        accessEndDate,
      };
    };

    const loadUsers = async () => {
      try {
        loading.value = true;
        searchParams.page = 0;
        const { resultList: usersData, totalCount } = await fetchUsers(searchParams);
        const users = usersData.map((user) => formatUser(user));

        totalItems.value = totalCount;
        totalPages = Math.ceil(totalCount / 20);

        items.value = users;
        searchParams.page = 1;
      } catch (error) {
        logError(error);
      } finally {
        loading.value = false;
      }
    };

    const openDialog = (name) => {
      dialogs[name] = true;
    };

    const addNewUser = async ({ user, loading }) => {
      try {
        loading.value = true;
        const password = generatePassword();
        const newUser = await usersApi.addUser({ ...user.value, password });
        if (newUser?.data?.success) {
          closeDialog(DIALOGS.CREATE_USER);
          loadUsers();
        }
      } finally {
        loading.value = false;
      }
    };

    const editUser = async ({ user, loading }) => {
      try {
        loading.value = true;
        const updateUser = await usersApi.updateUser(user.value.id, user.value);
        if (updateUser?.data?.success) {
          closeDialog(DIALOGS.EDIT_USER);
          loadUsers();
        }
      } finally {
        loading.value = false;
      }
    };

    const deleteUser = async (id) => {
      try {
        loading.value = true;
        const updateUser = await usersApi.deleteUser(id);
        if (updateUser?.data?.success) {
          closeDialog(DIALOGS.DELETE_USER);
          loadUsers();
        }
      } finally {
        loading.value = false;
      }
    };

    const closeDialog = (name) => {
      dialogs[name] = false;
    };

    const loadMoreUsers = async () => {
      if (searchParams.page >= totalPages) return;
      const { resultList: usersData, totalCount } = await fetchUsers();

      totalItems.value = totalCount;
      totalPages = Math.ceil(totalCount / 20);

      const users = usersData.map((user) => formatUser(user));
      items.value = [...items.value, ...users];
      searchParams.page += 1;
    };

    const handleEditUser = (user) => {
      currentUser.value = user;
      openDialog(DIALOGS.EDIT_USER);
    };

    const handleRemoveUser = (user) => {
      currentUser.value = user;
      openDialog(DIALOGS.DELETE_USER);
    };

    const onUpdateDbClick = async () => {
      await seedApi.seedAllDb();
    };

    return {
      DIALOGS,
      currentUser,
      headers,
      loadMoreUsers,
      infiniteScrollDisabled,
      addNewUser,
      items,
      onSortChange,
      totalItems,
      loading,
      onFilterChange,
      dialogs,
      closeDialog,
      openDialog,
      editUser,
      handleEditUser,
      handleRemoveUser,
      deleteUser,
      onUpdateDbClick,
    };
  },
};
</script>
<style scoped lang="scss">
.user-search-input {
  width: 370px;
}

.add-user-btn {
  width: 175px;
  color: #fff;
  font-weight: bold;
}

.user-table {
  background-color: var(--v-grey-lighten5);
  position: relative;
  min-width: 1460px;
}

.search-icon {
  color: var(--primary);
  cursor: pointer;
}
</style>

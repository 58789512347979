import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: {
          base: '#012b3e',
          lighten2: '#5e7e8b',
        },
        secondary: {
          base: '#007e62',
          darken1: '#327461',
          lighten1: '#4c988f',
          lighten2: '#72A192',
          lighten3: '#bacec5',
          lighten4: '#C8D9D3',
          lighten5: '#D2E0D4',
        },
        additional: {
          base: '#83BC56',
          darken1: '#72BF01',
          darken2: '#007660',
          lighten1: '#96E08B',
          lighten2: '#b8eaaf',
          lighten3: '#c2d8bd',
          lighten4: '#CBE0B3',
          lighten5: '#e0efd4',
          lighten6: '#daf4d4',
        },
        grey: {
          base: '#BACBD5',
          darken1: '#919191',
          darken2: '#848996',
          darken3: '#707070',
          lighten1: '#D4DEE5',
          lighten2: '#DCE4E9',
          lighten3: '#e2e9ee',
          lighten4: '#F0F0F0',
          lighten5: '#F8FAFB',
        },
        error: {
          darken1: '#D20000',
          base: '#ff5252',
        },
        success: {
          base: '#36d115',
        },
      },
    },
    options: {
      customProperties: true,
    },
  },
});

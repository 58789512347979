<template>
  <VDialog
    :value="true"
    width="400"
    height="300"
    @click:outside="$emit('cancel')"
    @keydown.esc="$emit('cancel')"
  >
    <VForm class="form-container">
      <h2>Are you sure you want to delete user with email {{ user.email }}</h2>
      <div>
        <VBtn color="primary mr-2" @click="handleSubmit">Submit</VBtn>
        <VBtn color="grey" text @click.prevent="$emit('cancel')"> Cancel </VBtn>
      </div>
    </VForm>
  </VDialog>
</template>
<script>
export default {
  emits: ['submit', 'cancel'],
  props: { user: { type: Object, required: true } },
  setup({ user }, { emit }) {
    const handleSubmit = () => {
      emit('submit', user._id);
    };

    return {
      handleSubmit,
    };
  },
};
</script>
<style lang="scss">
.form-container {
  background-color: #fff;
  padding: 10px 20px;
}
</style>

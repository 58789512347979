export const generatePassword = () => {
  const CHARS = '0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const PASSWORD_LENGTH = 12;

  let password = '';

  for (let i = 0; i <= PASSWORD_LENGTH; i++) {
    let randomNumber = Math.floor(Math.random() * CHARS.length);
    password += CHARS.substring(randomNumber, randomNumber + 1);
  }

  return password;
};

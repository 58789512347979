import axios from 'axios';
import { logError } from '../utils/logger';

const baseUrl = `/api/v1/contacts`;

const generateContactsScoutingReportCSV = async (searchParams) => {
  try {
    return await axios.post(`${baseUrl}/scouting-report-csv`, null, { params: searchParams });
  } catch (error) {
    logError(error);
    return error;
  }
};

const fetchContacts = async (companyName, searchParams) => {
  try {
    return await axios.get(`${baseUrl}/${companyName}/company`, { params: searchParams });
  } catch (error) {
    logError(error);
    return error;
  }
};

const fetchContact = async (contactId) => {
  try {
    return await axios.get(`${baseUrl}/${contactId}`);
  } catch (error) {
    logError(error);
    return error;
  }
};

const fetchScoutingReportContacts = async (searchParams) => {
  try {
    return await axios.get(`${baseUrl}/scouting-report`, { params: searchParams });
  } catch (error) {
    logError(error);
    return error;
  }
};

export const contactsApi = {
  generateContactsScoutingReportCSV,
  fetchContacts,
  fetchContact,
  fetchScoutingReportContacts,
};

import axios from 'axios';
import { logError } from '../utils/logger';

const baseUrl = `/api/v1/institutes`;

const addInstitute = async ({ name }) => {
  try {
    return await axios.post(baseUrl, { name });
  } catch (error) {
    logError(error);
    return error;
  }
};

const updateInstituteContacts = async ({ companyName, instituteId }) => {
  const url = `${baseUrl}/${instituteId}/contacts/${companyName}`;

  try {
    return await axios.post(url);
  } catch (error) {
    logError(error);
    return error;
  }
};

const updateDeliveryDatabase = async ({ id, isDeliveryDatabase }) => {
  const url = `${baseUrl}/${id}/delivery-database`;

  try {
    return await axios.patch(url, { isDeliveryDatabase });
  } catch (error) {
    logError(error);
    return error;
  }
};

const updateDeliveryReport = async ({ id, isDeliveryReport }) => {
  const url = `${baseUrl}/${id}/delivery-report`;

  try {
    return await axios.patch(url, { isDeliveryReport });
  } catch (error) {
    logError(error);
    return error;
  }
};

const fetchAllInstitutes = async () => {
  const url = `${baseUrl}`;

  try {
    return await axios.get(url);
  } catch (error) {
    logError(error);
    return error;
  }
};

const fetchInstitute = async (id) => {
  const url = `${baseUrl}/${id}`;

  try {
    return await axios.get(url);
  } catch (error) {
    logError(error);
    return error;
  }
};

const updateInstitute = async (id, body) => {
  const url = `${baseUrl}/${id}`;

  try {
    return await axios.put(url, body);
  } catch (error) {
    logError(error);
    return error;
  }
};

const fetchInstituteNotes = async (instituteId, contactId) => {
  const url = `${baseUrl}/${instituteId}/notes`;

  try {
    return await axios.get(url, { params: { contactId } });
  } catch (error) {
    logError(error);
    return error;
  }
};

export const institutesApi = {
  addInstitute,
  updateInstituteContacts,
  updateDeliveryDatabase,
  updateDeliveryReport,
  fetchInstitute,
  updateInstitute,
  fetchInstituteNotes,
  fetchAllInstitutes,
};

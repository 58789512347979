import { Mutations } from './types';

const mutations = {
  [Mutations.SET_SECTORS_LIST]: (state, sectors) => {
    state.sectorsList = sectors;
  },
  [Mutations.SET_SELECTED_SECTORS]: (state, sectors) => {
    state.selectedSectors = sectors;
  },
};

export default mutations;

export const dateCells = ['anteDate', 'accessStartDate', 'accessEndDate'];

export const calculateMargin = (columnName) => {
  if (dateCells.includes(columnName)) return '';
  return 'ml-4 mr-1';
};

export const calculateAlign = (columnName) => {
  if (dateCells.includes(columnName)) return 'center';
  return 'left';
};

import { Mutations } from './types';

const mutations = {
  [Mutations.ADD_NOTIFICATION]: (state, targetNotification) => {
    state.notifications.push({ ...targetNotification });
  },
  [Mutations.REMOVE_NOTIFICATION]: (state, targetNotification) => {
    state.notifications = state.notifications.filter(
      (notification) => notification.id !== targetNotification.id
    );
  },
};

export default mutations;

<template>
  <div class="companies-table-wrapper">
    <div class="companies-table">
      <VDataTable
        class="companies-table__table"
        :headers="headers"
        :items="companies"
        :items-per-page="1000"
        hide-default-header
        show-select
        hide-default-footer
      >
        <template #header="{ props }">
          <thead class="companies-table__head">
            <tr class="companies-table__head-row companies-table__head-row-top">
              <th class="companies-table__head-top-column institute-contact-cell"></th>

              <th class="companies-table__head-top-column company-name-cell">
                <div class="companies-table__head-download">
                  <span class="mr-4 subheader-text"> My Top Prospects </span>
                  <VBtn
                    class="white--text text-capitalize mb-0"
                    :loading="csvLoading"
                    :disabled="csvLoading"
                    color="additional darken-1"
                    small
                    @click="onDownloadCSVClick"
                  >
                    Download
                  </VBtn>
                </div>
              </th>
              <th class="companies-table__head-top-column strategy-cell"></th>
              <th
                class="
                  companies-table__head-top-column
                  total-score-cell
                  companies-table__head-overlap-th
                "
              ></th>
              <th class="companies-table__head-top-column companies-table__head-overlap-th">
                <div class="text-uppercase font-weight-bold companies-table__head-overlap-box pt-1">
                  Overlap
                </div>
              </th>
            </tr>
            <tr class="companies-table__head-row">
              <th
                v-for="header in props.headers"
                :key="header.value"
                class="companies-table__head-column cell body-2"
                :class="`${calculateTHClass(header.value)} ${
                  header.value !== 'averageScore' ? 'primary--text' : ''
                }`"
              >
                <div
                  v-if="header.value === 'name'"
                  class="companies-table__head-content header-padding"
                >
                  <ColumnTitle
                    :headerValue="header.value"
                    :sortBy="searchParams.sortBy"
                    :sortDesc="searchParams.sortDesc"
                    @handleSort="sortCompanies"
                  >
                    <span class="companies-table__head-content-title">{{ header.text }}</span>
                  </ColumnTitle>
                  <VTextField
                    :value="searchParams.companyName"
                    class="companies-table__head-textfield"
                    placeholder="Search"
                    dense
                    clearable
                    @input="onFilterChange('companyName', $event)"
                  />
                </div>

                <div v-if="header.value === 'strategy'" class="header-padding">
                  <ColumnTitle
                    :headerValue="header.value"
                    :sortBy="searchParams.sortBy"
                    :sortDesc="searchParams.sortDesc"
                    @handleSort="sortCompanies"
                  >
                    <span class="companies-table__head-content-title">{{ header.text }}</span>
                  </ColumnTitle>
                  <VAutocomplete
                    :value="searchParams.companyStrategy"
                    class="companies-table__head-textfield"
                    :items="COMPANY_STRATEGIES"
                    placeholder="Search"
                    dense
                    clearable
                    multiple
                    @input="onFilterChange('companyStrategy', $event)"
                  >
                    <template v-slot:selection="{ index }">
                      <span v-if="index === 0" class="grey--text text-caption" />
                    </template>
                  </VAutocomplete>
                </div>

                <div
                  v-if="header.value === 'averageScore'"
                  class="mb-12 overlap-cell d-flex justify-center header-margin"
                >
                  <ColumnTitle
                    :headerValue="header.value"
                    :sortBy="searchParams.sortBy"
                    :sortDesc="searchParams.sortDesc"
                    :additionalClasses="['companies-table__column-score-content']"
                    @handleSort="sortCompanies"
                  >
                    <span class="companies-table__column-score-title">Score</span>
                  </ColumnTitle>
                </div>

                <div
                  v-if="header.value === 'f13Overlap'"
                  class="mb-12 overlap-cell d-flex justify-center header-padding"
                >
                  <ColumnTitle
                    :headerValue="header.value"
                    :sortBy="searchParams.sortBy"
                    :sortDesc="searchParams.sortDesc"
                    @handleSort="sortCompanies"
                  >
                    <span class="companies-table__head-content-title">{{ header.text }}</span>
                  </ColumnTitle>
                </div>
                <div
                  v-if="header.value === 'budget'"
                  class="mb-12 d-flex justify-center header-padding"
                >
                  <ColumnTitle
                    :headerValue="header.value"
                    :sortBy="searchParams.sortBy"
                    :sortDesc="searchParams.sortDesc"
                    @handleSort="sortCompanies"
                  >
                    <span class="companies-table__head-content-title">{{ header.text }}</span>
                  </ColumnTitle>
                </div>
                <div
                  v-if="header.value === 'anteOverlapScore'"
                  class="mb-12 d-flex justify-center header-padding"
                >
                  <ColumnTitle
                    :headerValue="header.value"
                    :sortBy="searchParams.sortBy"
                    :sortDesc="searchParams.sortDesc"
                    @handleSort="sortCompanies"
                  >
                    <span class="companies-table__head-content-title">{{ header.text }}</span>
                  </ColumnTitle>
                </div>

                <div
                  v-if="header.value === 'accuracyOverlapScore'"
                  class="relative mb-12 d-flex justify-center header-padding"
                >
                  <VImg
                    max-width="40"
                    :src="require('@/assets/accuracy.png')"
                    class="companies-table__head-column-img"
                  />
                  <ColumnTitle
                    :headerValue="header.value"
                    :sortBy="searchParams.sortBy"
                    :sortDesc="searchParams.sortDesc"
                    @handleSort="sortCompanies"
                  >
                    <span class="companies-table__head-content-title">{{ header.text }}</span>
                  </ColumnTitle>
                  <span class="mathematical-sign">=</span>
                </div>

                <div
                  v-if="header.value === 'noveltyOverlapScore'"
                  class="relative mb-12 d-flex justify-center header-padding"
                >
                  <VImg
                    max-width="40"
                    :src="require('@/assets/novelty.png')"
                    class="companies-table__head-column-img"
                  />
                  <ColumnTitle
                    :headerValue="header.value"
                    :sortBy="searchParams.sortBy"
                    :sortDesc="searchParams.sortDesc"
                    @handleSort="sortCompanies"
                  >
                    <span class="companies-table__head-content-title">{{ header.text }}</span>
                  </ColumnTitle>
                  <span class="mathematical-sign">+</span>
                </div>

                <div
                  v-if="header.value === 'timelinessOverlapScore'"
                  class="relative mb-12 d-flex justify-center header-padding"
                >
                  <VImg
                    max-width="40"
                    :src="require('@/assets/timeliness.png')"
                    class="companies-table__head-column-img"
                  />
                  <ColumnTitle
                    :headerValue="header.value"
                    :sortBy="searchParams.sortBy"
                    :sortDesc="searchParams.sortDesc"
                    @handleSort="sortCompanies"
                  >
                    <span class="companies-table__head-content-title">{{ header.text }}</span>
                  </ColumnTitle>
                  <span class="mathematical-sign">+</span>
                </div>

                <div
                  v-if="header.value === 'easeOfUseOverlapScore'"
                  class="relative mb-12 ease-of-use-cell d-flex justify-center header-padding"
                >
                  <VImg
                    max-width="40"
                    :src="require('@/assets/easeOfUse.png')"
                    class="companies-table__head-column-img"
                  />
                  <ColumnTitle
                    :headerValue="header.value"
                    :sortBy="searchParams.sortBy"
                    :sortDesc="searchParams.sortDesc"
                    @handleSort="sortCompanies"
                  >
                    <span class="companies-table__head-content-title">{{ header.text }}</span>
                  </ColumnTitle>
                  <span class="mathematical-sign">+</span>
                </div>
              </th>
            </tr>
          </thead>
        </template>
        <template #body="{ items }">
          <tbody class="companies-table__body">
            <tr
              v-for="item in items"
              :key="item.id"
              class="companies-table__body-row"
              @click="$router.push(`/company/${item.name}`)"
            >
              <td class="companies-table__body-column px-0 cell institute-contact-cell">
                <VCheckbox
                  v-model="item.isInstituteContact"
                  :ripple="false"
                  :key="item._id"
                  class="ml-2 mb-3"
                  color="additional darken-1"
                  on-icon="mdi-checkbox-marked-circle"
                  off-icon="mdi-checkbox-marked-circle"
                  hide-details
                  @click.capture.stop="onSelectedCompanyChange(item)"
                />
              </td>
              <td
                :class="item.isInstituteContact && 'companies-table__body-row--selected'"
                class="companies-table__body-column pl-0 cell company-name-cell justify-start"
              >
                <div class="text-truncate">
                  <span class="font-weight-bold"> {{ item.name }} </span>
                </div>
              </td>
              <td
                :class="item.isInstituteContact && 'companies-table__body-row--selected'"
                class="companies-table__body-column cell strategy-cell justify-start"
              >
                {{ mapStrategy(item.strategy) }}
              </td>
              <td
                :style="`background-color: ${calculateAnteOverlapCellColor(item.averageScore)}`"
                :class="item.isInstituteContact && 'companies-table__body-row--selected'"
                class="companies-table__body-column font-weight-bold cell total-score-cell"
                align="center"
              >
                {{ formatPercent(item.averageScore, 0) }}
              </td>
              <td
                :class="item.isInstituteContact && 'companies-table__body-row--selected'"
                class="companies-table__body-column cell score-cell"
                align="center"
              >
                {{ formatPercent(item.f13Overlap, 0) }}
              </td>
              <td
                :class="item.isInstituteContact && 'companies-table__body-row--selected'"
                class="companies-table__body-column cell score-cell"
                align="center"
              >
                {{ formatPercent(item.budget, 0) }}
              </td>
              <td
                :class="item.isInstituteContact && 'companies-table__body-row--selected'"
                class="companies-table__body-column font-weight-bold cell ante-cell"
                align="center"
              >
                {{ formatPercent(item.anteOverlapScore, 0) }}
              </td>
              <td
                :class="item.isInstituteContact && 'companies-table__body-row--selected'"
                class="companies-table__body-column cell score-subcategory-cell"
                align="center"
              >
                {{ formatPercent(item.accuracyOverlapScore, 0) }}
              </td>
              <td
                :class="item.isInstituteContact && 'companies-table__body-row--selected'"
                class="companies-table__body-column cell score-subcategory-cell"
                align="center"
              >
                {{ formatPercent(item.noveltyOverlapScore, 0) }}
              </td>
              <td
                :class="item.isInstituteContact && 'companies-table__body-row--selected'"
                class="companies-table__body-column cell score-subcategory-cell"
                align="center"
              >
                {{ formatPercent(item.timelinessOverlapScore, 0) }}
              </td>
              <td
                :class="item.isInstituteContact && 'companies-table__body-row--selected'"
                class="companies-table__body-column cell score-subcategory-cell"
                align="center"
              >
                {{ formatPercent(item.easeOfUseOverlapScore, 0) }}
              </td>
            </tr>
          </tbody>
        </template>
      </VDataTable>
    </div>
    <VOverlay color="primary" :value="loading" class="loader-wrapper" />
  </div>
</template>

<script>
import { debounce, omit } from 'lodash';
import { computed, defineComponent, reactive, ref, watch } from '@vue/composition-api';
import { companiesApi } from '../../../api/companies';
import { headers } from './headers';
import { useUserInfo } from '../../../uses/useUserInfo';
import { institutesApi } from '../../../api/institutes';
import { logError } from '../../../utils/logger';
import { COMPANY_STRATEGIES } from '../../../constants/companyStrategies';
import { downloadCSVData } from '../../../utils/downloadCSVData';
import ColumnTitle from './ColumnTitle.vue';
import { useStore } from '../../../uses/useStore';
import { useFormatter } from '../../../uses/useFormatter';
import { StoreModules as Modules } from '../../../store';
import { States as DeliveryTypesStates } from '../../../store/modules/deliveryType/types';
import { States as SectorsStates } from '../../../store/modules/sectors/types';

const COMPANIES_LIMIT = 200;

export default defineComponent({
  components: { ColumnTitle },
  setup() {
    const csvLoading = ref(false);
    const companies = ref([]);
    const totalCompanies = ref(null);
    const loading = ref(false);
    const searchParams = reactive({
      offset: 0,
      limit: COMPANIES_LIMIT,
      companyName: null,
      companyStrategy: [],
      sortBy: null,
      sortDesc: 1,
      isDB: false,
      isReport: false,
      sectors: [],
    });

    const { user } = useUserInfo();
    const { useState: useDeliveryTypeState } = useStore(Modules.DELIVERY_TYPE);
    const { useState: useSectorsState } = useStore(Modules.SECTORS);
    const { deliveryType } = useDeliveryTypeState([DeliveryTypesStates.deliveryType]);
    const { selectedSectors } = useSectorsState([SectorsStates.selectedSectors]);
    const { formatPercent } = useFormatter();

    const infiniteScrollDisabled = computed(() => companies.value.length === totalCompanies.value);

    watch(
      () => [deliveryType.value, selectedSectors.value],
      async ([type, sectors]) => {
        searchParams.offset = 0;
        searchParams.limit = COMPANIES_LIMIT;
        searchParams.companyName = null;
        searchParams.companyStrategy = [];
        searchParams.sortBy = null;
        searchParams.sortDesc = 1;
        searchParams.isDB = type.isDB;
        searchParams.isReport = type.isReport;
        searchParams.sectors = sectors;

        try {
          loading.value = true;
          const {
            data: { resultList },
          } = await companiesApi.fetchCompanies(searchParams);
          const {
            data: { contactedCompanies },
          } = await institutesApi.fetchInstitute(user.value.institute);
          contactedCompanies.value = contactedCompanies;
          let preparedCompanies = resultList;
          if (contactedCompanies?.length) {
            preparedCompanies = resultList.map((item) => ({
              ...item,
              isInstituteContact: contactedCompanies?.includes(item.name),
            }));
          }
          companies.value = preparedCompanies;
        } catch (error) {
          logError(error);
        } finally {
          loading.value = false;
        }
      },
      { deep: true }
    );

    const mapStrategy = (abbr) => COMPANY_STRATEGIES.find((item) => item.value === abbr)?.text;

    const loadCompanies = async () => {
      try {
        loading.value = true;
        const {
          data: { contactedCompanies, isDeliveryDatabase, isDeliveryReport },
        } = await institutesApi.fetchInstitute(user.value.institute);
        contactedCompanies.value = contactedCompanies;
        searchParams.isDB = isDeliveryDatabase;
        searchParams.isReport = isDeliveryReport;
        searchParams.sectors = selectedSectors.value;
        const {
          data: { resultList, totalCount },
        } = await companiesApi.fetchCompanies(searchParams);
        let preparedCompanies = resultList;
        if (contactedCompanies?.length) {
          preparedCompanies = resultList.map((item) => ({
            ...item,
            isInstituteContact: contactedCompanies?.includes(item.name),
          }));
        }
        companies.value = companies.value.concat(preparedCompanies);
        totalCompanies.value = totalCount;
      } catch (error) {
        logError(error);
      } finally {
        loading.value = false;
      }
    };

    const onFilterChange = debounce((field, newName) => {
      searchParams[field] = newName;
      searchParams.offset = 0;
      companies.value = [];
      loadCompanies();
    }, 500);

    const sortCompanies = (newSortBy) => {
      let resultedSort = newSortBy;
      if (newSortBy === searchParams.sortBy) {
        if (searchParams.sortDesc === 1) {
          searchParams.sortDesc = -1;
        } else {
          searchParams.sortDesc = 1;
          resultedSort = null;
        }
      } else {
        searchParams.sortDesc = 1;
      }
      searchParams.sortBy = resultedSort;
      searchParams.offset = 0;
      companies.value = [];
      loadCompanies();
    };

    const calculateSortIcon = (targetSortBy) => {
      if (targetSortBy === searchParams.sortBy) {
        if (searchParams.sortDesc === 1) {
          return 'mdi-sort-descending';
        }
        return 'mdi-sort-ascending';
      }
      return 'mdi-sort';
    };

    const onSelectedCompanyChange = async (company) => {
      await institutesApi.updateInstituteContacts({
        companyName: company.name,
        instituteId: user.value.institute,
      });
    };

    const calculateTHClass = (columnName) => {
      const classes = {
        'data-table-select': 'institute-contact-cell',
        name: 'company-name-cell',
        strategy: 'strategy-cell',
        accuracyOverlapScore: 'score-subcategory-cell',
        noveltyOverlapScore: 'score-subcategory-cell',
        timelinessOverlapScore: 'score-subcategory-cell',
        easeOfUseOverlapScore: 'score-subcategory-cell',
        anteOverlapScore: 'ante-cell',
        averageScore: 'total-score-cell',
      };
      return classes[columnName] || 'score-cell';
    };

    const onDownloadCSVClick = async () => {
      csvLoading.value = true;
      try {
        let params;
        if (searchParams.sortBy) {
          params = omit(searchParams, ['offset', 'limit']);
        } else {
          params = omit(searchParams, ['offset', 'limit', 'sortDesc']);
        }
        const { data } = await companiesApi.generateCompaniesMatchupCSV(
          params,
          user.value.institute
        );
        downloadCSVData(data, 'companies_matchup');
      } finally {
        csvLoading.value = false;
      }
    };

    const calculateAnteOverlapCellColor = (targetValue) => {
      if (targetValue > 80) {
        return '#007e62';
      } else if (targetValue > 60) {
        return '#298e78';
      } else if (targetValue > 40) {
        return '#66a391';
      } else if (targetValue > 20) {
        return '#95baaf';
      }
      return '#c6d7d0';
    };

    return {
      headers,
      companies,
      infiniteScrollDisabled,
      loading,
      onFilterChange,
      sortCompanies,
      calculateSortIcon,
      onSelectedCompanyChange,
      totalCompanies,
      calculateTHClass,
      searchParams,
      COMPANY_STRATEGIES,
      mapStrategy,
      onDownloadCSVClick,
      csvLoading,
      formatPercent,
      calculateAnteOverlapCellColor,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/styles/variables.scss';

.relative {
  position: relative;
}

.companies-table-wrapper {
  position: relative;
}

.loader-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.companies-table {
  background-color: hsl(200, 27%, 98%);
  overflow: auto;
  position: relative;
  z-index: $z-index-neutral;

  &__table {
    min-width: 1300px;
    height: 663px;
    position: relative;
    z-index: $z-index-base;
  }

  &__head {
    display: block;
    width: 100%;
    position: sticky;
    top: 0;
    background-color: var(--v-grey-lighten5);
    z-index: $z-index-fixedBlockHead;

    &-textfield {
      width: 75%;
    }

    &-download {
      display: flex;
      height: 28px;
      transform: translate(-20px, 12px);
    }

    &-top-column {
      height: unset !important;
      padding: 0 !important ;
    }

    &-overlap-th {
      width: 56.8%;
    }

    &-overlap-box {
      background-color: var(--v-grey-base);
      text-align: center;
      font-size: 16px;
      height: inherit;
    }

    &-row {
      display: table;
      width: 100%;
      table-layout: fixed;
      &:first-child {
        .total-score-cell {
          border-top: 2px solid var(--v-additional-darken1) !important;
        }
      }

      .company-name-cell {
        position: sticky;
        top: 0;
        left: 40px;
        background-color: hsl(200, 27%, 98%);
        border-right: 1px solid darkgrey;
        z-index: $z-index-fixedBlockHead;
      }

      .institute-contact-cell {
        position: sticky;
        top: 0;
        left: 0;
        background-color: hsl(200, 27%, 98%);
        z-index: $z-index-fixedBlockBody;
      }

      .total-score-cell {
        background-color: transparent;

        .companies-table__column-score-content {
          height: 25px;
          font-size: 20px;
          color: var(--v-secondary-base);

          .companies-table__column-score-title {
            display: inline-flex;
            align-items: center;
          }
        }
      }
    }

    &-row-top {
      .company-name-cell {
        z-index: $z-index-fixedBlockTop;
      }
    }

    &-column {
      font-weight: bold;
      border-bottom: 2px solid rgb(153, 147, 147) !important;
      position: sticky;
      top: 0;
      background-color: var(--v-grey-lighten5);
      z-index: $z-index-base;

      &-img {
        position: absolute;
        top: 10px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  &__body {
    display: block;

    &-row {
      display: flex;
      width: 100%;

      cursor: pointer;

      &--selected {
        color: var(--v-grey-base);
      }

      .company-name-cell {
        border-right: 1px solid darkgrey;
      }

      &:hover .companies-table__body-column,
      &:hover .companies-table__body-column {
        background: var(--v-grey-lighten2);
      }
      &:hover .companies-table__body-column.total-score-cell {
        background: var(--v-secondary-lighten1);
      }

      &:hover .companies-table__body-column.ante-cell {
        background: var(--v-additional-lighten3);
      }

      &:hover .companies-table__body-column.score-subcategory-cell {
        background: var(--v-secondary-lighten5);
      }
    }

    &-column {
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      background-color: var(--v-grey-lighten5);
    }
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: darkgrey;
    border-radius: 10px;
  }
}

.pointer {
  cursor: pointer;
}

.header-padding {
  padding-top: 60px;
}
.header-margin {
  margin-top: 60px;
}

.mathematical-sign {
  position: absolute;
  left: -5px;
  color: grey;
}

.institute-contact-cell {
  width: 40px;
  min-width: 40px;
  max-width: 40px;
  position: sticky;
  left: 0;
  background-color: hsl(200, 27%, 98%);
  z-index: $z-index-fixedBlockBody;
}
.company-name-cell {
  width: 21%;
  position: sticky;
  left: 40px;
  background-color: hsl(200, 27%, 98%);
  z-index: $z-index-fixedBlockBody;
}
.strategy-cell {
  width: 13.3%;
  padding-left: 12px !important;
}
.score-cell {
  width: 7.9%;
}
.score-subcategory-cell {
  width: 8.2%;
  background-color: var(--v-additional-lighten6);
}
.ante-cell {
  width: 8.2%;
  background-color: var(--v-additional-lighten2);
}
.total-score-cell {
  width: 8.2%;
  border-left: 2px solid var(--v-additional-darken1);
  border-right: 2px solid var(--v-additional-darken1);
  background-color: var(--v-secondary-base);
  color: white;
}

.v-input--selection-controls {
  padding-top: 0;
  margin-top: 11px;
}
.v-data-table__wrapper {
  overflow: unset;
  .v-text-field--placeholder {
    font-size: 13px;
  }
  .v-icon {
    cursor: pointer;
  }
}

::v-deep {
  .v-input--selection-controls__input .v-icon {
    color: var(--v-grey-lighten2);
  }

  .v-data-table__wrapper {
    overflow-x: unset;
    overflow-y: unset;
    .v-text-field--placeholder {
      font-size: 13px;
    }
    .v-icon {
      cursor: pointer;
    }
  }

  &.v-list-item__action {
    margin-right: 8px !important;

    &:first-child {
      margin-right: 8px !important;
    }
  }

  &.v-list-item {
    padding: 0 8px !important;
  }
}
</style>

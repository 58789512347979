<template>
  <section class="top-panel mb-5">
    <VRow class="top-panel__row pl-3">
      <VCol class="top-panel__coverage" cols="12" lg="auto">
        <div class="subheader-text font-weight-bold">My Coverage Universe:</div>
        <VSelect
          class="top-panel__coverage-input pt-0"
          :class="{ 'ml-5': $vuetify.breakpoint.smAndUp }"
          :value="selectedSectors"
          :items="sectorsList"
          :menu-props="{ bottom: true, offsetY: true }"
          clearable
          hide-details
          multiple
          placeholder="Sector"
          @change="onSelectedSectorsChange"
        >
          <template v-slot:selection="{ item, index }">
            <span v-if="index === 0" class="top-panel__coverage-selected mr-1 text-truncate">
              {{ item }}
            </span>
            <span v-if="index === 1" class="grey--text text-caption">
              (+{{ selectedSectors.length - 1 }} others)
            </span>
          </template>
        </VSelect>
      </VCol>
      <VCol class="d-flex align-end" cols="12" lg="auto">
        <div class="subheader-text font-weight-bold">My Delivery Type:</div>
        <VCheckbox
          class="ml-3"
          color="additional lighten-1"
          label="Database"
          :value="isDeliveryDatabase"
          hide-details
          @change="handleDatabaseChange"
        />
        <VCheckbox
          class="ml-3"
          color="additional lighten-1"
          label="Report"
          :value="isDeliveryReport"
          hide-details
          @change="handleReportChange"
        />
      </VCol>
    </VRow>
  </section>
</template>

<script>
import { computed, defineComponent, onMounted, ref, watch } from '@vue/composition-api';
import { useStore } from '../uses/useStore';
import { StoreModules as Modules } from '../store';
import { Actions as SectorsActions, States, Mutations } from '../store/modules/sectors/types';
import { Mutations as DeliveryTypesMutations } from '../store/modules/deliveryType/types';
import { Actions as InstituteActions } from '../store/modules/institute/types';
import { institutesApi } from '../api/institutes';
import { useUserInfo } from '../uses/useUserInfo';

export default defineComponent({
  setup() {
    const { useActions: useSectorsActions, useState, useMutations } = useStore(Modules.SECTORS);
    const { useActions: useInstituteActions } = useStore(Modules.INSTITUTE);
    const { useMutations: useDeliveryTypeMutations } = useStore(Modules.DELIVERY_TYPE);
    const { LOAD_SECTORS_LIST } = useSectorsActions([SectorsActions.LOAD_SECTORS_LIST]);
    const { LOAD_INSTITUTE } = useInstituteActions([InstituteActions.LOAD_INSTITUTE]);
    const { SET_SELECTED_SECTORS } = useMutations([Mutations.SET_SELECTED_SECTORS]);
    const { SET_DELIVERY_TYPE } = useDeliveryTypeMutations([
      DeliveryTypesMutations.SET_DELIVERY_TYPE,
    ]);
    const { sectorsList, selectedSectors } = useState([States.sectorsList, States.selectedSectors]);
    const { user } = useUserInfo();

    const isDeliveryDatabase = ref(null);
    const isDeliveryReport = ref(null);

    watch(
      user,
      async (value) => {
        if (value) {
          const institute = await LOAD_INSTITUTE(value.institute);
          if (institute) {
            isDeliveryDatabase.value = institute.isDeliveryDatabase;
            isDeliveryReport.value = institute.isDeliveryReport;
            SET_SELECTED_SECTORS(institute.selectedSectors);
            SET_DELIVERY_TYPE({
              isDB: institute.isDeliveryDatabase,
              isReport: institute.isDeliveryReport,
            });
          }
        }
      },
      { immediate: true, deep: true }
    );

    onMounted(async () => {
      await LOAD_SECTORS_LIST();
    });

    const selectedSectorsCount = computed(() => selectedSectors.value.length);

    const onSelectedSectorsChange = async (selected) => {
      SET_SELECTED_SECTORS(selected);
      await institutesApi.updateInstitute(user.value.institute, { selectedSectors: selected });
    };

    const handleDatabaseChange = async () => {
      isDeliveryDatabase.value = !isDeliveryDatabase.value;

      const {
        data: { isDeliveryDatabase: isDB, isDeliveryReport: isReport },
      } = await institutesApi.updateDeliveryDatabase({
        id: user.value.institute,
        isDeliveryDatabase: isDeliveryDatabase.value,
      });

      SET_DELIVERY_TYPE({ isDB, isReport });
    };

    const handleReportChange = async () => {
      isDeliveryReport.value = !isDeliveryReport.value;

      const {
        data: { isDeliveryDatabase: isDB, isDeliveryReport: isReport },
      } = await institutesApi.updateDeliveryReport({
        id: user.value.institute,
        isDeliveryReport: isDeliveryReport.value,
      });

      SET_DELIVERY_TYPE({ isDB, isReport });
    };

    return {
      sectorsList,
      selectedSectors,
      selectedSectorsCount,
      onSelectedSectorsChange,
      handleDatabaseChange,
      handleReportChange,
      isDeliveryDatabase,
      isDeliveryReport,
    };
  },
});
</script>

<style scoped lang="scss">
@import '~vuetify/src/styles/settings/_variables.scss';

.top-panel {
  display: flex;
  justify-content: space-between;

  &__row {
    display: flex;
  }

  &__coverage {
    display: flex;
    align-items: flex-end;

    &-input {
      width: 270px;
    }

    &-selected {
      max-width: 145px;
    }
  }
}

@media #{map-get($display-breakpoints, 'xs-only')} {
  .top-panel {
    &__coverage {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

::v-deep {
  .v-label {
    font-size: 12px;
  }
  .v-list-item {
    .mdi-checkbox-marked::before {
      color: var(--v-additional-lighten1);
    }
  }
}
</style>

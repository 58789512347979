<template>
  <VDataTable
    class="sector-coverage"
    :headers="headers"
    :items="items"
    :items-per-page="totalItems"
    hide-default-header
    hide-default-footer
  >
    <template #top>
      <div class="sector-coverage__top mb-6 pl-5 pt-3">
        <span class="sector-coverage__top-title subheader-text"> Ownership (Sectors) </span>
        <VCheckbox
          v-model="isOverlap"
          class="ma-0 ml-3"
          color="additional lighten-1"
          label="Overlap"
          hide-details
        />
      </div>
    </template>
    <template #header="{ props }">
      <thead class="sector-coverage__head">
        <tr class="sector-coverage__head-row">
          <th
            v-for="header in props.headers"
            :class="calculateTHClass(header.value)"
            class="sector-coverage__head-column cell body-2 primary--text"
            :key="header.value"
          >
            <span v-if="header.value === 'totalValue'" class="justify-center">
              {{ header.text }}
            </span>
            <span v-else-if="header.value === 'absDeltaValue'" class="justify-center">
              {{ header.text }}
            </span>
            <span v-else-if="header.value === 'sector'" class="ml-4">{{ header.text }}</span>
            <span v-else>{{ header.text }}</span>
          </th>
        </tr>
      </thead>
    </template>
    <template #body="{ items }">
      <VOverlay color="primary" :value="loading" absolute />
      <tbody
        v-infinite-scroll="loadMoreCompanies"
        infinite-scroll-disabled="infiniteScrollDisabled"
        infinite-scroll-immediate-check="false"
        class="sector-coverage__body"
      >
        <tr v-for="item in items" :key="item._id" class="sector-coverage__body-row body-2">
          <td class="sector-coverage__body-column cell sector-cell justify-start" align="left">
            <span class="primary--text font-weight-bold ml-4"> {{ item.sector }} </span>
          </td>
          <td class="sector-coverage__body-column cell percentage-portfolio-cell" align="center">
            <span> {{ formatPercent(item.perc_of_portfolio_total) }} </span>
          </td>
          <td class="sector-coverage__body-column cell value-cell" align="right">
            <span> {{ formatCurrency(item.value_total, 0) }} </span>
          </td>
          <td
            class="sector-coverage__body-column cell delta-value-cell d-flex justify-end pr-12"
            align="right"
          >
            <span :style="`color: ${calculateDeltaAppearance(item.abs_delta_value_total).color}`">
              {{
                item.abs_delta_value_total > 0
                  ? `+${formatCurrency(item.abs_delta_value_total, 0)}`
                  : formatCurrency(item.abs_delta_value_total, 0)
              }}
            </span>
            <VIcon
              class="ml-1"
              :color="calculateDeltaAppearance(item.abs_delta_value_total).color"
              :class="item.abs_delta_value_total <= 0 && 'mb-1'"
              x-small
            >
              {{
                `${calculateDeltaAppearance(item.abs_delta_value_total).icon} ${
                  item.abs_delta_value_total > 0 ? '' : 'mdi-rotate-180'
                }`
              }}
            </VIcon>
          </td>
        </tr>
      </tbody>
    </template>
  </VDataTable>
</template>

<script>
import { computed, defineComponent, reactive, ref, watch } from '@vue/composition-api';
import { useFormatter } from '../../../uses/useFormatter';
import { f13Api } from '../../../api/f13';
import { logError } from '../../../utils/logger';
import { useStore } from '../../../uses/useStore';
import { StoreModules as Modules } from '../../../store';
import { States } from '../../../store/modules/sectors/types';

export const headers = [
  {
    text: 'Sector',
    value: 'sector',
  },
  {
    text: 'Percentage of Portfolio',
    value: 'percentageOfPortfolio',
  },
  {
    text: 'Value (USD,$)',
    value: 'totalValue',
  },
  {
    text: 'Value of Change (USD,$)',
    value: 'absDeltaValue',
  },
];

export default defineComponent({
  setup(_, { root }) {
    const isOverlap = ref(true);
    const items = ref([]);
    const totalItems = ref(null);
    const loading = ref(false);
    const searchParams = reactive({
      offset: 0,
      limit: 20,
      sectors: null,
    });

    const { useState } = useStore(Modules.SECTORS);
    const { selectedSectors } = useState([States.selectedSectors]);
    const { formatPercent, formatCurrency } = useFormatter();

    const infiniteScrollDisabled = computed(() => items.value.length === totalItems.value);

    watch([isOverlap, selectedSectors], ([newOverlap, newSectors]) => {
      items.value = [];
      searchParams.offset = 0;
      searchParams.sectors = newOverlap ? newSectors : null;
      loadCompanies();
    });

    const loadCompanies = async () => {
      try {
        loading.value = true;
        const { data } = await f13Api.fetchF13SectorCoverage(root.$route.params.id, searchParams);
        items.value = items.value.concat(data.resultList);
        totalItems.value = data.totalCount;
      } catch (error) {
        logError(error);
      } finally {
        loading.value = false;
      }
    };

    const loadMoreCompanies = () => {
      searchParams.offset += 20;
      loadCompanies();
    };

    const calculateTHClass = (columnName) => {
      if (columnName === 'sector') {
        return 'sector-cell';
      } else if (columnName === 'percentageOfPortfolio') {
        return 'percentage-portfolio-cell';
      } else if (columnName === 'totalValue') {
        return 'value-cell';
      } else if (columnName === 'absDeltaValue') {
        return 'delta-value-cell';
      }
    };

    const calculateDeltaAppearance = (value) => {
      if (!value) {
        return { color: 'var(--v-grey-darken2)', icon: 'mdi-window-minimize' };
      } else if (value > 0) {
        return { color: 'var(--v-success-base)', icon: 'mdi-triangle' };
      }
      return { color: 'var(--v-error-darken1)', icon: 'mdi-triangle' };
    };

    return {
      headers,
      calculateTHClass,
      formatPercent,
      formatCurrency,
      calculateDeltaAppearance,
      loadMoreCompanies,
      items,
      totalItems,
      loading,
      isOverlap,
      infiniteScrollDisabled,
    };
  },
});
</script>

<style scoped lang="scss">
.sector-coverage {
  background-color: var(--v-grey-lighten5);
  position: relative;
  min-width: 650px;

  &__top {
    display: flex;
    align-items: center;

    &-title {
      font-weight: bold;
    }
  }

  &__head {
    display: block;
    width: 100%;
    overflow-y: scroll !important;

    &-row {
      display: table;
      width: 100%;
    }

    &-column {
      font-weight: bold;
      border-bottom: 2px solid darkgrey !important;
      text-align: center !important;
    }
  }

  &__body {
    display: block;
    height: 400px;
    overflow-x: hidden;
    overflow-y: scroll !important;

    &-row {
      display: flex;
      width: 100%;
    }

    &-column {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .value-cell {
      justify-content: center;
    }
  }
}

.sector-cell {
  width: 25%;
  text-align: left !important;
}
.percentage-portfolio-cell {
  width: 25%;
}
.value-cell {
  width: 25%;
}
.delta-value-cell {
  width: 25%;
}

::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-thumb {
  background: darkgrey;
  border-radius: 10px;
}
::v-deep {
  .v-label {
    font-size: 12px;
  }
  .v-input--selection-controls__input .v-icon {
    color: var(--v-grey-lighten2);
  }
}
</style>

import { Mutations } from './types';

const mutations = {
  [Mutations.SET_USER_DETAILS]: (state, user) => {
    state.user = user;
  },
  [Mutations.CLEAR_USER_DETAILS]: (state) => {
    state.user = null;
  },
};

export default mutations;

<template>
  <VCard class="pa-3 ante-match" color="grey lighten-5" flat>
    <div class="ante-match__title subheader-text mb-2">ANTE Match</div>
    <div class="ante-match__scores">
      <div cols="4" class="ante-match__header">
        <VMenu open-on-hover bottom offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <div class="d-flex align-center" v-bind="attrs" v-on="on">
              <div class="d-flex flex-column justify-center align-center mr-2">
                <div class="ante-match__header-percent subheader-text">
                  {{ contact.anteOverlapScore }}%
                </div>
                <div class="ante-match__header-text">ANTE</div>
              </div>
              <VProgressLinear
                :value="contact.anteOverlapScore"
                color="additional"
                background-color="additional lighten-4"
                height="23"
              />
            </div>
          </template>
          <AnteDetailsPanel :ante="contact" />
        </VMenu>
        <VDivider class="mt-2" />
      </div>
      <div class="ante-match__cell-wrapper" cols="8">
        <VRow class="ante-match__row">
          <VCol class="ante-match__cell" cols="12" sm="6">
            <div class="ante-match__cell-content">
              <VImg class="ante-match__img" :src="require('../../../assets/accuracy.png')" />
              <div class="ante-match__cell-content-text">
                <div class="ante-match__percent">{{ contact.accuracyOverlapScore }}%</div>
                <div class="body-2">Accuracy</div>
              </div>
            </div>
          </VCol>
          <VCol cols="12" v-if="$vuetify.breakpoint.xsOnly">
            <VDivider class="ante-match__divider" />
          </VCol>

          <VCol class="ante-match__cell" cols="12" sm="6">
            <div class="ante-match__cell-content">
              <VImg class="ante-match__img" :src="require('../../../assets/novelty.png')" />
              <div class="ante-match__cell-content-text">
                <div class="ante-match__percent">{{ contact.noveltyOverlapScore }}%</div>
                <div class="body-2">Novelty</div>
              </div>
            </div>
          </VCol>
        </VRow>

        <VDivider class="ante-match__divider ante-match__divider-main my-6" />
        <VRow class="ante-match__row">
          <VCol class="ante-match__cell" cols="12" sm="6">
            <div class="ante-match__cell-content">
              <VImg class="ante-match__img" :src="require('../../../assets/timeliness.png')" />
              <div class="ante-match__cell-content-text">
                <div class="ante-match__percent">{{ contact.timelinessOverlapScore }}%</div>
                <div class="body-2">Timeliness</div>
              </div>
            </div>
          </VCol>
          <VCol cols="12" v-if="$vuetify.breakpoint.xsOnly">
            <VDivider class="ante-match__divider" />
          </VCol>

          <VCol class="ante-match__cell" cols="12" sm="6">
            <div class="ante-match__cell-content">
              <VImg class="ante-match__img" :src="require('../../../assets/easeOfUse.png')" />
              <div class="ante-match__cell-content-text">
                <div class="ante-match__percent">{{ contact.easeOfUseOverlapScore }}%</div>
                <div class="body-2">Ease of Use</div>
              </div>
            </div>
          </VCol>
        </VRow>
      </div>
    </div>
  </VCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import AnteDetailsPanel from '../../../components/AnteDetailsPanel';

export default defineComponent({
  components: {
    AnteDetailsPanel,
  },
  props: {
    contact: { type: Object },
  },
});
</script>

<style scoped lang="scss">
@import '~vuetify/src/styles/settings/_variables.scss';

.ante-match {
  width: 100%;
  min-width: 460px;

  &__divider {
    color: var(--v-grey-darken3);
    border-style: dotted;
  }

  &__scores {
    padding: 0 36px;
  }

  &__title {
    font-weight: bold;
  }

  &__header {
    position: relative;

    &-percent {
      color: var(--v-additional-base);
      font-size: 24px;
      font-weight: bold;
    }

    &-text {
      color: var(--v-primary-base);
      font-size: 12px;
    }
  }

  &__cell-wrapper {
    margin-top: 24px;
  }

  &__cell {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 90px;

    &:first-child {
      border-right: 1px dotted var(--v-grey-darken3);
    }

    &-content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      align-items: center;
      width: 129px;
      white-space: nowrap;

      &-text {
        margin-left: 16px;
      }
    }
  }

  &__percent {
    font-family: Oswald;
    font-size: 25px;
    font-style: normal;
    font-weight: bold;
    color: var(--v-additional-base);
  }

  &__img {
    max-width: 65px;
    height: 65px;
  }
}
.theme--light.v-divider {
  border-color: var(--v-grey-darken3) !important;
}

@media screen and (max-width: 1599px) and (min-width: 1100px) {
  .ante-match {
    .ante-match__cell-wrapper {
      display: flex;
    }

    &__row {
      flex: 0 1 50%;
      margin: 0;

      &:first-child {
        .ante-match__cell {
          border-right: 1px dotted var(--v-grey-darken3);
        }
      }
    }

    &__divider-main {
      display: none;
    }

    &__header {
      flex: 1 1 250px;
    }

    &__scores {
      display: flex;
      align-items: center;
      padding: 0;
    }

    &__cell {
      align-self: center;

      &-content {
        &-text {
          margin-left: 8px;
        }
      }

      &-wrapper {
        flex: 1 1 626px;
        margin-top: 0;
      }
    }

    &__img {
      max-width: 40%;
      height: 40%;
    }
  }
}

@media #{map-get($display-breakpoints, 'xs-only')} {
  .ante-match {
    min-width: 300px;

    &__cell {
      &:first-child {
        border-right: none;
      }
    }

    &__scores {
      padding: 0;
    }
  }
}
</style>

<template>
  <div class="contact-table">
    <div class="contact-table__top px-3 py-3">
      <VBtn
        class="white--text text-capitalize mb-2"
        color="additional darken-1"
        :loading="csvLoading"
        :disabled="csvLoading"
        @click="onDownloadCSVClick"
      >
        Download
      </VBtn>
    </div>
    <VDataTable
      class="contact-table__table"
      :headers="headers"
      :items="contacts"
      :items-per-page="totalContacts"
      hide-default-header
      hide-default-footer
    >
      <template #header="{ props }">
        <thead class="contact-table__head">
          <tr class="contact-table__head-row">
            <th
              v-for="header in props.headers"
              :class="calculateCellClass(header.value)"
              class="contact-table__head-column cell body-2 primary--text"
              :key="header.value"
            >
              <template v-if="Array.isArray(header.text)">
                <div class="contact-table__head-column-string">{{ header.text[0] }}</div>
                <div class="contact-table__head-column-string">{{ header.text[1] }}</div>
              </template>
              <div v-else class="ml-3">
                {{ header.text }}
              </div>
              <template v-if="header.value === 'name'">
                <VTextField
                  :value="searchParams.name"
                  class="mx-3"
                  placeholder="Search"
                  dense
                  clearable
                  @input="onFilterChange('name', $event)"
                />
              </template>
              <template v-else-if="header.value === 'companyName'">
                <VTextField
                  :value="searchParams.companyName"
                  class="mx-3"
                  placeholder="Search"
                  dense
                  clearable
                  @input="onFilterChange('companyName', $event)"
                />
              </template>
              <template v-else-if="header.value === 'function'">
                <VTextField
                  :value="searchParams.function"
                  class="mx-3"
                  placeholder="Search"
                  dense
                  clearable
                  @input="onFilterChange('function', $event)"
                />
              </template>
              <template v-else-if="header.value === 'strategy'">
                <VAutocomplete
                  :value="searchParams.strategy"
                  class="mx-3"
                  :items="COMPANY_STRATEGIES"
                  placeholder="Search"
                  dense
                  clearable
                  multiple
                  @input="onFilterChange('strategy', $event)"
                >
                  <template v-slot:selection="{ index }">
                    <span v-if="index === 0" class="grey--text text-caption" />
                  </template>
                </VAutocomplete>
              </template>
            </th>
          </tr>
        </thead>
      </template>
      <template #body="{ items }">
        <VOverlay style="z-index: 100000" color="primary" :value="loading" absolute />
        <tbody class="contact-table__body">
          <tr v-for="item in items" :key="item._id" class="contact-table__body-row">
            <td class="contact-table__body-column cell" :class="calculateCellClass('name')">
              <span class="primary--text ml-4"> {{ item.name }} </span>
            </td>
            <td class="contact-table__body-column cell" :class="calculateCellClass('companyName')">
              <span class="primary--text ml-4"> {{ item.companyName }} </span>
            </td>
            <td class="contact-table__body-column cell" :class="calculateCellClass('function')">
              <span class="primary--text ml-4"> {{ item.function }} </span>
            </td>
            <td class="contact-table__body-column cell" :class="calculateCellClass('strategy')">
              <span class="primary--text ml-4"> {{ mapStrategy(item.strategy) }} </span>
            </td>
            <td class="contact-table__body-column cell" :class="calculateCellClass('dataBudget')">
              <span class="primary--text ml-4">
                {{ item.dataBudget === '50+' ? '50M+' : item.dataBudget }}
              </span>
            </td>
            <td class="contact-table__body-column cell" :class="calculateCellClass('averageScore')">
              <span class="primary--text ml-4"> {{ formatPercent(item.averageScore, 0) }} </span>
            </td>
            <td class="contact-table__body-column cell" :class="calculateCellClass('f13Overlap')">
              <span class="primary--text ml-4"> {{ formatPercent(item.f13Overlap, 0) }} </span>
            </td>
            <td class="contact-table__body-column cell" :class="calculateCellClass('budget')">
              <span class="primary--text ml-4"> {{ formatPercent(item.budget, 0) }} </span>
            </td>
            <td
              class="contact-table__body-column cell"
              :class="calculateCellClass('anteOverlapScore')"
            >
              <span class="primary--text ml-4">
                {{ formatPercent(item.anteOverlapScore, 0) }}
              </span>
            </td>
            <td
              class="contact-table__body-column cell"
              :class="calculateCellClass('accuracyOverlapScore')"
            >
              <span class="primary--text ml-4">
                {{ formatPercent(item.accuracyOverlapScore, 0) }}
              </span>
            </td>
            <td
              class="contact-table__body-column cell"
              :class="calculateCellClass('noveltyOverlapScore')"
            >
              <span class="primary--text ml-4">
                {{ formatPercent(item.noveltyOverlapScore, 0) }}
              </span>
            </td>
            <td
              class="contact-table__body-column cell"
              :class="calculateCellClass('timelinessOverlapScore')"
            >
              <span class="primary--text ml-4">
                {{ formatPercent(item.timelinessOverlapScore, 0) }}
              </span>
            </td>
            <td
              class="contact-table__body-column cell"
              :class="calculateCellClass('easeOfUseOverlapScore')"
            >
              <span class="primary--text ml-4">
                {{ formatPercent(item.easeOfUseOverlapScore, 0) }}
              </span>
            </td>
          </tr>
        </tbody>
      </template>
    </VDataTable>
  </div>
</template>

<script>
import { defineComponent, reactive, ref } from '@vue/composition-api';
import { useFormatter } from '../../../uses/useFormatter';
import { downloadCSVData } from '../../../utils/downloadCSVData';
import { logError } from '../../../utils/logger';
import { contactsApi } from '../../../api/contacts';
import { debounce, omit } from 'lodash';
import { watch } from '@vue/composition-api/dist/vue-composition-api.esm';
import { useStore } from '../../../uses/useStore';
import { StoreModules as Modules } from '../../../store';
import { States as DeliveryTypesStates } from '../../../store/modules/deliveryType/types';
import { States as SectorsStates } from '../../../store/modules/sectors/types';
import { COMPANY_STRATEGIES } from '../../../constants/companyStrategies';

export const headers = [
  {
    text: 'Name',
    value: 'name',
  },
  {
    text: 'Company Name',
    value: 'companyName',
  },
  {
    text: 'Function',
    value: 'function',
  },
  {
    text: 'Investment Strategy',
    value: 'strategy',
  },
  {
    text: 'Data Budget',
    value: 'dataBudget',
  },
  {
    text: 'Overall Company Score',
    value: 'averageScore',
  },
  {
    text: 'Overall 13F Overlap Score',
    value: 'f13Overlap',
  },
  {
    text: 'Overall Budget Overlap Score',
    value: 'budget',
  },
  {
    text: 'Contact ANTE Overlap Score',
    value: 'anteOverlapScore',
  },
  {
    text: ['ANTE', '(Accuracy)'],
    value: 'accuracyOverlapScore',
  },
  {
    text: ['ANTE', '(Novelty)'],
    value: 'noveltyOverlapScore',
  },
  {
    text: ['ANTE', '(Timeliness)'],
    value: 'timelinessOverlapScore',
  },
  {
    text: ['ANTE', '(Ease of Use)'],
    value: 'easeOfUseOverlapScore',
  },
];

const stringFields = ['name', 'companyName', 'function', 'strategy', 'dataBudget'];

export default defineComponent({
  setup() {
    const contacts = ref([]);
    const totalContacts = ref(null);
    const loading = ref(false);
    const searchParams = reactive({
      offset: 0,
    });

    const { useState: useDeliveryTypeState } = useStore(Modules.DELIVERY_TYPE);
    const { useState: useSectorsState } = useStore(Modules.SECTORS);
    const { deliveryType } = useDeliveryTypeState([DeliveryTypesStates.deliveryType]);
    const { selectedSectors } = useSectorsState([SectorsStates.selectedSectors]);

    const csvLoading = ref(false);

    const { formatPercent, formatCurrency } = useFormatter();

    watch(
      () => [deliveryType.value, selectedSectors.value],
      async ([type, sectors]) => {
        searchParams.companyName = null;
        searchParams.companyStrategy = [];
        searchParams.sortBy = null;
        searchParams.sortDesc = 1;
        searchParams.isDB = type.isDB;
        searchParams.isReport = type.isReport;
        searchParams.sectors = sectors;

        try {
          loading.value = true;
          const {
            data: { resultList, totalCount },
          } = await contactsApi.fetchScoutingReportContacts(searchParams);
          totalContacts.value = totalCount;
          contacts.value = resultList;
        } catch (error) {
          logError(error);
        } finally {
          loading.value = false;
        }
      },
      { deep: true }
    );

    const calculateCellClass = (columnName) => {
      const columnClasses = {
        name: 'name-cell',
        companyName: 'company-cell',
        function: 'function-cell',
        strategy: 'strategy-cell',
        dataBudget: 'data-budget-cell',
      };

      return columnClasses[columnName] || 'number-cell';
    };

    const onDownloadCSVClick = async () => {
      csvLoading.value = true;
      try {
        let params;
        if (searchParams.sortBy) {
          params = omit(searchParams, ['offset', 'limit']);
        } else {
          params = omit(searchParams, ['offset', 'limit', 'sortDesc']);
        }
        const { data } = await contactsApi.generateContactsScoutingReportCSV(params);
        downloadCSVData(data, 'contacts_scouting-report');
      } finally {
        csvLoading.value = false;
      }
    };

    const mapStrategy = (abbr) => COMPANY_STRATEGIES.find((item) => item.value === abbr)?.text;

    const onFilterChange = debounce(async (field, newName) => {
      searchParams[field] = newName;
      try {
        loading.value = true;
        const {
          data: { resultList, totalCount },
        } = await contactsApi.fetchScoutingReportContacts(searchParams);
        totalContacts.value = totalCount;
        contacts.value = resultList;
      } catch (error) {
        logError(error);
      } finally {
        loading.value = false;
      }
    }, 500);

    return {
      headers,
      calculateCellClass,
      formatPercent,
      formatCurrency,
      totalContacts,
      loading,
      stringFields,
      onDownloadCSVClick,
      csvLoading,
      contacts,
      COMPANY_STRATEGIES,
      mapStrategy,
      searchParams,
      onFilterChange,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/styles/variables.scss';

$tableWidth: 1225px;

.contact-table {
  background-color: var(--v-grey-lighten5);
  position: relative;
  overflow: auto;
  height: 660px;
  z-index: $z-index-neutral;

  &__top {
    position: sticky;
    top: 0;
    left: 0;
    display: flex;
    justify-content: end;
    background-color: var(--v-grey-lighten5);
    z-index: $z-index-fixedBlockTop;
  }

  &__table {
    min-width: $tableWidth;
    background-color: var(--v-grey-lighten5);
  }

  &__head {
    display: block;
    width: 100%;
    position: sticky;
    top: 68px;
    background-color: var(--v-grey-lighten5);
    z-index: $z-index-fixedBlockHead;

    &-row {
      display: table;
      width: 100%;
    }

    &-column {
      font-weight: bold;
      border-bottom: 2px solid darkgrey !important;

      &-textfield {
        width: 75%;
      }

      &-string {
        text-align: center !important;
      }

      &-string-textfield {
        width: 75%;
        visibility: hidden;
      }
    }
  }

  &__body {
    display: block;

    &-row {
      display: flex;
      width: 100%;

      &:hover {
        .name-cell {
          background-color: var(--v-grey-lighten3);
        }
      }
    }
    &-column {
      display: flex;
      align-items: center;
    }
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: darkgrey;
    border-radius: 10px;
  }
}

.name-cell {
  width: 10.5%;
  position: sticky;
  left: 0;
  border-right: 1px darkgrey solid;
  background-color: var(--v-grey-lighten5);
  z-index: $z-index-base;
}
.company-cell {
  width: 16.5%;
}
.function-cell {
  width: 7%;
}
.strategy-cell {
  width: 10%;
}
.data-budget-cell {
  width: 6%;
}
.number-cell {
  width: 6.25%;
  justify-content: center;
  text-align: center !important;
}

::v-deep {
  .v-input--selection-controls__input .v-icon {
    color: var(--v-grey-lighten2);
  }
  .v-data-table__wrapper {
    overflow-x: unset;
    overflow-y: unset;
    .v-text-field--placeholder {
      font-size: 13px;
    }
    .v-icon {
      cursor: pointer;
    }
  }
}
@media (max-width: 1520px) {
  .company-cell {
    width: 16%;
  }
}
</style>

export const anteNames = {
  accuracy: {
    ac01: {
      value: 'Applicability',
    },
    ac02: {
      value: 'KPI Predictive',
    },
    ac03: {
      value: 'Market Predictive',
    },
    ac04: {
      value: 'Depth',
    },
    value: 'Accuracy',
  },
  novelty: {
    nv01: {
      value: 'Competition',
    },
    nv02: {
      value: 'Moat',
    },
    nv03: {
      value: 'Innovation',
    },
    nv04: {
      value: 'Breadth',
    },
    value: 'Novelty',
  },
  timeliness: {
    tm01: {
      value: 'History',
    },
    tm02: {
      value: 'Latency',
    },
    tm03: {
      value: 'Cadence',
    },
    tm04: {
      value: 'Duration',
    },
    value: 'Timeliness',
  },
  easeOfUse: {
    eu01: {
      value: 'Structure',
    },
    eu02: {
      value: 'Quality',
    },
    eu03: {
      value: 'Testability',
    },
    eu04: {
      value: 'Workflow',
    },
    value: 'Ease of Use',
  },
};

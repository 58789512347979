export const States = {
  user: 'user',
};

export const Mutations = {
  SET_USER_DETAILS: 'SET_USER_DETAILS',
  CLEAR_USER_DETAILS: 'CLEAR_USER_DETAILS',
};

export const Actions = {
  INIT_CURRENT_USER: 'INIT_CURRENT_USER',
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  GET_CURRENT_USER: 'GET_CURRENT_USER',
};
